import React from "react";
import UserProfile from "../components/users/userProfile";
import UserEdit from "../components/users/userEdit";
import Dashboard from "../components/dashboard/defaultCompo/default";
import IndexStrategicgoals from "../components/strategic-goals";
import { Profile } from "../constant";
import ContinuousLearning from "../components/my-tasks/continuouslearning/continuouslearning";
import StartStopContinue from "../components/my-tasks/startstopcontinue/startstopcontinue";
import Achievements from "../components/my-tasks/achievements/achievements";
import Task from "../components/my-tasks/mytasks/mytasks";
import PowerHourpLanner from "../components/my-tasks/PowerHourPlanner/powerhourplanner";

import Finance from "../components/foundersos/finance/finance";
import HumanResources from "../components/foundersos/human-resources/humanresources";
import InformationTechnology from "../components/foundersos/information-technology/informationtechnology";
import Marketing from "../components/foundersos/marketing/marketing";
import Operations from "../components/foundersos/operations/operations";
import Partnerships from "../components/foundersos/partnerships/partnerships";
import Sales from "../components/foundersos/sales/sales";

import SopsSales from "../components/sops/Sales/Sales";
import SopsFinance from "../components/sops/Finance/Finance";
import SopsMarketing from "../components/sops/Marketing/Marketing";
import SopsPartnerships from "../components/sops/Partnerships/Partnerships";
import SopsRisks from "../components/sops/Risks/Risks";
import SopsHumanresources from "../components/sops/HumanResources/Humanresources";
import SopsInformationtechnology from "../components/sops/InformationTechnology/Informationtechnology";
import SopsOperations from "../components/sops/Operations/Operations";

import Rockfeller from "../components/Growth-Frameworks/Rockefeller/Rockfeller";
import Inviteteammatesmember from "../components/invite-teammates/inviteteammates";
import Founderdrivers from "../components/Growth-Frameworks/FoundersDrivers/Founderdrivers";
import Yeargoals from "../components/strategic-goals/1-year-goals/1-year-goals";
import DaySprint from "../components/strategic-goals/90-day-sprint/90daysprint";
import ParkingLotItems from "../components/strategic-goals/parking-lot-Items/parkinglotItems";
import YearsAspirations from "../components/strategic-goals/10-25-year-aspirations/1025yearaspirations";
import AnnualPlaning from "../components/meetings/annually-planning-session/annuallyplanningsession";

import Disc from "../components/People/DiSC-Quick-Profile-Exercise/Disc";
import FiveDisfunctions from "../components/People/Five-Disfunctions-of-a-Team/FiveDisfunctions";
import FACe from "../components/People/Functionality-Accountability-Chart-(FACe)/FACe";
import GrowYoyrTeam from "../components/People/Grow-Your-Team/GrowYourTeam";
import JobScorecard from "../components/People/Job-Scorecard/JobScorecard";
import Loveloathe from "../components/People/Love-Loathe-Exercise/LoveLoathe";
import Oppp from "../components/People/One-Pageersonallan-(OPPP)/OPPP";
import OrganizationalCheckup from "../components/People/Organizational-Checkup/OrganizationalCheckup";
import PeopleAnalyzer from "../components/People/People-Analyzer/PeopleAnalyzer";
import PersonalHistoriesExercise from "../components/People/Personal-Histories-Exercise/PersonalHistoriesExercise";
import PACe from "../components/People/Process-Accountability-Chart-(PACe)/PACe";
import TalentAssessment from "../components/People/Talent-Assessment-Chart/TalentAssessment";
import Team from "../components/People/Team-Effectiveness-Exercise/Team";
import SevenStrata from "../components/strategy-development/7-strata/7-strata";
import VisionSummury from "../components/strategy-development/vision-summary/visionsummary";
import BrandPromise from "../components/strategy-development/brand-promise/brandpromise";
import Swot from "../components/strategy-development/swot/swot";
import CustomerAvtar from "../components/strategy-development/customer-avatar/customeravatar";
import PurposeReview from "../components/strategy-development/purpose-review/purposereview";
import Sandbox from "../components/strategy-development/sandbox/sandbox";


import AddtoDirectory from "../components/invite-teammates/addtodirectory";
import NewCompany from "../components/newcompany";
import GoalScorecard from "../components/reporting/goal-scorecard/goalscorecard";
import LaborEfficiencyRatio from "../components/cashunderstanding/labor-efficiency-ratio/laborefficiencyratio";
import CashAccelerationForm from "../components/cashunderstanding/cash-acceleration-strategies/cashaccelerationstrategies";
import ProjectName from "../components/projects/add-new-project-tabs/addnewprojecttabs";
import PersonalSelling from "../components/execution/personal-selling-competence/personalselling";
import CashCompensation from "../components/cashunderstanding/cash-compensation/cashcompensation";
import PowerOfOneForm from "../components/cashunderstanding/the-power-of-one/thepowerofone";
import FundabilityForm from "../components/cashunderstanding/fundability-Improvement/fundabilityImprovement";


import Businessmodelcanvas from "../components/business-model/business-model-canvas/businessmodelcanvas";
import Onepagepersonalplan from "../components/business-model/one-page-personal-plan/onepagepersonalplan";
import ValueImprovement from "../components/cashunderstanding/value-Improvement-from-recurringrevenue/valueImprovementfrom";

import People from "../components/business-tools/people/people";
import Processes from "../components/business-tools/processes/processes";
import Technology from "../components/business-tools/technology/technology";

import Corevalues from "../components/corporate/core-values/corevalues";
import Foundationstory from "../components/corporate/foundati-onstory/foundationstory";
import Foundationalpillars from "../components/corporate/foundational-pillars/foundationalpillars";
import Mission from "../components/corporate/mission/mission";
import Productsservices from "../components/corporate/products-services/productsservices"
import Uniquevalueproposition from "../components/corporate/unique-value-proposition/uniquevalueproposition";
import Vision from "../components/corporate/vision/vision";

// Notes
import ClientsName from "../components/clients/clients-name/clientsname";
import Oureminology from "../components/users/tabcontentent/ourerminology";
import Accountdeltail from "../components/users/tabcontentent/accountDetails";
import Monthly from "../components/reporting/Monthly/Monthly";
import Publicindex from "../components/Growth-Frameworks/FoundersDrivers/driversofgrowthteb/public/index";
import Technologyindex from "../components/Growth-Frameworks/FoundersDrivers/driversofgrowthteb/technology";
import Operationsindex from "../components/Growth-Frameworks/FoundersDrivers/driversofgrowthteb/operations";
import Customerindex from "../components/Growth-Frameworks/FoundersDrivers/driversofgrowthteb/customer";
import Financeindex from "../components/Growth-Frameworks/FoundersDrivers/driversofgrowthteb/finance";
import Transactionsindex from "../components/Growth-Frameworks/FoundersDrivers/driversofgrowthteb/transactions";
import Riskindex from "../components/Growth-Frameworks/FoundersDrivers/driversofgrowthteb/risk";
import Agenda from "../components/meetings/Agenda/Agenda";
import Meetingtypes from "../components/meetings/Meeting-types/Meeting-types";
import Projectgannt from "../components/projects/project-gannt/projectgannt";
import Billings from "../components/users/tabcontentent/billing";
import ClientsTempleat from "../components/clients/clients-name/clientstempleat";
import Salesfeacthdata from "../components/foundersos/sales/salesfeacthdata";
import Financefeacthdata from "../components/foundersos/finance/financefeacthdata";
import HumanResourcesfeacthdata from "../components/foundersos/human-resources/humanresourcesfeatchdata";
import Informationfeacthdata from "../components/foundersos/information-technology/informationtechnologyfeacthdata";
import Operationsfeacthdata from "../components/foundersos/operations/operationsfeacthdata";
import Partnershipfeacthdata from "../components/foundersos/partnerships/partnershipsfeacthdata";
import Riskfeacthdata from "../components/foundersos/admin/risksfeactchdata";
import Marketingfeacthdata from "../components/foundersos/marketing/marketingfeactchdata";
import ContactForm from "../components/learningandsupport/contactform";
import HelpCenter from "../components/learningandsupport/helpcenter";
import LiveTraining from "../components/learningandsupport/livetraining";
import NotesSales from "../components/notes/notesSales";
import NotesMarketing from "../components/notes/notesMarketing";
import NotesFinanace from "../components/notes/notesFinance";
import NotesHr from "../components/notes/notesHr";
import NotesIt from "../components/notes/notesIt";
import NotesOperation from "../components/notes/notesOperations";
import Admin from "../components/foundersos/admin/admin";
import BrandingGuidlines from "../components/corporate/brandingGuidelines/brandingGuidlines";
import MarketStrategy from "../components/corporate/marketStrategy/MarketStrategy";
import Meetings from "../components/meetings/meetings/meetings";
import Teams from "../components/users/tabcontentent/teams";
import Reportingdata from "../components/reporting/Report-data/reportdata";
import UserDatail from "../components/admin/User-details/userdetails";
import Fosuniversity from "../components/admin/founderUniversity/founderUniversity";
import AdminPersonalInformation from "../components/common/adminSidebar/profilepage";
import FrequentlyQustions from "../components/admin/frequentlyaskedquestion/Frequentlyquestion";
import Workspace from "../defalutmenupage/defalutmenupage";
export const routes = [
  { path: '/', Component: <Workspace /> },
  { path: '/dashboard', Component: <Dashboard /> },

  // { path: '/admin/dashboard', Component: <AdminDashboard /> },

  { path: '/my-task/task', Component: <Task /> },
  { path: '/my-task/continuous-learning', Component: <ContinuousLearning /> },
  { path: '/my-task/start-stop-contineu', Component: <StartStopContinue /> },
  { path: '/my-task/achievments', Component: <Achievements /> },
  { path: '/my-task/power-hour-planner', Component: <PowerHourpLanner /> },
  { path: '/strategic-goals', Component: <IndexStrategicgoals /> },

  //founder o/s
  { path: '/sales', Component: <Sales /> },
  { path: '/founderos/finance', Component: <Finance /> },
  { path: '/founderos/human-resources', Component: <HumanResources /> },
  { path: '/founderos/information-technology', Component: <InformationTechnology /> },
  { path: '/founderos/marketing', Component: <Marketing /> },
  { path: '/founderos/operations', Component: <Operations /> },
  { path: '/founderos/partnership', Component: <Partnerships /> },
  { path: '/founderos/founderosadmin', Component: <Admin /> },

  // {/ Users /}
  { path: '/users/userProfile', Component: <UserProfile /> },
  { path: '/users/userProfile/profile', Component: <Profile /> },

  { path: '/users/userEdit', Component: <UserEdit /> },
  { path: '/users/billings', Component: <Billings /> },

  { path: '/users/team', Component: <Teams /> },

  { path: '/sops/sales', Component: <SopsSales /> },
  { path: '/sops/finance', Component: <SopsFinance /> },
  { path: '/sops/marketing', Component: <SopsMarketing /> },
  { path: '/sops/Partnerships', Component: <SopsPartnerships /> },
  { path: '/sops/risks', Component: <SopsRisks /> },
  { path: '/sops/humanresources', Component: <SopsHumanresources /> },
  { path: '/sops/operations', Component: <SopsOperations /> },
  { path: '/sops/informationtechnology', Component: <SopsInformationtechnology /> },

  { path: '/growthframeworks/founders-OS-checklist', Component: <Rockfeller /> },
  { path: '/growthframeworks/founderdriver', Component: <Founderdrivers /> },


  { path: '/invite-to-Company', Component: <Inviteteammatesmember /> },
  { path: '/add-to-directory', Component: <AddtoDirectory /> },

  // { path: '/learning-and-support', Component: <Indexsupport /> },

  //stratergy-goals
  { path: '/Strategic-goal/one-year-goal', Component: <Yeargoals /> },
  { path: '/Strategic-goal/ninty-days-sprints', Component: <DaySprint /> },
  { path: '/Strategic-goal/parking-lot-items', Component: <ParkingLotItems /> },
  { path: '/Strategic-goal/years-aspirations', Component: <YearsAspirations /> },

  //meeting
  { path: '/meeting/annual-planning', Component: <AnnualPlaning /> },
  { path: '/meeting/meetings', Component: <Meetings /> },
  { path: '/meeting/meeting-type', Component: <Meetingtypes /> },
  { path: '/meeting/agenda', Component: <Agenda /> },

  // people
  { path: '/people/disc', Component: <Disc /> },
  { path: '/people/fivedisfunctions', Component: <FiveDisfunctions /> },
  { path: '/people/face', Component: <FACe /> },
  { path: '/people/growyoyrteam', Component: <GrowYoyrTeam /> },
  { path: '/people/jobscorecard', Component: <JobScorecard /> },
  { path: '/people/loveloathe', Component: <Loveloathe /> },
  { path: '/people/oppp', Component: <Oppp /> },
  { path: '/people/organizationalcheckup', Component: <OrganizationalCheckup /> },
  { path: '/people/peopleanalyzer', Component: <PeopleAnalyzer /> },
  { path: '/people/personalhistoriesexercise', Component: <PersonalHistoriesExercise /> },
  { path: '/people/pace', Component: <PACe /> },
  { path: '/people/talentassessment', Component: <TalentAssessment /> },
  { path: '/people/team', Component: <Team /> },

  { path: '/new-company', Component: <NewCompany /> },

  // Reporting
  { path: '/goalscorecard', Component: <GoalScorecard /> },
  { path: '/Monthly', Component: <Monthly /> },
  { path: '/reporting-data', Component: <Reportingdata /> },

  // { path: '/initiative-commitments-tracker', Component: <InitiativeCommitmentsTracker /> },

  // Cash Understanding
  { path: '/labor-efficiency-ratio', Component: <LaborEfficiencyRatio /> },
  { path: '/cash-acceleration-strategies', Component: <CashAccelerationForm /> },
  { path: '/cash-compensation', Component: <CashCompensation /> },
  { path: '/the-power-of-one', Component: <PowerOfOneForm /> },
  { path: '/fundability-improvement', Component: <FundabilityForm /> },
  { path: '/value-Improvement-from-recurringrevenue', Component: <ValueImprovement /> },

  //execution

  { path: '/execution/personal-selling', Component: <PersonalSelling /> },

  //customer support 

  { path: '/customerSupport/contactSupport', Component: <ContactForm /> },
  { path: '/customerSupport/frequentlyAskedQuestion', Component: <HelpCenter /> },
  { path: '/customerSupport/foudersosUniversity', Component: <LiveTraining /> },


  //stratergy development
  { path: '/strategy-deveopment/seven-strata', Component: <SevenStrata /> },
  { path: '/strategy-deveopment/vision-summry', Component: <VisionSummury /> },
  { path: '/strategy-deveopment/brand-promise', Component: <BrandPromise /> },
  { path: '/strategy-deveopment/swot', Component: <Swot /> },
  { path: '/strategy-deveopment/customer-avtar', Component: <CustomerAvtar /> },
  { path: '/strategy-deveopment/purpose-review', Component: <PurposeReview /> },

  { path: '/strategy-deveopment/sandbox', Component: <Sandbox /> },

  //project
  { path: '/project/project-name', Component: <ProjectName /> },
  { path: '/project/project-gannt', Component: <Projectgannt /> },

  // clients
  { path: '/clients-name', Component: <ClientsName /> },
  { path: '/clients-templeat', Component: <ClientsTempleat /> },


  { path: '/businessmodal/businessmodalcanvas', Component: <Businessmodelcanvas /> },
  { path: '/businessmodal/onepagepersonalplan', Component: <Onepagepersonalplan /> },
  { path: '/businesstool/people', Component: <People /> },
  { path: '/businesstool/process', Component: <Processes /> },
  { path: '/businesstool/technology', Component: <Technology /> },

  //notes
  // { path: '/notes' ,Component:<IndexNotes/>},  
  { path: '/notes-sales', Component: <NotesSales /> },
  { path: '/notes-marketing', Component: <NotesMarketing /> },
  { path: '/notes-finanace', Component: <NotesFinanace /> },
  { path: '/notes-hr', Component: <NotesHr /> },
  { path: '/notes-it', Component: <NotesIt /> },
  { path: '/notes-operation', Component: <NotesOperation /> },


  { path: '/corporate/corevalues', Component: <Corevalues /> },
  { path: '/corporate/foundationstory', Component: <Foundationstory /> },
  { path: '/corporate/foundationalpillars', Component: <Foundationalpillars /> },
  { path: '/corporate/mission', Component: <Mission /> },
  { path: '/corporate/productsservices', Component: <Productsservices /> },

  { path: '/corporate/branding-guidelines', Component: <BrandingGuidlines /> },
  { path: '/corporate/market-strategy', Component: <MarketStrategy /> },

  { path: '/corporate/uniquevalueproposition', Component: <Uniquevalueproposition /> },
  { path: '/corporate/vision', Component: <Vision /> },
  { path: '/our-terminology', Component: <Oureminology /> },
  { path: '/account-deltail', Component: <Accountdeltail /> },

  // 7 Drivers Of Growth
  { path: '/growthframeworks/founderdriver/public-drivers-of-growth', Component: <Publicindex /> },
  { path: '/growthframeworks/founderdriver/technology-drivers-of-growth', Component: <Technologyindex /> },
  { path: '/growthframeworks/founderdriver/operations-drivers-of-growth', Component: <Operationsindex /> },
  { path: '/growthframeworks/founderdriver/customer-drivers-of-growth', Component: <Customerindex /> },
  { path: '/growthframeworks/founderdriver/finance-drivers-of-growth', Component: <Financeindex /> },
  { path: '/growthframeworks/founderdriver/transactions-drivers-of-growth', Component: <Transactionsindex /> },
  { path: '/growthframeworks/founderdriver/risk-drivers-of-growth', Component: <Riskindex /> },


  { path: '/sales/salesworkflow', Component: <Salesfeacthdata /> },
  { path: '/finance/financeworkflow', Component: <Financefeacthdata /> },
  { path: '/human-resources/humanresourceseworkflow', Component: <HumanResourcesfeacthdata /> },
  { path: '/information/informationworkflow', Component: <Informationfeacthdata /> },
  { path: '/markrting/marketingworkflow', Component: <Marketingfeacthdata /> },
  { path: '/operations/operationsworkflow', Component: <Operationsfeacthdata /> },
  { path: '/partership/partnershpiworkflow', Component: <Partnershipfeacthdata /> },
  { path: '/risk/riskworkflow', Component: <Riskfeacthdata /> },
  { path: '/admin/user', Component: <UserDatail /> },
  { path: '/admin/university', Component: <Fosuniversity /> },
  { path: '/admin/frequently', Component: <FrequentlyQustions /> },
  { path: '/admin/profile', Component: <AdminPersonalInformation /> },

];
