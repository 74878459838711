import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';
import axios from 'axios';
import { toast } from 'react-toastify';
import API from '../../../../../utils';

function FinanceAnalysis() {
    const [checkedValue, setCheckedValue] = useState({ finance_analysis: '' });
    const [updatedTask, setUpdatedTask] = useState(null);
    const uid = localStorage.getItem("user_id");

    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_growth_finance}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                setCheckedValue(response.data.data || { finance_analysis: '' });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch meeting types error', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const debouncedUpdateTask = useCallback(
        debounce(async (task) => {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('analysis', task.value || '');

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.add_update_finance_analysis}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === "success") {
                    toast.success(response.data.message);
                    fetchData();
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error("An error occurred while updating data.");
            }
        }, 1000),
        [uid]
    );

    useEffect(() => {
        if (updatedTask) {
            debouncedUpdateTask(updatedTask);
        }
    }, [updatedTask, debouncedUpdateTask]);

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;

        setCheckedValue({
            finance_analysis: checked ? value : ''
        });

        setUpdatedTask({
            value: checked ? value : ''
        });
    };

    return (
        <div className="col-sm-12 ">
            <div className="card mt-4">
                <div className="card-header ">
                    <div className='justify-content-between d-flex '>
                        <div className="">
                            <h5>Finance Analysis</h5>
                        </div>
                        <div >
                            <Link className='backto-ondriver' to="/growthframeworks/founderdriver">Back to the Founder OS Drivers of Growth</Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card-body">
                        <div
                            className="founderdriers mb-3"
                            style={{ backgroundColor: checkedValue.finance_analysis === 'Enterprise' ? '#d3f9d8' : 'white' }}
                        >
                            <label><b>
                                <input
                                    className="checkbox_animated"
                                    type="checkbox"
                                    value="Enterprise"
                                    checked={checkedValue.finance_analysis === 'Enterprise'}
                                    onChange={handleCheckboxChange}
                                />
                                <strong>Maturity Level: Enterprise</strong>
                            </b></label>
                            <ul>
                                <li>
                                    <p className="textnots-text">
                                        • Finance is a strategic business partner, supporting decisions to maximize return on capital and long-term value.
                                    </p>
                                </li>
                            </ul>
                        </div>

                        <div
                            className="founderdriers mb-3"
                            style={{ backgroundColor: checkedValue.finance_analysis === 'Corporation' ? '#d3f9d8' : 'white' }}
                        >
                            <label><b>
                                <input
                                    className="checkbox_animated"
                                    type="checkbox"
                                    value="Corporation"
                                    checked={checkedValue.finance_analysis === 'Corporation'}
                                    onChange={handleCheckboxChange}
                                />
                                <strong>Maturity Level: Corporation</strong>
                            </b></label>
                            <ul>
                                <li>
                                    <p className="textnots-text">
                                        • Leadership drives financial discipline to support growth and maintain access to capital.
                                    </p>
                                </li>
                            </ul>
                        </div>

                        <div
                            className="founderdriers mb-3"
                            style={{ backgroundColor: checkedValue.finance_analysis === 'Startup' ? '#d3f9d8' : 'white' }}
                        >
                            <label><b>
                                <input
                                    className="checkbox_animated"
                                    type="checkbox"
                                    value="Startup"
                                    checked={checkedValue.finance_analysis === 'Startup'}
                                    onChange={handleCheckboxChange}
                                />
                                <strong>Maturity Level: Startup</strong>
                            </b></label>
                            <ul>
                                <li>
                                    <p className="textnots-text">
                                        • The business is focused on establishing trust and financial credibility with key stakeholders.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FinanceAnalysis;
