import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import API from '../../../utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import BackButton from '../../common/header-component/backButton';

const FundabilityForm = () => {
    const uid = localStorage.getItem('user_id');
    const [formState, setFormState] = useState({
        leadershipTeam: '',
        businessModel: '',
        valuesExecution: '',
        industryExperience: '',
        eatPains: '',
        houseInOrder: '',
        protectedAssets: '',
        brainstormIdeas: '',
        topIdeas: [{ idea: '', owner: '', deadline: '' }],
    });


    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
        setFormState((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleTopIdeasChange = (index, e) => {
        const { name, value } = e.target;
        const updatedIdeas = [...formState.topIdeas];
        updatedIdeas[index][name] = value;
        setFormState({ ...formState, topIdeas: updatedIdeas });
    };

    const addNewIdea = () => {
        setFormState({ ...formState, topIdeas: [...formState.topIdeas, { idea: '', owner: '', deadline: '' }] });
    };

    const deleteIdea = async (index) => {
        if (formState.topIdeas.length > 1) {
            const idea = formState.topIdeas[index];

            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('ideas[]', idea.idea);
            formDataToSend.append('owner[]', idea.owner);
            formDataToSend.append('deadline[]', idea.deadline);

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.delete_fundability_ideas}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === 'success') {
                    const updatedIdeas = formState.topIdeas.filter((_, i) => i !== index);
                    setFormState({ ...formState, topIdeas: updatedIdeas });
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message || 'Failed to delete idea.');
                }
            } catch (error) {
                console.error('Error during delete request:', error);
                toast.error('Failed to delete idea. Please try again later.');
            }
        }
    };


    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_fundability_data}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                const data = response.data.data;

                const parsedIdeas = data.ideas ? JSON.parse(data.ideas) : [];

                setFormState({
                    leadershipTeam: data.leadership_team || '',
                    businessModel: data.business_model || '',
                    valuesExecution: data.values_and_execution_habits || '',
                    industryExperience: data.industry_experience || '',
                    eatPains: data.eat_pains || '',
                    houseInOrder: data.house_in_order || '',
                    protectedAssets: data.protected_assets || '',
                    brainstormIdeas: data.brainstorm_ideas || '',
                    topIdeas: parsedIdeas.map(idea => ({
                        idea: idea.idea || '',
                        owner: idea.owner || '',
                        deadline: idea.deadline || ''
                    })),
                });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during data fetching:', error);
            //toast.error('Failed to fetch data. Please try again later.');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('leadership_team', formState.leadershipTeam);
        formDataToSend.append('business_model', formState.businessModel);
        formDataToSend.append('values_and_execution_habits', formState.valuesExecution);
        formDataToSend.append('industry_experience', formState.industryExperience);
        formDataToSend.append('eat_pains', formState.eatPains);
        formDataToSend.append('house_in_order', formState.houseInOrder);
        formDataToSend.append('protected_assets', formState.protectedAssets);
        formDataToSend.append('brainstorm_ideas', formState.brainstormIdeas);

        formState.topIdeas.forEach((idea, index) => {
            formDataToSend.append(`ideas[${index}]`, idea.idea);
            formDataToSend.append(`owner[${index}]`, idea.owner);
            formDataToSend.append(`deadline[${index}]`, idea.deadline);
        });

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.fundability_insert}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.status === "success") {
                fetchData();
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during request:", error);
            toast.error('Failed to submit the form. Please try again later.');
        }
    };

    return (
        <div className="container-fluid">
            {/* <BackButton /> */}
            <div className="card">
                {/* <div className="card-header">
                    <div className="row">
                        <div className="col-md-12 align-items-center d-flex">
                            <h5>Fundability Improvement</h5>
                        </div>
                    </div>
                </div> */}
                <div className="card-header d-flex flex-wrap justify-content-between align-items-center ">
                    <div className="d-flex align-items-center flex-grow-1">
                        <h5>Fundability Improvement</h5>
                    </div>
                    <div >
                        <BackButton />
                    </div>
                </div>
                <form >
                    <div className="card-body">

                        <div className="life-planing-div">
                            <table className="life-planning-table table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Criteria</th>
                                        <th>Weak</th>
                                        <th>Solid</th>
                                        <th>Great</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Leadership Team</td>
                                        <td><input type="radio" className="radio_animated" name="leadershipTeam" value="weak" checked={formState.leadershipTeam === 'weak'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="leadershipTeam" value="solid" checked={formState.leadershipTeam === 'solid'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="leadershipTeam" value="great" checked={formState.leadershipTeam === 'great'} onChange={handleInputChange} /></td>
                                    </tr>
                                    <tr>
                                        <td>Business Model</td>
                                        <td><input type="radio" className="radio_animated" name="businessModel" value="weak" checked={formState.businessModel === 'weak'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="businessModel" value="solid" checked={formState.businessModel === 'solid'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="businessModel" value="great" checked={formState.businessModel === 'great'} onChange={handleInputChange} /></td>
                                    </tr>
                                    <tr>
                                        <td>Values & Execution Habits</td>
                                        <td><input type="radio" className="radio_animated" name="valuesExecution" value="weak" checked={formState.valuesExecution === 'weak'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="valuesExecution" value="solid" checked={formState.valuesExecution === 'solid'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="valuesExecution" value="great" checked={formState.valuesExecution === 'great'} onChange={handleInputChange} /></td>
                                    </tr>
                                    <tr>
                                        <td>Industry Experience</td>
                                        <td><input type="radio" className="radio_animated" name="industryExperience" value="weak" checked={formState.industryExperience === 'weak'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="industryExperience" value="solid" checked={formState.industryExperience === 'solid'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="industryExperience" value="great" checked={formState.industryExperience === 'great'} onChange={handleInputChange} /></td>
                                    </tr>
                                    <tr>
                                        <td>Eat Pains</td>
                                        <td><input type="radio" className="radio_animated" name="eatPains" value="weak" checked={formState.eatPains === 'weak'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="eatPains" value="solid" checked={formState.eatPains === 'solid'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="eatPains" value="great" checked={formState.eatPains === 'great'} onChange={handleInputChange} /></td>
                                    </tr>
                                    <tr>
                                        <td>House in Order</td>
                                        <td><input type="radio" className="radio_animated" name="houseInOrder" value="weak" checked={formState.houseInOrder === 'weak'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="houseInOrder" value="solid" checked={formState.houseInOrder === 'solid'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="houseInOrder" value="great" checked={formState.houseInOrder === 'great'} onChange={handleInputChange} /></td>
                                    </tr>
                                    <tr>
                                        <td>Protected Assets</td>
                                        <td><input type="radio" className="radio_animated" name="protectedAssets" value="weak" checked={formState.protectedAssets === 'weak'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="protectedAssets" value="solid" checked={formState.protectedAssets === 'solid'} onChange={handleInputChange} /></td>
                                        <td><input type="radio" className="radio_animated" name="protectedAssets" value="great" checked={formState.protectedAssets === 'great'} onChange={handleInputChange} /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="form-group brainstorm">
                                <label><b>Brainstorm Ideas</b></label>
                                <textarea
                                    className={`form-control ${errors.brainstormIdeas && 'is-invalid'}`}
                                    name="brainstormIdeas"
                                    value={formState.brainstormIdeas}
                                    onChange={handleInputChange}
                                    rows="4"
                                    placeholder="Enter A Compensation with Culture and Strategy"></textarea>
                                {errors.brainstormIdeas && <div className="invalid-feedback">{errors.brainstormIdeas}</div>}
                            </div>

                            <div className="form-group top-ideas mt-3 ">
                                <div className='Row'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='d-flex align-items-center'><b>Top 1-3 Ideas</b></label>
                                        <Button type="button" className=' mb-3' onClick={addNewIdea}>Add Idea</Button>
                                    </div>
                                </div>

                                {formState.topIdeas.map((idea, index) => (
                                    <div key={index} className="top-idea mb-4">
                                        <div className='row my-2 m-0'>
                                            <div className='col-md-3'>
                                                <input
                                                    className={`form-control mb-3 ${errors[`idea${index}`] && 'is-invalid'}`}
                                                    type="text"
                                                    name="idea"
                                                    placeholder="Idea"
                                                    value={idea.idea}
                                                    onChange={(e) => handleTopIdeasChange(index, e)}
                                                />
                                                {errors[`idea${index}`] && <div className="invalid-feedback">{errors[`idea${index}`]}</div>}
                                            </div>
                                            <div className='col-md-3'>
                                                <input
                                                    className={`form-control mb-3 ${errors[`owner${index}`] && 'is-invalid'}`}
                                                    type="text"
                                                    name="owner"
                                                    placeholder="Owner"
                                                    value={idea.owner}
                                                    onChange={(e) => handleTopIdeasChange(index, e)}
                                                />
                                                {errors[`owner${index}`] && <div className="invalid-feedback">{errors[`owner${index}`]}</div>}
                                            </div>
                                            <div className='col-md-3'>
                                                <input
                                                    className={`form-control mb-3 ${errors[`deadline${index}`] && 'is-invalid'}`}
                                                    type="text"
                                                    name="deadline"
                                                    placeholder="Deadline"
                                                    value={idea.deadline}
                                                    onChange={(e) => handleTopIdeasChange(index, e)}
                                                />
                                                {errors[`deadline${index}`] && <div className="invalid-feedback">{errors[`deadline${index}`]}</div>}
                                            </div>

                                            {formState.topIdeas.length > 1 && (
                                                <div className='col-md-3 d-flex align-items-center'>
                                                    <Button type="button" variant="danger" onClick={() => deleteIdea(index)}>Delete</Button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>

                    <div className='card-footer'>
                        <div className='d-flex justify-content-end'>
                            <Button variant="success" onClick={handleSubmit} className=" me-1 float-end">Save</Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default FundabilityForm;
