import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import API from "../../../utils";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BackButton from "../../common/header-component/backButton";

const Disc = () => {
  const uid = localStorage.getItem("user_id");
  const [formData, setFormData] = useState({
    winning: '',
    why: '',
    identity: '',
    rating_container: '',
    family_rating: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('winning', formData.winning);
    formDataToSend.append('why', formData.why);
    formDataToSend.append('identity', formData.identity);
    formDataToSend.append('rating_container', formData.rating_container);
    formDataToSend.append('family_rating', formData.family_rating);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.quick_profile_insert}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Profile update failed.");
    }
  };

  const fetchData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.quick_profile_get}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        const fetchedData = response.data.data[0];

        setFormData({
          winning: fetchedData.winning || "",
          why: fetchedData.why || "",
          identity: fetchedData.identity || "",
          rating_container: fetchedData.rating_container || "",
          family_rating: fetchedData.family_rating || "",
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Fetch data error', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container-fluid">
      {/* <BackButton /> */}
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            {/* <div className="card-header">
              <div className="row">
                <div className="col-md-6 align-items-center d-flex">
                  <h5>DiSC Quick Profile Exercise</h5>
                </div>
              </div>
            </div> */}
            <div className="card-header d-flex flex-wrap justify-content-between align-items-center ">
              <div className="d-flex align-items-center flex-grow-1">
                <h5>DiSC Quick Profile Exercise</h5>
              </div>
              <div >
                <BackButton />
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <div className="form-group mb-2">
                  <label><b>Answer the two questions below.</b></label>
                  <textarea
                    className="form-control"
                    name="winning"
                    value={formData.winning}
                    onChange={handleChange}
                    rows="4"
                  ></textarea>
                </div>

                <div className="form-group mb-2">
                  <label><b>Place a dot on the graph below where the two numbers intersect.</b></label>
                  <textarea
                    className="form-control"
                    rows="4"
                    name="why"
                    value={formData.why}
                    onChange={handleChange}
                  ></textarea>
                </div>

                <div className="form-group mb-2">
                  <label><b>Complete the questions at the bottom of this page to compare your DISC to that of your coach and peers.</b></label>
                  <textarea
                    className="form-control"
                    name="identity"
                    value={formData.identity}
                    onChange={handleChange}
                    rows="4"
                  ></textarea>
                </div>

                <div className="form-group mb-2">
                  <label><b>On a scale from 0 to 10, are you a "LISTENER (0) or a "TALKER (10)?</b></label>
                  <div className="d-lg-flex justify-content-lg-around">
                    {[...Array(10)].map((_, i) => (
                      <div className="rating-item" key={i + 1}>
                        <input
                          type="radio"
                          className="radio_animated"
                          name="rating_container"
                          value={i + 1}
                          checked={formData.rating_container === String(i + 1)} // Convert value to string to match
                          onChange={handleChange}
                        />
                        <span className="family-rating">{i + 1}</span>
                      </div>
                    ))}
                  </div>
                  <div className="rating-labels d-sm-none d-flex justify-content-between">
                    <span className="poor-label mx-2">TASK</span>
                    <span className="outstanding-label mx-4">PEOPLE</span>
                  </div>
                </div>

                <div className="form-group mb-2">
                  <label><b>On a scale from 0 to 10, do you care more about the "TASK" (D) or the "PEOPLE" (10)?</b></label>
                  <div className="d-lg-flex justify-content-lg-around">
                    {[...Array(10)].map((_, i) => (
                      <div className="rating-item" key={i + 1}>
                        <input
                          type="radio"
                          className="radio_animated"
                          name="family_rating"
                          value={i + 1}
                          checked={formData.family_rating === String(i + 1)} // Convert value to string to match
                          onChange={handleChange}
                        />
                        <span className="family-rating">{i + 1}</span>
                      </div>
                    ))}
                  </div>
                  <div className="rating-labels d-sm-none d-flex justify-content-between">
                    <span className="poor-label mx-2">LISTENER</span>
                    <span className="outstanding-label mx-4">TALKER</span>
                  </div>
                </div>

              </div>

              <div className="card-footer">
                <Button type="submit" variant="success" className="mb-2 float-end">
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disc;
