import React, { useState, useEffect, useRef } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import { MdDelete } from 'react-icons/md';
import DataTable from 'react-data-table-component';
import { FaEye, FaRegEdit } from "react-icons/fa";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from "../../../utils";
import axios from 'axios';
import BackButton from "../../common/header-component/backButton";

const Teams = () => {
    const uid = localStorage.getItem("user_id");
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [formData, setFormData] = useState({
        name: '',
        title_role: '',
        image: null,
        department: '',
    });
    const [validationErrors, setValidationErrors] = useState({});
    const [annualOutcomes, setAnnualOutcomes] = useState([]);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
        setValidationErrors({});
    };

    const handleCancel = () => {
        setFormData({
            name: '',
            title_role: '',
            image: null,
            department: '',
        });
        setValidationErrors({});
        setIsPopupOpen(false);
    };

    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_all_team_membres}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                const sortedTasks = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setAnnualOutcomes(sortedTasks);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch gratitude error', error);

        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSubmit = async () => {
        const errors = {};

        const name = formData.name.trim();
        const title_role = formData.title_role.trim();

        if (Object.keys(errors).length === 0) {
            setFormData({
                name: '',
                title_role: '',
                image: '',
                department: '',
            });
            setIsPopupOpen(false);
        } else {
            setValidationErrors(errors);
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('team_name', name);
        formDataToSend.append('title_role', title_role);
        formDataToSend.append('category', formData.department);

        if (formData.image) {
            formDataToSend.append('profile', formData.image);
        }

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_team_membres}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data' // Important for file uploads
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
                setFormData({
                    name: '',
                    title_role: '',
                    image: '',
                    department: '',
                });
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
            } else {
                toast.error(response.data.message);
            }

        } catch (error) {
            console.error("Error during submission:", error);
            toast.error('An error occurred while submitting the form.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;

        if (name === 'image' && files.length > 0) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: files[0]
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value
            }));
        }
    };


    const toggleDeleteModal = () => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };

    const toggleEditModal = () => {
        setIsEditModalOpen(!isEditModalOpen);
        if (isEditModalOpen) {
            setFormData({
                name: '',
                title_role: '',
                image: '',
                department: '',
            });
            setValidationErrors({});
        }
    };

    const toggleViewModal = () => {
        setIsViewModalOpen(!isViewModalOpen);
    };

    const handleDelete = (item) => {
        setSelectedItem(item);
        toggleDeleteModal();
    };

    const handleEdit = (item) => {
        setSelectedItem(item);
        setFormData({
            name: item.team_name,
            title_role: item.title_role,
            image: item.profile,
            department: item.category,
        });
        toggleEditModal();
    };

    const handleDeleteItem = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('id', selectedItem.id);
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.delete_team_membres}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                toggleDeleteModal();
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Failed to delete the item.");
        }
    };

    const handleEditItem = async () => {
        const errors = {};



        if (Object.keys(errors).length === 0) {
            const updatedEntries = annualOutcomes.map(entry => {
                if (entry.id === selectedItem.id) {
                    return {
                        ...entry,
                        team_name: formData.name,
                        title_role: formData.title_role,
                        profile: formData.image,
                        category: formData.department,
                    };
                }
                return entry;
            });

            setAnnualOutcomes(updatedEntries);

            const formDataToSend = new FormData();
            formDataToSend.append('id', selectedItem.id);
            formDataToSend.append('team_name', formData.name);
            formDataToSend.append('title_role', formData.title_role);

            formDataToSend.append('category', formData.department);
            if (formData.image) {
                formDataToSend.append('profile', formData.image);
            }


            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.update_team_membres}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === "success") {
                    toast.success(response.data.message);
                    setFormData({
                        name: '',
                        title_role: '',
                        image: '',
                        department: '',
                    });
                    setValidationErrors({});
                    fetchData();
                    toggleEditModal();
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error("Error during submission:", error);
                toast.error('An error occurred while submitting the form.');
            }
        } else {
            setValidationErrors(errors);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleView = (item) => {
        setSelectedItem(item);
        toggleViewModal();
    };

    const filteredEntries = annualOutcomes.filter(entry =>
        (entry.team_name || "").toLowerCase().includes((searchQuery || "").toLowerCase()) ||
        (entry.title_role || "").toLowerCase().includes((searchQuery || "").toLowerCase())
    );

    const thStyle = {
        border: "1px solid gray",
        padding: "8px",
        textAlign: "left",
        background: "black",
        color: "white"
    };

    const columns = [
        {
            name: 'Profile Image',
            cell: row => {
                const fileUrl = row.profile;
                return (
                    // style={{ width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #ddd', borderRadius: '4px' }}
                    <div >
                        {fileUrl ? (
                            <img
                                src={fileUrl}
                                alt="Profile"
                                style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                            />
                        ) : (
                            <p>No Image</p>
                        )}
                    </div>
                );
            }
        },
        {
            name: 'Name',
            selector: row => row.team_name,
            sortable: true,
        },
        {
            name: 'Title / Role',
            selector: row => row.title_role,
            sortable: true,
        },
        {
            name: 'Department',
            selector: row => row.category,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => (
                <>
                    <div className="d-lg-flex">
                        <Button className="btn-secondary btn-pill main-edit-view-buttons m-1" onClick={() => handleView(row)}><FaEye /></Button>
                        <Button className="btn-secondary btn-pill main-edit-view-buttons m-1" onClick={() => handleEdit(row)}><FaRegEdit /></Button>
                        <Button className="btn-danger btn-pill main-delete-buttons m-1" onClick={() => handleDelete(row)}><MdDelete /></Button>
                    </div>
                </>
            ),
        },
    ];

    const fileInputRef = useRef(null);

    const [imagePreview, setImagePreview] = useState(null);
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        handleInputChange(e);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setImagePreview(null); // Clear preview if no file is selected
        }
    };
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            {/* <div className="card-header">
                                <div className="row">
                                    <div className=" align-items-center justify-content-between d-flex">
                                        <h5>Team Members</h5>
                                        <Button className="btn-success main-buttons" onClick={togglePopup}>Add Team Members </Button>
                                    </div>
                                </div>
                            </div> */}
                            <div className="card-header d-flex flex-wrap justify-content-between align-items-center ">
                                <div className="d-flex align-items-center flex-grow-1">
                                    <h5>Team Members</h5>
                                </div>
                                <div className="align-items-center justify-content-between d-flex">
                                    <Button className="btn-success main-buttons me-lg-2" onClick={togglePopup}>Add Team Members </Button>
                                    <BackButton />
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-9"></div>
                                    <div className="col-md-3">
                                        <div className="form-group text-end mb-3">
                                            <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <DataTable
                                        columns={columns}
                                        data={filteredEntries}
                                        persistTableHead={true}
                                        pagination
                                        className="altimate-table"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isPopupOpen && (
                    <Modal centered={true} show={isPopupOpen} onHide={togglePopup}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Team Members</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form className="theme-form">
                                <div className="form-group">
                                    <label className="col-form-label pt-0" htmlFor="name">Team Members Name</label>
                                    <input
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        type="text"
                                        placeholder="Enter Team Members Name"
                                        value={formData.name || ''}
                                        onChange={handleInputChange}
                                    />

                                </div>
                                <div className="form-group">
                                    <label className="col-form-label pt-0" htmlFor="title_role">Title / Role</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="title_role"
                                        name="title_role"
                                        placeholder="Enter Title / Role"
                                        value={formData.title_role || ''}
                                        onChange={handleInputChange}
                                    />
                                    {validationErrors.title_role && <div className="text-danger">{validationErrors.title_role}</div>}
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label pt-0" htmlFor="department">Department</label>
                                    <select
                                        className="form-control"
                                        id="department"
                                        name="department"
                                        value={formData.department || ''}
                                        onChange={handleInputChange}
                                    >
                                        <option value="" disabled>Select Department</option>
                                        <option value="Sales">Sales</option>
                                        <option value="Marketing">Marketing</option>
                                        <option value="Finance">Finance</option>
                                        <option value="Human Resources">Human Resources</option>
                                        <option value="Information Technology">Information Technology</option>
                                        <option value="Operations">Operations</option>
                                        <option value="Administration">Administration</option>
                                        <option value="Other">Other</option>
                                    </select>

                                </div>
                                <div className="form-group">
                                    <label className="col-form-label pt-0" htmlFor="image">Upload Picture</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="image"
                                        name="image"
                                        accept="image/*"
                                        onChange={handleInputChange}
                                        ref={fileInputRef}
                                    />

                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
                            <button className="btn btn-success" onClick={handleSubmit}>Save</button>
                        </Modal.Footer>
                    </Modal>

                )}
                {isDeleteModalOpen && (
                    <Modal centered={true} show={isDeleteModalOpen} onHide={toggleDeleteModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Team Member</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure you want to delete {selectedItem && selectedItem.name}?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary" onClick={toggleDeleteModal}>Cancel</button>
                            <button className="delete-btn  btn-danger me-1" onClick={handleDeleteItem}>Delete</button>
                        </Modal.Footer>
                    </Modal>
                )}
                <Modal show={isEditModalOpen} onHide={toggleEditModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Team Member</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="mb-3">
                                <label className="form-label">Upload Picture</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    name="image"
                                    accept="image/*"
                                    onChange={handleImageChange} // Use handleImageChange to show preview
                                />
                            </div>
                            {imagePreview && (
                                <div className="mb-3">

                                    <img src={imagePreview} alt="Preview" className="img-thumbnail" style={{ width: '100px', height: '100px' }} />
                                </div>
                            )}
                            <div className="mb-3">
                                <label htmlFor="editName" className="form-label">Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="editName"
                                    name="name"
                                    value={formData.name || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Title / Role</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="title_role"
                                    value={formData.title_role || ''}
                                    placeholder="Enter Title / Role"
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Department</label>
                                <select
                                    className="form-control"
                                    name="department"
                                    value={formData.department || ''}
                                    onChange={handleInputChange}
                                >
                                    <option value="">Select Department</option>
                                    <option value="Sales">Sales</option>
                                    <option value="Marketing">Marketing</option>
                                    <option value="Finance">Finance</option>
                                    <option value="Human Resources">Human Resources</option>
                                    <option value="Information Technology">Information Technology</option>
                                    <option value="Operations">Operations</option>
                                    <option value="Administration">Administration</option>
                                    <option value="Other">Other</option>
                                </select>
                                {validationErrors.department && (
                                    <span className="text-danger">{validationErrors.department}</span>
                                )}
                            </div>

                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={toggleEditModal}>
                            Cancel
                        </Button>
                        <Button variant="success" onClick={handleEditItem}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>



                <div className="card-body btn-showcase">
                    {isViewModalOpen && (
                        <Modal centered={true} show={isViewModalOpen} onHide={toggleViewModal} >
                            <Modal.Header closeButton>
                                <Modal.Title>Team  Detail</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <table className="table">
                                    <thead></thead>
                                    <tbody>
                                        <tr>
                                            <th style={thStyle} colSpan={2}>Team  Detail</th>
                                        </tr>

                                        <tr>
                                            <td>Profile</td>
                                            <td>
                                                <Image
                                                    src={selectedItem.profile}
                                                    alt="Profile"
                                                    width={50}
                                                    height={50}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Team  Name</td>
                                            <td>{selectedItem.team_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Title / Role</td>
                                            <td>{selectedItem.title_role}</td>
                                        </tr>
                                        <tr>
                                            <td>Department</td>
                                            <td>{selectedItem.category}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-secondary" onClick={toggleViewModal}>Close</button>
                            </Modal.Footer>
                        </Modal>
                    )}
                </div>
            </div>

            {/* <div className="container-fluid">
<People/></div> */}
        </>
    );
};

export default Teams;
