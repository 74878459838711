import React from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

const Edit4events = ({ show, onHide, formData, onChange, onSave, onAddField, onRemoveField }) => {
    return (
        <Modal show={show} onHide={onHide} >
            <Modal.Header closeButton>
                <Modal.Title>Edit Avatar</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="row" >
                    {['Motivations'].map((field) => (
                        <Form.Group key={field}>
                            <Form.Label>{field.replace('_', ' ')}</Form.Label>
                            {formData[field] && Array.isArray(formData[field]) && formData[field].map((item, index) => (
                                <Row key={index} className="align-items-center mb-2">
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            value={item}
                                            onChange={onChange}
                                            name={`${field}[${index}]`}
                                            placeholder={`Enter ${field.replace('_', ' ')}`}
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <Button variant="danger" onClick={() => onRemoveField(field, index)}>
                                            Remove
                                        </Button>
                                    </Col>
                                </Row>
                            ))}

                            <Button onClick={() => onAddField(field)}>
                                Add {field.replace('_', ' ')}
                            </Button>
                        </Form.Group>
                    ))}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Close</Button>
                <Button variant="success" onClick={onSave}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default Edit4events;