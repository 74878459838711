import React from "react";
import Totalvenue from "./totalrevenue";
import AccountPayable from "./accountspayable";
import BackButton from "../../common/header-component/backButton";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";

const Reportingdata = () => {

    return (
        <div className="container-fluid">
            <div className='d-flex justify-content-end mb-2'>
                <Link to="/" className="Black-button">
                    <FaArrowLeft className='me-1' style={{ fontSize: '12px' }} />
                    <span>Back To Home</span>
                </Link>
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <Totalvenue />
                </div>
                <div className="col-sm-6">
                    <AccountPayable />
                </div>
            </div>
        </div >
    );
};

export default Reportingdata;
