import React from 'react';
import { Link, useNavigate } from 'react-router-dom'; // React Router v6
import { FaArrowLeft } from 'react-icons/fa'; // Using react-icons for the back icon

const BackButton = () => {
    return (
        <div className='d-flex justify-content-end '>
            <Link to="/" className="Back-button">
                <FaArrowLeft className='me-1' style={{ fontSize: '12px' }} />
                <span>Back To Home</span>
            </Link>
        </div>
    );
};

export default BackButton;
