import React, { useState, useEffect } from "react";
import { Form, FormGroup, Input, Label, Button } from 'reactstrap';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../../utils";
import axios from "axios";

const AddModal = ({ fetchTasks }) => {
  const uid = localStorage.getItem("user_id");

  const initialFormData = {
    title: '',
    description: '',
    criticality: '',
    due_date: '',
    status: '',
    task_type: '',
  };

  const initialFormErrors = {
    title: '',
    description: '',
    criticality: '',
    due_date: '',
    status: '',
    task_type: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState(initialFormErrors);

  useEffect(() => {
    setFormData(initialFormData);
    setFormErrors(initialFormErrors);
  }, []);

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    setFormErrors({ ...formErrors, [field]: '' });
  };

  const handleSubmit = async (e) => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('title', formData.title);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('criticality', formData.criticality);
    formDataToSend.append('status', formData.status);
    formDataToSend.append('task_type', formData.task_type);
    formDataToSend.append('due_date', formData.due_date);

    if (formData.status === 'completed') {
      formDataToSend.append('is_complete', '1');
    }

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_task}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(`Task Successfully Added`);
        setFormData(initialFormData);
        setFormErrors(initialFormErrors);
        fetchTasks();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during task submission:", error);
    }
  };

  const isSaveButtonEnabled = () => formData.due_date.trim() !== '' && formData.title.trim() !== '';

  return (
    <div>
      <Form>
        <div className='row'>
          <FormGroup className="col-lg-4 col-sm-4">
            <Label><b>Task</b></Label>
            <Input
              type="text"
              name="title"
              id="task"
              placeholder="Enter Task Title"
              value={formData.title}
              onChange={(e) => handleChange('title', e.target.value)}
              invalid={!!formErrors.title}
            />
            {formErrors.title && <div className="text-danger">{formErrors.title}</div>}
          </FormGroup>
          <FormGroup className="col-lg-4 col-sm-4">
            <Label><b>Criticality</b></Label>
            <Input
              name="criticality"
              invalid={!!formErrors.criticality}
              value={formData.criticality}
              onChange={(e) => handleChange('criticality', e.target.value)}
              type="select"
            >
              <option value="general">Choose Option</option>
              <option value="Critical">Critical</option>
              <option value="High">High</option>
              <option value="Medium">Medium</option>
              <option value="Low">Low</option>
            </Input>
            {formErrors.criticality && <div className="text-danger">{formErrors.criticality}</div>}
          </FormGroup>
          <FormGroup className="col-lg-4 col-sm-4">
            <Label><b>Status</b></Label>
            <Input
              name="Status"
              invalid={!!formErrors.status}
              value={formData.status}
              onChange={(e) => handleChange('status', e.target.value)}
              type="select"
            >
              <option value="general">Choose Option</option>
              <option value="not_started">Not Started</option>
              <option value="in_process">In Process</option>
              <option value="on_hold">On Hold</option>
              <option value="completed">Completed</option>
            </Input>
            {formErrors.status && <div className="text-danger">{formErrors.status}</div>}
          </FormGroup>
          <FormGroup className="col-lg-4 col-sm-4">
            <Label><b>Task Type</b></Label>
            <Input
              name="task_type"
              invalid={!!formErrors.task_type}
              value={formData.task_type}
              onChange={(e) => handleChange('task_type', e.target.value)}
              type="select"
            >
              <option value="general">Choose Option</option>
              <option value="Professional">Professional</option>
              <option value="Personal">Personal</option>
            </Input>
            {formErrors.task_type && <div className="text-danger">{formErrors.task_type}</div>}
          </FormGroup>
          <FormGroup className="col-lg-4 col-sm-4">
            <Label><b>Due Date</b></Label>
            <Input
              className={`form-control ${formErrors.due_date ? 'is-invalid' : ''}`}
              name="due_date"
              type="date"
              value={formData.due_date}
              onChange={(e) => handleChange('due_date', e.target.value)}
              min={new Date().toISOString().split('T')[0]}

            />
            {formErrors.due_date && <div className="text-danger">{formErrors.due_date}</div>}
          </FormGroup>
          <FormGroup className="col-lg-4 col-sm-4">
            <Label><b>Description</b></Label>
            <Input
              name="description"
              placeholder="Enter Task Description"
              invalid={!!formErrors.description}
              value={formData.description}
              onChange={(e) => handleChange('description', e.target.value)}
              type="textarea"
              rows="1"
            />
            {formErrors.description && <div className="text-danger">{formErrors.description}</div>}
          </FormGroup>
        </div>
        {/* <div className="d-flex justify-content-end align-content-center mb-2">
          <Button className="btn btn-success" onClick={handleSubmit}>Save</button>
        </div> */}
        <div className="d-flex justify-content-end align-content-center mb-2">
          <Button
            className="btn btn-success"
            onClick={handleSubmit}
            disabled={!isSaveButtonEnabled()}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddModal;
