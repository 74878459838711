import React, { useState, useEffect, useCallback } from "react";
import { Button, Card, CardBody, CardHeader, Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import debounce from 'lodash/debounce';
import BackButton from "../../common/header-component/backButton";

const ProjectName = () => {
    const uid = localStorage.getItem("user_id");
    const [projectData, setProjectData] = useState([]);
    const [errors, setErrors] = useState({});
    const [milestones, setMilestones] = useState([{ next_milestone: '', next_milestonedate: '' }]);
    const [formData, setFormData] = useState({
        named_customer_client: '',
        next_milestone: '',
        next_milestonedate: '',
        project_name: '',
        owner: '',
        due_date: '',
        status: '',
        notes: '',
    })
    useEffect(() => {
        fetchTasks();
    }, []);
    const fetchTasks = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_projectname}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
            if (response.data.status === 'success') {
                setProjectData(response.data.info);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during task fetching:', error);
        }
    }
    const validate = () => {
        const errors = {};
        if (!formData.project_name) {
            errors.project_name = "This field is required";
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        if (!validate()) return;
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('project_name', formData.project_name);
            formDataToSend.append('owner', formData.owner);
            formDataToSend.append('due_date', formData.due_date);
            formDataToSend.append('status', formData.status);
            formDataToSend.append('named_customer_client', formData.named_customer_client);
            formDataToSend.append('notes', formData.notes)
            milestones.forEach((milestone, index) => {
                formDataToSend.append(`next_milestone[${index}]`, milestone.next_milestone);
                formDataToSend.append(`date[${index}]`, milestone.next_milestonedate);
            });
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_projectname}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchTasks();
                setFormData({
                    project_name: '',
                    owner: '',
                    due_date: '',
                    status: '',
                    notes: '',
                    named_customer_client: '',
                });
                setMilestones([{ next_milestone: '', next_milestonedate: '' }]);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during task submission:", error);
        }
    };
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === "project_name") {
            const isProjectNameFilled = value.trim() !== '';
            setIsButtonEnabled(isProjectNameFilled);
            setErrors({
                ...errors,
                project_name: isProjectNameFilled ? "" : "This field is required"
            });
        }
    };

    const [updatedTask, setUpdatedTask] = useState(null)
    const debouncedUpdateTask = useCallback(
        debounce(async (task) => {
            const formDataToSend = new FormData();
            formDataToSend.append('projectname_id', task.id);
            formDataToSend.append('filed', task.field);
            formDataToSend.append('text', task.value);
            try {
                const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.update_projectname}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );
                if (response.data.status === "success") {
                    fetchTasks();
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error("An error occurred while updating data.");
            }
        }, 1000),
        []
    );
    const handleEditInputChange = (e, index, field, id) => {
        const { value } = e.target;
        const updatedOutcomes = [...projectData];
        updatedOutcomes[index][field] = value;
        setUpdatedTask({ field, value, id });
    };
    useEffect(() => {
        if (updatedTask) {
            debouncedUpdateTask(updatedTask);
        }
    }, [updatedTask, debouncedUpdateTask]);


    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };
    const filteredEntries = (projectData || []).filter(entry =>
        (entry.project_name || "").toLowerCase().includes((searchQuery || "").toLowerCase())
    );

    const handleCheckboxChange = async (taskid, isChecked) => {
        const newTasks = projectData.map(task =>
            task.id === taskid ? { ...task, is_complete: isChecked ? "1" : "0" } : task
        );
        setProjectData(newTasks);
        const formDataToSend = new FormData();
        formDataToSend.append('project_id', taskid);
        formDataToSend.append('is_check', isChecked ? "1" : "0");

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.is_check_project_name}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
            if (response.data.status === "success") {
                // toast.success(response.data.message);
                fetchTasks();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during checkbox status update:", error);
            toast.error('An error occurred while updating the checkbox status.');
        }
    };

    const columns = [
        {
            name: 'Completed',
            selector: row => row.is_complete,
            cell: row => (
                <input
                    type="checkbox"
                    id={`chk-ani${row.id}`}
                    className="checkbox_animated"
                    checked={row.is_complete === "1"}
                    onChange={(e) => handleCheckboxChange(row.id, e.target.checked)}
                />
            ),
            sortable: true
        },
        {
            name: "Project Name",
            selector: row => row.project_name,
            cell: (row, index) =>
            (
                <div className="project-table w-100">
                    <input
                        type="text"
                        name="project_name"
                        value={row.project_name}
                        className="form-control w-100"
                        onChange={(e) => handleEditInputChange(e, index, "project_name", row.id)}
                    />
                </div>
            ),
            sortable: true,
        },
        {
            name: "Customer / Client",
            selector: row => row.named_customer_client,
            cell: (row, index) =>
            (
                <div className="project-table w-100">
                    <input
                        type="text"
                        name="named_customer_client"
                        value={row.named_customer_client}
                        className="form-control w-100"
                        onChange={(e) => handleEditInputChange(e, index, "named_customer_client", row.id)}
                    />
                </div>
            ),
            sortable: true,
        },
        {
            name: "Owner",
            selector: row => row.owner,
            cell: (row, index) =>
            (
                <div className="project-table w-100">
                    <input
                        type="text"
                        name="owner"
                        value={row.owner}
                        className="form-control w-100"
                        onChange={(e) => handleEditInputChange(e, index, "owner", row.id)}
                    />
                </div>
            ),
            sortable: true,
        },

        {
            name: "Due Date",
            selector: row => row.date,
            cell: (row, index) =>
            (
                <div className="project-table w-100">
                    <input
                        type="date"
                        name="due_date"
                        value={row.due_date}
                        className="form-control w-100"
                        onChange={(e) => handleEditInputChange(e, index, "due_date", row.id)}
                        min={new Date().toISOString().split('T')[0]}
                    />
                </div>
            ),
            sortable: true,
        },
        {
            name: "Status",
            selector: row => row.status,
            cell: (row, index) =>
            (
                <div className="project-table w-100">
                    <select
                        value={row.status}
                        className=" w-100"
                        name="status"
                        onChange={(e) => handleEditInputChange(e, index, "status", row.id)}
                    >
                        <option value="">Select Status</option>
                        <option value="Completed">Completed</option>
                        <option value="Scheduled">Scheduled</option>
                        <option value="In_process">In Process</option>
                        <option value="On_hold">On Hold</option>
                    </select>
                </div>
            ),
            sortable: true,
        },
        {
            name: "Notes",
            selector: row => row.notes,
            cell: (row, index) =>
            (
                <div className="project-table w-100">
                    <textarea
                        type="text"
                        name="notes"
                        value={row.notes}
                        className="form-control w-100"
                        onChange={(e) => handleEditInputChange(e, index, "notes", row.id)}
                    />
                </div>
            ),
            sortable: true,
        },
        {
            name: "View",
            selector: row => row.owner,
            cell: (row) => (
                <div className='d-flex justify-content-end mx-2 my-3 workflow_sales_data'>
                    <a href={`/project/project-gannt?id=${row.id}`} className='link'>
                        View Project
                    </a>
                </div>
            ),
            sortable: true,
        }
    ];

    return (
        <>
            <Container fluid={true}>
                {/* <BackButton /> */}
                <Card>
                    <div className="card-header d-flex flex-wrap justify-content-between align-items-center ">
                        <div className="d-flex align-items-center flex-grow-1">
                            <h5>Project Name</h5>
                        </div>
                        <div >
                            <BackButton />
                        </div>
                    </div>
                    <CardBody>
                        <form className="mb-3">
                            <div className="row">
                                <div className="form-group mb-3 col-lg-3">
                                    <label><b>Project Name</b></label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.project_name ? 'is-invalid' : ''}`}
                                        name="project_name"
                                        value={formData.project_name}
                                        onChange={handleInputChange}
                                        placeholder="Project Name"
                                    />
                                    {errors.project_name && <div className="invalid-feedback">{errors.project_name}</div>}
                                </div>

                                <div className="form-group mb-3 col-lg-3">
                                    <label><b>Customer / Client</b></label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.named_customer_client ? 'is-invalid' : ''}`}
                                        name="named_customer_client"
                                        value={formData.named_customer_client}
                                        onChange={handleInputChange}
                                        placeholder="Customer / Client"
                                    />
                                    {errors.named_customer_client && <div className="invalid-feedback">{errors.named_customer_client}</div>}
                                </div>
                                <div className="form-group mb-3 col-lg-3">
                                    <label><b>Owner</b></label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.owner ? 'is-invalid' : ''}`}
                                        name="owner"
                                        value={formData.owner}
                                        onChange={handleInputChange}
                                        placeholder="Owner"
                                    />
                                    {errors.owner && <div className="invalid-feedback">{errors.owner}</div>}
                                </div>

                                <div className="form-group mb-3 col-lg-3">
                                    <label><b>Due Date</b></label>
                                    <input
                                        type="date"
                                        className={`form-control ${errors.due_date ? 'is-invalid' : ''}`}
                                        name="due_date"
                                        value={formData.due_date}
                                        onChange={handleInputChange}
                                        min={new Date().toISOString().split('T')[0]}
                                    />
                                    {errors.due_date && <div className="invalid-feedback">{errors.due_date}</div>}
                                </div>
                                <div className="form-group mb-3 col-lg-3">
                                    <label><b>Status</b></label>
                                    <select
                                        className={`form-control ${errors.status ? 'is-invalid' : ''}`}
                                        name="status"
                                        value={formData.status}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="Completed">Completed</option>
                                        <option value="Scheduled">Scheduled</option>
                                        <option value="In_process">In Process</option>
                                        <option value="On_hold">On Hold</option>
                                    </select>
                                    {errors.status && <div className="invalid-feedback">{errors.status}</div>}
                                </div>

                                <div className="form-group mb-3 col-lg-6">
                                    <label><b>Notes</b></label>
                                    <textarea
                                        type="text"
                                        className={`form-control ${errors.notes ? 'is-invalid' : ''}`}
                                        name="notes"
                                        value={formData.notes}
                                        onChange={handleInputChange}
                                        placeholder="notes"
                                    />
                                    {errors.notes && <div className="invalid-feedback">{errors.notes}</div>}
                                </div>

                            </div>
                            <div className="align-content-center d-flex form-group justify-content-end">

                                <Button
                                    variant="success"
                                    onClick={handleSubmit}
                                    disabled={!isButtonEnabled}
                                >
                                    Save
                                </Button>
                            </div>
                        </form>
                        <div className='mb-2 d-flex justify-content-end'>
                            <div className="form-group mb-2 text-end  ">
                                <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                            </div>
                        </div>
                        <DataTable
                            columns={columns}
                            data={filteredEntries}
                            persistTableHead={true}
                            pagination
                            className="rolmodel-table"
                        />
                    </CardBody>
                </Card>
            </Container>
        </>
    );
};

export default ProjectName;