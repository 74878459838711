import React, { Fragment, useEffect } from "react";
import Header from "./common/header-component/header";
// import RightSidebar from "./common/right-sidebar";
import Footer from "./common/footer";
import { ToastContainer } from "react-toastify";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// import Sidebar from "./common/sidebar-component/sidebar";
import AdminSidebar from "./common/adminSidebar/sidebar";
import Chatbot from "./common/chatbot/chatBot";

const AppLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const role = sessionStorage.getItem("role");

  useEffect(() => {
    if (location.pathname.includes("/admin")) {
      sessionStorage.setItem("role", "true");
    } else if (location.pathname === "/login") {
      sessionStorage.setItem("role", "false");
    } else {
      sessionStorage.setItem("role", "false");
    }
  }, [location.pathname, navigate]);


  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="page-body-wrapper">
          <Header />
          {role === "true" ? <AdminSidebar /> : ""}

          <div className="page-body">
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
      {/* <Chatbot /> */}
    </Fragment>
  );
};

export default AppLayout;
