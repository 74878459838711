import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import API, { isSubscribedAccountant } from "../../../utils";
import { format } from 'date-fns';

const EventCharts = () => {
  const [people, setPeople] = useState([]);
  const user_id = localStorage.getItem('user_id');

  const fetchData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', user_id);
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.deshboard_meeting_data}`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        setPeople(Array.isArray(response.data.data) ? response.data.data : []);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Fetch meetings error', error);
    }
  };

  const columns = [
    {
      name: 'Meeting Date',
      cell: row => format(new Date(row.created_at), 'dd-MM-yyyy'),
      sortable: true,
    },
    {
      name: 'Meeting Name',
      cell: row => row.meeting_name,
      sortable: true
    },
    {
      name: 'Meeting Type',
      cell: row => {
        let types = [];
        try {
          types = JSON.parse(row.meeting_type); // Convert JSON string to array
        } catch (error) {
          console.error("Invalid JSON format in meeting_type", error);
        }

        return (
          <ul style={{ paddingLeft: '15px', margin: 0 }}>
            {Array.isArray(types) && types.map((type, index) => (
              <li key={index}>{type}</li>
            ))}
          </ul>
        );
      },
      sortable: true,
    },
    // {
    //   name: 'Status',
    //   selector: row => row.status,
    //   cell: row => (
    //     <div
    //       style={{
    //         backgroundColor: row.status === 'completed' ? '#39e026' : (row.status === 'scheduled' ? '#bababa' : ''),
    //         padding: '8px',
    //         borderRadius: '4px',
    //         textAlign: 'center',
    //       }}
    //     >
    //       {row.status}
    //     </div>
    //   ),
    // }

    {
      name: 'Status',
      selector: row => row.status,
      cell: row => {
        const status = row.status || "panding";
        const backgroundColor =
          status === "completed" ? "#39e026" :
            status === "scheduled" ? "#bababa" :
              "#FFC107"; // Default background color if status is missing

        return (
          <div
            style={{
              backgroundColor,
              padding: '8px',
              borderRadius: '4px',
              textAlign: 'center',
              minWidth: '80px', // Ensures consistent box size
            }}
          >
            {status}
          </div>
        );
      },
    }

  ];

  useEffect(() => {
    fetchData();
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    if (!isSubscribedAccountant()) {
      navigate("/users/billings");
      window.location.reload();
    }
  }, []);

  return (
    <Fragment>
      <div className="activity">
        <DataTable
          columns={columns}
          data={people}
          persistTableHead={true}
          pagination
        />
      </div>
    </Fragment>
  );
};

export default EventCharts;