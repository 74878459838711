import React, { useState, useEffect, useRef } from "react";
import { FaBullhorn, FaCalendarAlt, FaChartLine, FaClipboardList, FaComments, FaList, FaRobot } from "react-icons/fa";
import { IoIosSend } from "react-icons/io";
import axios from "axios";
import "../../../assets/css/chatbot.css";
import API from "../../../utils";
import Favicon from "../../../assets/images/logo/bg-sort.png";

const Chatbot = ({isopen}) => {
  const [isOpen, setIsOpen] = useState(isopen);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [activeButton, setActiveButton] = useState(null);
  const chatContainerRef = useRef(null);
  const uid = localStorage.getItem("user_id");

  useEffect(() => {
    const storedUserName = localStorage.getItem("user_name");
    if (storedUserName) {
      setUserName(storedUserName);
    }
  }, []);

  const handleSendMessage = async () => {
    if (input.trim()) {
      setMessages([...messages, { text: input, user: "user" }]);
      setInput("");
      setIsLoading(true);

      const formDataToSend = new FormData();
      formDataToSend.append("user_id", uid);
      formDataToSend.append("message", input);

      try {
        const response = await axios.post(
          `${API.BASE_URL}${API.ENDPOINTS.openai_chat}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        let botResponse = response.data.reply.choices[0].message.content;
        botResponse = botResponse.replace(/\*\*/g, '');
        setMessages((prev) => [...prev, { text: botResponse, user: "bot" }]);
      } catch (error) {
        console.error("Error sending message to OpenAI API:", error);
        setMessages((prev) => [
          ...prev,
          { text: "Sorry, I couldn't process your request.", user: "bot" },
        ]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCloseChatbot = () => {
    setIsOpen(false);
    setIsChatOpen(false);
  };

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages, isLoading]);

  return (
    <>
      {/* <div className="chatbot-button" onClick={() => setIsOpen(!isOpen)}>
        <FaComments size={24} />
      </div> */}

      {isOpen && (
        <div
          className={`chatbot-container  ${isExpanded ? "expanded" : ""}`}
          id="webcrumbs"
        >
          {!isChatOpen ? (
            <div className="bg-gray-50 h-[600px] w-full mx-auto rounded-lg  overflow-hidden flex flex-col relative">
              <div className="bg-white p-4 border-b border-gray-200 flex items-center justify-between">
              <button
                    className="text-gray-600 hover:text-gray-800 transition-colors"
                    onClick={handleCloseChatbot}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                {/* <div className="flex -space-x-2">
                  <img src={Favicon} alt="robot" className="w-50 h-50" />
                </div> */}
                <div className="flex items-center space-x-2">
                  <button
                    className="text-gray-600 hover:text-gray-800 transition-colors"
                    onClick={toggleExpand}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path stroke="currentColor" strokeWidth="1.7" d="m1.999 2 5 5"></path>
                      <path stroke="currentColor" strokeWidth="1.75" d="M6.499 1.5h-5v5m7.5 2.5 5 5m.5-4.5v5h-5"></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div className="chatbot-content">
                <h3>{userName ? `Hi ${userName} 👋` : "Hi there 👋"}</h3>
                <p>How can we help?</p>

                <div className="chat-options">
                  <button className="chat-option-btn p-2" onClick={() => setIsChatOpen(true)}>
                    Send us a message
                  </button>
                  <button className="chat-option-btn p-2" onClick={() => setIsChatOpen(true)}>Search for help</button>
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-gray-50 h-[600px] w-full mx-auto rounded-lg shadow-md overflow-hidden flex flex-col relative">
              <div className="bg-white p-4 border-b border-gray-200 flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <button
                    className="text-gray-600 hover:text-gray-800 transition-colors"
                    onClick={handleCloseChatbot}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  <div className="flex -space-x-2">
                    <div className="chatbot-logo">founders os</div>
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <button
                    className="text-gray-600 hover:text-gray-800 transition-colors"
                    onClick={toggleExpand}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path stroke="currentColor" strokeWidth="1.7" d="m1.999 2 5 5"></path>
                      <path stroke="currentColor" strokeWidth="1.75" d="M6.499 1.5h-5v5m7.5 2.5 5 5m.5-4.5v5h-5"></path>
                    </svg>
                  </button>
                </div>
              </div>

              <div
                className="flex-1 overflow-y-auto space-y-4"
                ref={chatContainerRef}
              >
                <div className="chat-page">
                  <div className="chat-messages">
                    {messages.length === 0 ? (
                      <>
                      <div className="bg-gray-100 rounded-lg p-3 max-w-[80%]">
                        <div className="flex items-center mb-1">
                          <div className="mr-2 bg-gradient-to-r from-blue-400 to-purple-500 p-1 rounded">
                            <span className="material-symbols-outlined text-md">smart_toy</span>
                          </div>
                          <span className="font-medium text-gray-800">Founders Os</span>
                        </div>
                        <p className="text-gray-700 text-sm">What brings you here today?</p>
                      </div>
                      <div className="mt-2">
                        {activeButton === null && (
                          <>
                            <button
                              className="chat-option-btn p-2 mb-2 flex items-center"
                              onClick={() => setActiveButton('mentor')}
                            >
                              <FaRobot className="mr-2" /> Work With My AI Business Mentor
                            </button>
                            <button
                              className="chat-option-btn p-2 mb-2 flex items-center"
                              onClick={() => setActiveButton('todo')}
                            >
                              <FaList className="mr-2" /> Review My To-Do List
                            </button>
                            <button
                              className="chat-option-btn p-2 mb-2 flex items-center"
                              onClick={() => setActiveButton('sales')}
                            >
                              <FaChartLine className="mr-2" /> Work on Sales
                            </button>
                            <button
                              className="chat-option-btn p-2 mb-2 flex items-center"
                              onClick={() => setActiveButton('marketing')}
                            >
                              <FaBullhorn className="mr-2" /> Work on Marketing
                            </button>
                            <button
                              className="chat-option-btn p-2 mb-2 flex items-center"
                              onClick={() => setActiveButton('plan')}
                            >
                              <FaClipboardList className="mr-2" /> Work on My Founders OS Plan
                            </button>
                            <button
                              className="chat-option-btn p-2 mb-2 flex items-center"
                              onClick={() => setActiveButton('kpis')}
                            >
                              <FaCalendarAlt className="mr-2" /> Review My Monthly KPIs
                            </button>
                            <button
                              className="chat-option-btn p-2 mb-2 flex items-center"
                              onClick={() => setActiveButton('faqs')}
                            >
                              <FaList className="mr-2" /> FAQs
                            </button>
                            <button
                              className="chat-option-btn p-2 mb-2 flex items-center"
                              onClick={() => setActiveButton('support')}
                            >
                              <FaRobot className="mr-2" /> Contact Support
                            </button>
                            <button
                              className="chat-option-btn p-2 mb-2 flex items-center"
                              onClick={() => setActiveButton('resources')}
                            >
                              <FaChartLine className="mr-2" /> Resources
                            </button>
                          </>
                        )}
                        {activeButton === 'mentor' && (
                          <>
                            <button
                              className="chat-option-btn p-2 mb-2 flex items-center"
                              onClick={() => setActiveButton(null)}
                            >
                              <FaRobot className="mr-2" /> Work With My AI Business Mentor
                            </button>
                            <p>Here are some options for working with your AI Business Mentor:</p>
                            {/* Add related options or suggestions here */}
                          </>
                        )}
                        {activeButton === 'todo' && (
                          <>
                            <button
                              className="chat-option-btn p-2 mb-2 flex items-center"
                              onClick={() => setActiveButton(null)}
                            >
                              <FaList className="mr-2" /> Review My To-Do List
                            </button>
                            <p>Here are some options for reviewing your To-Do List:</p>
                            {/* Add related options or suggestions here */}
                          </>
                        )}
                        {activeButton === 'sales' && (
                          <>
                            <button
                              className="chat-option-btn p-2 mb-2 flex items-center"
                              onClick={() => setActiveButton(null)}
                            >
                              <FaChartLine className="mr-2" /> Work on Sales
                            </button>
                            <p>Here are some options for working on Sales:</p>
                            {/* Add related options or suggestions here */}
                          </>
                        )}
                        {activeButton === 'marketing' && (
                          <>
                            <button
                              className="chat-option-btn p-2 mb-2 flex items-center"
                              onClick={() => setActiveButton(null)}
                            >
                              <FaBullhorn className="mr-2" /> Work on Marketing
                            </button>
                            <p>Here are some options for working on Marketing:</p>
                            {/* Add related options or suggestions here */}
                          </>
                        )}
                        {activeButton === 'plan' && (
                          <>
                            <button
                              className="chat-option-btn p-2 mb-2 flex items-center"
                              onClick={() => setActiveButton(null)}
                            >
                              <FaClipboardList className="mr-2" /> Work on My Founders OS Plan
                            </button>
                            <p>Here are some options for working on your Founders OS Plan:</p>
                            {/* Add related options or suggestions here */}
                          </>
                        )}
                        {activeButton === 'kpis' && (
                          <>
                            <button
                              className="chat-option-btn p-2 mb-2 flex items-center"
                              onClick={() => setActiveButton(null)}
                            >
                              <FaCalendarAlt className="mr-2" /> Review My Monthly KPIs
                            </button>
                            <p>Here are some options for reviewing your Monthly KPIs:</p>
                            {/* Add related options or suggestions here */}
                          </>
                        )}
                        {activeButton === 'faqs' && (
                          <>
                            <button
                              className="chat-option-btn p-2 mb-2 flex items-center"
                              onClick={() => setActiveButton(null)}
                            >
                              <FaList className="mr-2" /> FAQs
                            </button>
                            <p>Here are some FAQs:</p>
                            {/* Add related options or suggestions here */}
                          </>
                        )}
                        {activeButton === 'support' && (
                          <>
                            <button
                              className="chat-option-btn p-2 mb-2 flex items-center"
                              onClick={() => setActiveButton(null)}
                            >
                              <FaRobot className="mr-2" /> Contact Support
                            </button>
                            <p>Here are some options for contacting support:</p>
                            {/* Add related options or suggestions here */}
                          </>
                        )}
                        {activeButton === 'resources' && (
                          <>
                            <button
                              className="chat-option-btn p-2 mb-2 flex items-center"
                              onClick={() => setActiveButton(null)}
                            >
                              <FaChartLine className="mr-2" /> Resources
                            </button>
                            <p>Here are some resources:</p>
                            {/* Add related options or suggestions here */}
                          </>
                        )}
                      </div>
                      </>
                    ) : (
                      messages.map((msg, index) => (
                        <div key={index} className={`chat-message ${msg.user}`}>
                          {msg.text.split("\n").map((line, i) => (
                            <React.Fragment key={i}>
                              {line}
                              <br />
                            </React.Fragment>
                          ))}
                        </div>
                      ))
                    )}
                    {isLoading && (
                      <div className="loading-message bot">
                        <div className="typing-indicator">
                          <div className="typing-circle"></div>
                          <div className="typing-circle"></div>
                          <div className="typing-circle"></div>
                          <div className="typing-shadow"></div>
                          <div className="typing-shadow"></div>
                          <div className="typing-shadow"></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="bg-white border-t border-gray-200 p-3">
                <div className="flex items-center bg-gray-100 rounded-full px-4 py-2">
                  <input
                    type="text"
                    placeholder="Message..."
                    className="bg-transparent flex-1 outline-none text-sm"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
                  />
                  <button onClick={handleSendMessage} className="text-gray-400 hover:text-gray-600 transition-colors">
                    <IoIosSend size={24} />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Chatbot;
