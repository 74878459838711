import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import API from "../../../utils";
import { barChartOptions } from './monthlychartdata';
import MypuffLoader from "../../common/MypuffLoader";
import TotalRevenue from "./totalrevenue";
import AccountsPayable from "./accountspayable";
import BackButton from "../../common/header-component/backButton";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const Monthly = () => {
    const [scorecardsData, setScorecardsData] = useState({ labels: [], datasets: [] });
    const uid = localStorage.getItem("user_id");
    const [loading, setLoading] = useState(false);

    const fetchScorecards = async () => {
        setLoading(true);

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_monthly_reporting_goal_scorecard}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                const scorecardData = response.data.data;

                const labels = Object.values(scorecardData).map(item => item.month);
                const data = Object.values(scorecardData).map(item => item.count ? parseInt(item.count, 10) : 0);

                const formattedChartData = {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Reports',
                            data: data,
                            backgroundColor: "#007bff",
                            borderColor: "#007bff",
                            borderWidth: 2,
                        },
                    ],
                };

                setScorecardsData(formattedChartData);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch scorecards error', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchScorecards();
    }, []);

    return (
        <>
            <div className="container-fluid">
                {/* <BackButton /> */}
                <div className="row">
                    <div className="col-sm-12">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header d-flex flex-wrap justify-content-between align-items-center ">
                                    <div className="d-flex align-items-center flex-grow-1">
                                        <h5 >Reports</h5>
                                    </div>
                                    <div >
                                        <BackButton />
                                    </div>
                                </div>
                                <div className="card-body chart-block ">
                                    {loading ? (
                                        <div className="d-flex justify-content-center my-3">
                                            <MypuffLoader active={loading} />
                                        </div>
                                    ) : (
                                        <Bar data={scorecardsData} options={barChartOptions} width={778} height={300} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <TotalRevenue />
            <AccountsPayable />
        </>
    );
};

export default Monthly;
