import React, { useState, useEffect, useCallback } from "react";
import { Container } from "react-bootstrap";
import API from "../../../utils";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from "lodash";
import BackButton from "../../common/header-component/backButton";

const Uniquevalueproposition = () => {
  const uid = localStorage.getItem("user_id");
  const [formData, setFormData] = useState({
    foundationstorys: "",
  });
  const role = localStorage.getItem("role"); // Fetch the role from localStorage

  const fetchData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);
      formDataToSend.append('category_id', 6);
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_all_category_question}`,
        formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        const VisionAnswer = response.data.data[0].answer;
        setFormData({ foundationstorys: VisionAnswer });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Fetch gratitude error', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Debounced API call
  const debouncedApiCall = useCallback(
    _.debounce(async (name, value) => {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);
      formDataToSend.append('category_id', 6);
      formDataToSend.append('answer', value);

      try {
        const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_updated_category_question}`,
          formDataToSend, {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`
          },
        });
        if (response.data.status === "success") {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Profile update failed.");
      }
    }, 2000),
    [] // Empty dependency array to keep the debounce function stable
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));

    // Call debounced function
    debouncedApiCall(name, value);
  };

  return (
    <Container fluid={true}>
      {/* <BackButton /> */}
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            {/* <div className="card-header">
              <h5>Unique Value Proposition</h5>
            </div> */}
            <div className="card-header d-flex flex-wrap justify-content-between align-items-center ">
              <div className="d-flex align-items-center flex-grow-1">
                <h5>Unique Value Proposition</h5>
              </div>
              <div >
                <BackButton />
              </div>
            </div>
            <div className="card-body">
              <div className="form-group row">
                <label><b>Unique Value Proposition</b></label>
                <div>
                  <textarea
                    className="form-control"
                    name="foundationstorys"
                    value={formData.foundationstorys}
                    onChange={handleChange}
                    rows="4"
                    readOnly={role === "3"}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Uniquevalueproposition;
