import React from "react";
import { Container } from "react-bootstrap";
import GrowthDataTable from "./growthdataTable";
import KeyResponsibilitiesTable from "./keyresponsibilitiesTable";
import SkillsTable from "./skillstable";
import BackButton from "../../common/header-component/backButton";

const JobScorecard = () => {
  return (
    <Container fluid={true}>
      {/* <BackButton /> */}
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            {/* <div className="card-header">
              <div className="row">
                <div className="align-items-center d-flex">
                  <h5>Job Scorecard</h5>
                </div>
              </div>
            </div> */}
            <div className="card-header d-flex flex-wrap justify-content-between align-items-center ">
              <div className="d-flex align-items-center flex-grow-1">
                <h5>Job Scorecard</h5>
              </div>
              <div >
                <BackButton />
              </div>
            </div>
            <div className="col-lg-12">

              <GrowthDataTable />
              <hr />
              <KeyResponsibilitiesTable />
              <hr />
              <SkillsTable />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default JobScorecard;
