import React from 'react';
import { Modal, Button, Form} from 'react-bootstrap';

const EditAvatarModal = ({ show, onHide, formData, onChange, onSave, onAddField, onRemoveField }) => {
    return (
        <Modal show={show} onHide={onHide} >
            <Modal.Header closeButton>
                <Modal.Title>Edit Avatar</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="row" >
                <div className='col-lg-6'>
                        <div className="form-group">
                            <label className="col-form-label pt-0" htmlFor="image"><b>Avatar Image</b></label>
                            <input
                                className="form-control"
                                name="image"
                                type="file"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formData.name || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formAge">
                            <Form.Label>Age</Form.Label>
                            <Form.Control
                                type="text"
                                name="age"
                                value={formData.age || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>

                        <Form.Group controlId="formGender">
                            <Form.Label>Gender</Form.Label>
                            <Form.Control
                                type="text"
                                name="gender"
                                value={formData.gender || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formLocation">
                            <Form.Label>Location</Form.Label>
                            <Form.Control
                                type="text"
                                name="location"
                                value={formData.location || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formProfession">
                            <Form.Label>Profession</Form.Label>
                            <Form.Control
                                type="text"
                                name="profession"
                                value={formData.profession || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formHobbies">
                            <Form.Label>Hobbies</Form.Label>
                            <Form.Control
                                type="text"
                                name="hobbies"
                                value={formData.hobbies || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formEducation">
                            <Form.Label>Education</Form.Label>
                            <Form.Control
                                type="text"
                                name="education"
                                value={formData.education || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formIncome">
                            <Form.Label>Income</Form.Label>
                            <Form.Control
                                type="text"
                                name="income"
                                value={formData.income || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formFamily">
                            <Form.Label>Family</Form.Label>
                            <Form.Control
                                type="text"
                                name="family"
                                value={formData.family || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formInfluencers">
                            <Form.Label>Influencers</Form.Label>
                            <Form.Control
                                type="text"
                                name="Influencers"
                                value={formData.Influencers || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formBrandTheyLove">
                            <Form.Label>Brands They Love</Form.Label>
                            <Form.Control
                                type="text"
                                name="brand_they_love"
                                value={formData.brand_they_love || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formSkills">
                            <Form.Label>Skills/Expertise</Form.Label>
                            <Form.Control
                                type="text"
                                name="skills"
                                value={formData.skills || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formSources">
                            <Form.Label>Sources</Form.Label>
                            <Form.Control
                                type="text"
                                name="sources"
                                value={formData.sources || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formSocialMediaUse">
                            <Form.Label>Social Media Used</Form.Label>
                            <Form.Control
                                type="text"
                                name="social_media_use"
                                value={formData.social_media_use || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Close</Button>
                <Button variant="success" onClick={onSave}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditAvatarModal;
