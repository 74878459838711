import React, { useEffect } from "react";
import { Container, Row, Nav } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { FaAnchor, FaBullhorn, FaChartBar, FaHome, FaMoneyBill, FaPowerOff } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isSubscribedAccountant } from "../../utils";

const CashIndex = () => {
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        // centerMode: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false
                }
            }
        ]
    };

    const navigate = useNavigate()
    useEffect(() => {
        if (!isSubscribedAccountant()) {
            navigate("/users/billings");
            window.location.reload();
        }
    }, []);

    return (
        <Container fluid={true}>
            <Row className="mx-3">
                <Slider {...settings}>
                    <div className="slider-item">
                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="/labor-efficiency-ratio" exact>
                                    <FaHome className="me-1" /> Labor Efficiency Ratio
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="slider-item">
                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="/cash-acceleration-strategies" exact>
                                    <FaMoneyBill className="me-1" /> Cash Acceleration Strategies
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="slider-item">
                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="/cash-compensation" exact>
                                    <FaAnchor className="me-1" /> Cash Compensation
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="slider-item">
                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="/the-power-of-one" exact>
                                    <FaPowerOff className="me-1" /> The Power of One
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="slider-item">
                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="/fundability-improvement" exact>
                                    <FaBullhorn className="me-1" /> Fundability Improvement
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="slider-item">
                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="/value-Improvement-from-recurringrevenue" exact>
                                    <FaChartBar className="me-1" /> Value Improvement from Recurring Revenue
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>

                </Slider>
            </Row>
        </Container>
    );
};

export default CashIndex;
