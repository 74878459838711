import React, { useEffect, useState, useCallback } from "react";
import { Container } from "react-bootstrap";
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import MyLoader from "../../common/myloader";
import BackButton from "../../common/header-component/backButton";

const BusinessModelCanvas = () => {
  const user_id = localStorage.getItem('user_id');
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState({
    key_partners: "",
    key_activitis: "",
    value_proposition: "",
    customer_relationship: "",
    customer_segments: "",
    key_resources: "",
    channals: "",
    cost_structure: "",
    revanue_streams: "",
    enviromental_cost: "",
    societal_cost: "",
    societal_benefits: "",
    enviromental_benefits: "",
  });

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    setLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append('user_id', user_id);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_all_bussiness_canvas}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );


      if (response.data.status === 'success') {
        const formattedData = response.data.data[0] || {};

        const sanitizedData = Object.fromEntries(
          Object.entries(formattedData).map(([key, value]) => [key, value === "null" ? "" : value])
        );

        setTableData(sanitizedData);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Error during task fetching:', error);
      toast.error("An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  };

  const debouncedUpdateTask = useCallback(
    debounce(async (updatedData) => {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', user_id);
      Object.keys(updatedData).forEach((key) => {
        formDataToSend.append(key, updatedData[key]);
      });

      try {
        const response = await axios.post(
          `${API.BASE_URL}${API.ENDPOINTS.add_update_bussiness_canvas}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
            },
          }
        );

        if (response.data.status === "success") {
          fetchTasks();
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("An error occurred while updating data.");
      }
    }, 2000),
    [user_id]
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...tableData, [name]: value };
    setTableData(updatedData);
    debouncedUpdateTask(updatedData);
  };

  const tableStyle = {
    border: "1px solid gray",
    borderCollapse: "collapse",
    width: "100%",
  };

  const thStyle = {
    border: "1px solid gray",
    padding: "8px",
    textAlign: "left",
    background: "#161b40",
    color: "white",
  };

  const tdStyle = {
    border: "1px solid gray",
    padding: "8px",
    textAlign: "left",
    height: "300px",
  };

  return (
    <MyLoader active={loading} >
      <Container fluid={true}>
        {/* <BackButton /> */}
        <div className="row">
          <div className="col-sm-12">
            <div className="card ">
              {/* <div className="card-header">
                <div className="row">
                  <div className="align-items-center d-flex">
                    <h5>Business Model Canvas</h5>
                  </div>
                </div>
              </div> */}
              <div className="card-header d-flex flex-wrap justify-content-between align-items-center ">
                <div className="d-flex align-items-center flex-grow-1">
                  <h5>Business Model Canvas</h5>
                </div>
                <div >
                  <BackButton />
                </div>
              </div>
              <div className="life-planing-div">
                <table className="mt-4 life-planing-table table table-bordered" style={tableStyle}>
                  <thead>
                    <tr>
                      <th style={thStyle}>KEY PARTNERS</th>
                      <th style={thStyle}>KEY ACTIVITIES</th>
                      <th style={thStyle}>VALUE PROPOSITIONS</th>
                      <th style={thStyle}>CUSTOMER RELATIONSHIPS</th>
                      <th style={thStyle}>CUSTOMER SEGMENTS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={tdStyle} rowSpan={3}>
                        <textarea
                          name="key_partners"
                          value={tableData.key_partners}
                          onChange={handleChange}
                          style={{ width: "100%", height: "100%", border: "none" }}
                        />
                      </td>
                      <td style={tdStyle}>
                        <textarea
                          name="key_activitis"
                          value={tableData.key_activitis}
                          onChange={handleChange}
                          style={{ width: "100%", height: "100%", border: "none" }}
                        />
                      </td>
                      <td style={tdStyle} rowSpan={3}>
                        <textarea
                          name="value_proposition"
                          value={tableData.value_proposition}
                          onChange={handleChange}
                          style={{ width: "100%", height: "100%", border: "none" }}
                        />
                      </td>
                      <td style={tdStyle}>
                        <textarea
                          name="customer_relationship"
                          value={tableData.customer_relationship}
                          onChange={handleChange}
                          style={{ width: "100%", height: "100%", border: "none" }}
                        />
                      </td>
                      <td style={tdStyle} rowSpan={3}>
                        <textarea
                          name="customer_segments"
                          value={tableData.customer_segments}
                          onChange={handleChange}
                          style={{ width: "100%", height: "100%", border: "none" }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={thStyle}>KEY RESOURCES</th>
                      <th style={thStyle}>CHANNELS</th>
                    </tr>
                    <tr>
                      <td style={tdStyle}>
                        <textarea
                          name="key_resources"
                          value={tableData.key_resources}
                          onChange={handleChange}
                          style={{ width: "100%", height: "100%", border: "none" }}
                        />
                      </td>
                      <td style={tdStyle}>
                        <textarea
                          name="channals"
                          value={tableData.channals}
                          onChange={handleChange}
                          style={{ width: "100%", height: "100%", border: "none" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="life-planing-div">
                <table className="life-planing-table table table-bordered">
                  <thead>
                    <tr>
                      <th style={thStyle} colSpan={2}>COST STRUCTURE</th>
                      <th style={thStyle} colSpan={2}>REVENUE STREAMS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={tdStyle} colSpan={2}>
                        <textarea
                          name="cost_structure"
                          value={tableData.cost_structure}
                          onChange={handleChange}
                          style={{ width: "100%", height: "100%", border: "none" }}
                        />
                      </td>
                      <td style={tdStyle} colSpan={2}>
                        <textarea
                          name="revanue_streams"
                          value={tableData.revanue_streams}
                          onChange={handleChange}
                          style={{ width: "100%", height: "100%", border: "none" }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={thStyle}>ENVIRONMENTAL COSTS</th>
                      <th style={thStyle}>SOCIETAL COSTS</th>
                      <th style={thStyle}>SOCIETAL BENEFITS</th>
                      <th style={thStyle}>ENVIRONMENTAL BENEFITS</th>
                    </tr>
                    <tr>
                      <td style={tdStyle}>
                        <textarea
                          name="enviromental_cost"
                          value={tableData.enviromental_cost}
                          onChange={handleChange}
                          style={{ width: "100%", height: "100%", border: "none" }}
                        />
                      </td>
                      <td style={tdStyle}>
                        <textarea
                          name="societal_cost"
                          value={tableData.societal_cost}
                          onChange={handleChange}
                          style={{ width: "100%", height: "100%", border: "none" }}
                        />
                      </td>
                      <td style={tdStyle}>
                        <textarea
                          name="societal_benefits"
                          value={tableData.societal_benefits}
                          onChange={handleChange}
                          style={{ width: "100%", height: "100%", border: "none" }}
                        />
                      </td>
                      <td style={tdStyle}>
                        <textarea
                          name="enviromental_benefits"
                          value={tableData.enviromental_benefits}
                          onChange={handleChange}
                          style={{ width: "100%", height: "100%", border: "none" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </MyLoader>
  );
};

export default BusinessModelCanvas;
