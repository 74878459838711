import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import BackButton from "../../common/header-component/backButton";

const Sales = () => {
  const checklists = {
    physical: [
      "Quarterly Therme/Critical Number posted throughout the company are aware of the progress each week.",
      "The Critical Number is identified to move the company ahead this quarter",
      "3-5 Priorities (Rocks) that support the Critical Number are identified and ranked for the quarter",
      "A Quarterly Theme and Celebration/Reward are announced to all employees that bring the Critical Number to life."
    ],
    mental: [
      "All employees are in a daily huddle that lasts less than 15 minutes",
      "All teams have a weekly meeting.",
      "The executive and middle managers meet for a day of learning, resolving big issues, and DNA transfer each month",
      "Quarterly and annually, the executive and middle managers meet offsite to work on the 4 Decisions"
    ],
    spiritual: [
      "The Function Accountability Chart (FACE) is completed (right people, doing the right things, right)",
      "Financial statements have a person assigned to each line item",
      "Each of the 49 processes on the Process Accountability Chart (PACe) has someone that is accountable for them",
      "Each 3-5 year Key Thrust/Capability has a corresponding expert on the Advisory Board if internal expertise doesn't exist",
    ],
    socialEmotional: [
      "All executives (and middle managers) have a Start/Stop/Keep conversation with at least one employee weekly.",
      "The insights from employee conversations are shared at the weekly executive team meeting",
      "Employee input about obstacles and opportunities is being collected weekly",
      "A mid-management team is responsible for the process of closing the loop on all obstacles and opportunities",
    ],
    employee: [
      "All executives (and middle managers) have a Start/Stop/Keep conversation with at least one employee weekly.",
      "The insights from employee conversations are shared at the weekly executive team meeting",
      "Employee input about obstacles and opportunities is being collected weekly",
      "A mid-management team is responsible for the process of closing the loop on all obstacles and opportunities",
    ],
    reporting: [
      "All executives (and middle managers) have a 40 conversation with at least one end user weekly.",
      "The insights from customer conversations are shared at the weekly executive team meeting",
      "All employees are involved in collecting customer data",
      "A mid-management team is responsible for the process of closing the loop on all customer feedback",
    ],
    purpose: [
      "Core Values are discovered, Purpose is articulated, and both are known by all employees",
      "All executives and middle managers refer back to the Core Values and Purpose when giving praise or reprimands.",
      "Actions are identified and implemented each quarter to strengthen the Core Values and Purpose in the organization",
      "HR processes and activities align with the Core Values and Purpose (hiring, orientation, appraisal, recognition, etc.)",
    ],
    articulate: [
      "Big Hairy Audacious Goal (BHAG)-Progress is tracked and visible",
      "Core Customer(s) Their profile in 25 words or less",
      "3 Brand Promises And the corresponding Brand Promise KPIs reported on weekly.",
      "Elevator Pitch- A compelling response to the question What does your company do?"
    ],
    quantitatively: [
      "1 or 2 Key Performance Indicators (KPIs) are reported on weekly for each role/person.",
      "Each employee has 1 Critical Number that aligns with the company's Critical Number for the quarter (clear line of sight)",
      "All executives and middle managers have a coach (or peer coach) holding them accountable to behavior changes",
      "Each individual/team has 3-5 Quarterly Priorities/Rocks that align with those of the company",
    ],
    company: [
      "A situation room is established for weekly meetings (physical or virtual)",
      "Core Values, Purpose and Priorities are posted throughout the company",
      "Scoreboards are up everywhere displaying current progress on KPIs and Critical Numbers",
      "There is a system in place for tracking and managing the cascading Priorities and KPIs",
    ]
  };

  const uid = localStorage.getItem("user_id");
  const [formData, setFormData] = useState({
    physical: [],
    mental: [],
    spiritual: [],
    socialEmotional: [],
    employee: [],
    reporting: [],
    purpose: [],
    articulate: [],
    quantitatively: [],
    company: [],
  });

  const [progress, setProgress] = useState({
    physical: 0,
    mental: 0,
    spiritual: 0,
    socialEmotional: 0,
    employee: 0,
    reporting: 0,
    purpose: 0,
    articulate: 0,
    quantitatively: 0,
    company: 0,
  });

  const fetchData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.checklist_get}`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        const jsonData = response.data.data;

        const parsedChecklistData = Object.keys(checklists).reduce((acc, category) => {
          const data = jsonData[category] || '{}';
          try {
            const parsedData = JSON.parse(data);
            const categoryData = checklists[category].map((_, index) => parsedData[`${category}-${index}`] === '1');
            acc[category] = categoryData;
          } catch (e) {
            console.error(`Error parsing JSON for category ${category}:`, e);
            acc[category] = [];
          }
          return acc;
        }, {});

        const updatedFormData = { ...formData };
        Object.keys(checklists).forEach((category) => {
          updatedFormData[category] = parsedChecklistData[category] || [];
        });
        setFormData(updatedFormData);

        const newProgress = Object.keys(checklists).reduce((acc, category) => {
          const totalItems = checklists[category].length;
          const completedItems = parsedChecklistData[category].filter(Boolean).length;
          acc[category] = (completedItems / totalItems) * 100;
          return acc;
        }, {});
        setProgress(newProgress);
      } else {
        toast.error(response.data.message || 'Failed to fetch data');
      }
    } catch (error) {
      // toast.error('Error fetching data');
      console.error('Fetch data error:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCheckboxChange = (category, index) => {
    const newCategoryData = [...formData[category]];
    newCategoryData[index] = !newCategoryData[index];

    const totalItems = checklists[category].length;
    const completedItems = newCategoryData.filter(Boolean).length;
    const newProgress = (completedItems / totalItems) * 100;

    setFormData(prev => ({
      ...prev,
      [category]: newCategoryData,
    }));

    setProgress(prevProgress => ({
      ...prevProgress,
      [category]: newProgress,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Prepare data to send to the server
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    Object.keys(formData).forEach(cat => {
      formData[cat].forEach((isChecked, idx) => {
        formDataToSend.append(`${cat}-${idx}`, isChecked ? '1' : '0');
      });
    });

    // Make API call to update data
    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.checklist_insert}`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.status !== "success") {
        toast.error(response.data.message || 'Failed to save data');
      } else {
        toast.success(response.data.message || 'Data saved successfully!'); // Show success toast message
        fetchData(); // Refresh data after successful save
      }
    } catch (error) {
      toast.error('Error saving data');
      console.error('Save data error:', error);
    }
  };

  return (
    <div className="container-fluid">
      {/* <BackButton /> */}
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            {/* <div className="card-header">
              <div className="d-flex">
                <h5 className="mb-0">Execution: Founders OS Habits Checklist™</h5>
              </div>
            </div> */}
            <div className="card-header d-flex flex-wrap justify-content-between align-items-center ">
              <div className="d-flex align-items-center flex-grow-1">
                <h5 className="mb-0">Execution: Founders OS Habits Checklist™</h5>
              </div>
              <div >
                <BackButton />
              </div>
            </div>
            <form className="form theme-form" onSubmit={handleFormSubmit}>
              <div className="card-body">
                <div className="row">
                  {Object.keys(checklists).map((category, catIndex) => (
                    <div className="col-lg-12 my-3" key={catIndex}>
                      <div className="d-flex align-items-center">
                        <label className="form-label fw-bold me-2"><b>
                          {catIndex + 1}. {category.charAt(0).toUpperCase() + category.slice(1).replace(/([A-Z])/g, ' $1')}
                        </b></label>
                        <div style={{ width: 45, height: 45 }}>
                          <CircularProgressbar
                            value={progress[category]}
                            text={`${Math.round(progress[category])}%`}
                            styles={buildStyles({
                              textSize: '30px',
                              pathColor: progress[category] === 100
                                ? 'green'
                                : `rgb(${62 + (progress[category] * 1.9)}, ${152 + (progress[category] * 0.47)}, 199)`,
                              textColor: '#000',
                              trailColor: progress[category] === 100 ? 'green' : '#d6d6d6',
                              backgroundColor: progress[category] === 100 ? 'green' : '#f3f3f3',
                            })}
                          />
                        </div>

                      </div>
                      {checklists[category].map((item, index) => (
                        <div key={index} className="form-check">
                          <input
                            className="checkbox_animated checkbox-primary"
                            type="checkbox"
                            value={item}
                            id={`${category}-${index}`}
                            checked={formData[category]?.[index] || false}
                            onChange={() => handleCheckboxChange(category, index)}
                          />
                          <label className="form-check-label" htmlFor={`${category}-${index}`}>
                            {item}
                          </label>
                        </div>
                      ))}
                    </div>
                  ))}

                </div>

              </div>
              <div className="card-footer">
                <Button className="float-end mb-2" type="submit" variant="success">Save</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sales;
