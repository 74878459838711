import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import {
  FaBrain,
  FaBullhorn,
  FaChartLine,
  FaCompass,
  FaDumbbell,
  FaEye,
  FaFire,
  FaGlobe,
  FaHeart,
  FaIndustry,
  FaLaptop,
  FaMoneyBillWave,
  FaRegEye,
  FaRegLightbulb,
  FaRocket,
  FaTasks,
  FaUniversity,
  FaUser,
  FaUsers,
  FaRobot,
  FaList,
  FaCalendarAlt,
  FaClipboardList,
} from "react-icons/fa";
import { BiSolidEditAlt, BiConversation } from "react-icons/bi";
import { Box, Airplay, Cloud } from "react-feather";
import API from "../utils";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { LiaStarHalfAltSolid } from "react-icons/lia";
import { GoStar, GoStarFill } from "react-icons/go";
import { IoMdArrowForward } from "react-icons/io";

const iconMap = {
  FaTasks: <FaTasks className="icon-svg-menu" />,
  BiSolidEditAlt: <BiSolidEditAlt className="icon-svg-menu" />,
  FaGlobe: <FaGlobe className="icon-svg-menu" />,
  BiConversation: <BiConversation className="icon-svg-menu" />,
  FaBrain: <FaBrain className="icon-svg-menu" />,
  FaChartLine: <FaChartLine className="icon-svg-menu" />,
  FaBullhorn: <FaBullhorn className="icon-svg-menu" />,
  FaMoneyBillWave: <FaMoneyBillWave className="icon-svg-menu" />,
  FaUsers: <FaUsers className="icon-svg-menu" />,
  FaLaptop: <FaLaptop className="icon-svg-menu" />,
  FaIndustry: <FaIndustry className="icon-svg-menu" />,
  FaDumbbell: <FaDumbbell className="icon-svg-menu" />,
  FaHeart: <FaHeart className="icon-svg-menu" />,
  FaUniversity: <FaUniversity className="icon-svg-menu" />,
  FaCompass: <FaCompass className="icon-svg-menu" />,
  FaEye: <FaEye className="icon-svg-menu" />,
  FaRocket: <FaRocket className="icon-svg-menu" />,
  FaFire: <FaFire className="icon-svg-menu" />,
  FaRegLightbulb: <FaRegLightbulb className="icon-svg-menu" />,
  FaUser: <FaUser className="icon-svg-menu" />,
};

function Workspace() {
  const navigate = useNavigate();
  const [showSubMenu, setShowSubMenu] = useState(false);
  const uid = localStorage.getItem("user_id");
  const [profileImage, setProfileImg] = useState("");
  const [formData, setFormData] = useState({});
  const [favorites, setFavorites] = useState([]);

  const operations = [
    {
      title: "My Tasks",
      path: "/my-task/task",
      color: "#4d4d4d",
      icon: "FaTasks",
      module_name: "Tasks",
      svg: "tasks-svg",
    },
    {
      title: "Meetings",
      path: "/meeting/meetings",
      color: "#49a6df",
      icon: "BiSolidEditAlt",
      module_name: "Meetings",
      svg: "meetings-svg",
    },
    {
      title: "Projects",
      path: "/project/project-name",
      color: "#90be6d",
      icon: "FaGlobe",
      module_name: "Projects",
      svg: "projects-svg",
    },
    {
      title: "Reporting",
      path: "/people/pace",
      color: "#dc0000",
      icon: "BiConversation",
      module_name: "Reporting",
      svg: "reporting-svg",
    },
    {
      title: "Departmental Notes ",
      path: "#",
      color: "#f27123",
      icon: "FaBrain",
      hasSubMenu: true,
      module_name: "Departmental Notes",
      svg: "departmental-notes-svg",
    },
  ];

  const departmentLinks = [
    {
      title: "Sales",
      path: "/notes-sales",
      color: "#4d4d4d",
      icon: "FaChartLine",
      module_name: "Sales",
      svg: "sales-svg",
    },
    {
      title: "Marketing",
      path: "/notes-marketing",
      color: "#49a6df",
      icon: "FaBullhorn",
      module_name: "Marketing",
      svg: "marketing-svg",
    },
    {
      title: "Finance",
      path: "/notes-finanace",
      color: "#90be6d",
      icon: "FaMoneyBillWave",
      module_name: "Finance",
      svg: "finance-svg",
    },
    {
      title: "Human Resources",
      path: "/notes-hr",
      color: "#dc0000",
      icon: "FaUsers",
      module_name: "HR",
      svg: "hr-svg",
    },
    {
      title: "IT",
      path: "/notes-it",
      color: "#f94144",
      icon: "FaLaptop",
      module_name: "IT",
      svg: "it-svg",
    },
    {
      title: "Operations",
      path: "/notes-operation",
      color: "#4d4d4d",
      icon: "FaIndustry",
      module_name: "Operations",
      svg: "operations-svg",
    },
  ];

  const mylifeplan = [
    {
      path: "/Strategic-goal/ninty-days-sprints",
      title: "Strategic Goals",
      type: "link",
      color: "#4d4d4d",
      icon: "FaGlobe",
      module_name: "Strategic Goals",
      svg: "strategic-goals-svg",
    },
    {
      path: "/businessmodal/businessmodalcanvas",
      title: "Business Model",
      type: "link",
      color: "#49a6df",
      icon: "FaDumbbell",
      module_name: "Business Model",
      svg: "business-model-svg",
    },
    {
      path: "/corporate/foundationstory",
      title: "Corporate",
      type: "link",
      color: "#f27123",
      icon: "FaBrain",
      module_name: "Corporate",
      svg: "corporate-svg",
    },
    {
      path: "/businesstool/people",
      title: "Business Tools",
      type: "link",
      color: "#90be6d",
      icon: "FaHeart",
      module_name: "Business Tools",
      svg: "business-tools-svg",
    },
    {
      path: "/strategy-deveopment/seven-strata",
      title: "Business Strategy",
      type: "link",
      color: "#dc0000",
      icon: "FaUniversity",
      module_name: "Business Strategy",
      svg: "business-strategy-svg",
    },
    {
      path: "/growthframeworks/founders-OS-checklist",
      title: "Growth Frameworks",
      type: "link",
      color: "#f94144",
      icon: "FaUsers",
      module_name: "Growth Frameworks",
      svg: "growth-frameworks-svg",
    },
  ];

  const personalos = [
    {
      path: "/sales",
      title: "Process Workflows",
      type: "link",
      color: "#4d4d4d",
      icon: "FaCompass",
      module_name: "Process Workflows",
      svg: "process-workflows-svg",
    },
    {
      path: "/clients-name",
      title: "Clients",
      type: "link",
      icon: "FaEye",
      color: "#49a6df",
      module_name: "Clients",
      svg: "clients-svg",
    },
    {
      path: "/labor-efficiency-ratio",
      title: "Cash Flow",
      type: "link",
      icon: "FaRocket",
      color: "#90be6d",
      module_name: "Cash Flow",
      svg: "cash-flow-svg",
    },
    {
      path: "/execution/personal-selling",
      title: "Business Execution",
      type: "link",
      icon: "FaFire",
      color: "#dc0000",
      module_name: "Business Execution",
      svg: "business-execution-svg",
    },
    {
      path: "/people/face",
      title: "Business Roles & Responsibi lities",
      type: "link",
      icon: "FaRegLightbulb",
      color: "#f94144",
      module_name: "Business Roles",
      svg: "business-roles-svg",
    },
    {
      path: "/sops/sales",
      title: "Standard Operating Procedures (SOPs)",
      type: "link",
      icon: "FaUser",
      color: "#4d4d4d",
      module_name: "SOPs",
      svg: "sops-svg",
    },
  ];

  const myprofile = [
    {
      title: "My Notes",
      path: "/users/userEdit",
    },
  ];

  const handleNavigate = () => {
    navigate(myprofile[0].path);
  };

  const getCurrentDate = () => {
    return new Date().toLocaleDateString("en-US", {
      weekday: "long",
      month: "long",
      day: "numeric",
    });
  };

  const role = localStorage.getItem("role");

  const fetchData = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("user_id", uid);
    if (role) {
      formDataToSend.append("role", role);
    }

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_user_details}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        const userData = response.data.user_data;
        setProfileImg(userData.profile);
        setFormData({
          user_name: userData.user_name || "",
          title: userData.job_title || "",
          street: userData.street || "",
          city: userData.city || "",
          pinCode: userData.zip_code || "",
          email: userData.email || "",
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchFavorites = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("user_id", uid);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_favourites_icons_by_user}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        const favoritesData = response.data.data.map((item) => ({
          path: item.url,
          title: item.module_name,
          color: item.color,
          icon: iconMap[item.image],
          module_name: item.module_name, // Add module_name to favoritesData
        }));
        setFavorites(favoritesData);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addToFavorites = async (item) => {
    const formDataToSend = new FormData();
    formDataToSend.append("user_id", uid);
    formDataToSend.append("url", item.path);
    formDataToSend.append("module_name", item.module_name);
    formDataToSend.append("image", item.icon);
    formDataToSend.append("color", item.color);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.add_favorite}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        fetchFavorites();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const isFavorite = (item) => {
    return favorites.some(
      (fav) => fav.path === item.path && fav.module_name === item.module_name
    );
  };

  useEffect(() => {
    fetchData();
    fetchFavorites();
  }, []);

  const handleButtonClick = (path) => {
    navigate(path);
  };

  return (
    <>
      <div className="container-fluid p-0">
        <div className="workspace-header-banner d-flex">
          {/* Left Side: Profile Image & Greeting */}
          <div className="workspace-header-banner__content d-flex">
            <div
              className="workspace-header-banner__avatar"
              onClick={handleNavigate}
              style={{ cursor: "pointer" }}
            >
              <div className="base-avatar base-avatar--size-xxxl">
                <div className="base-avatar__initials">
                  {profileImage ? (
                    <img
                      className="img-100 rounded-circle"
                      src={profileImage}
                      alt="Profile"
                    />
                  ) : (
                    <div className="placeholder-avatar"></div>
                  )}
                </div>
              </div>
            </div>
            <div
              className="workspace-header-banner__greeting"
              onClick={handleNavigate}
              style={{ cursor: "pointer" }}
            >
              <h3 className="title title--s currentColor light">
                {getCurrentDate()}
              </h3>
              <h2 className="title title--xxxl currentColor light">
                Hello, <strong>{formData.user_name || "User"}</strong>
              </h2>
            </div>
          </div>
          <div className="workspace-header-banner__actions">
            <Link
              to="/users/userEdit"
              className="Back-button text-decoration-none"
            >
              <FaRegEye className="me-1" style={{ fontSize: "12px" }} />
              <span>View Profile</span>
            </Link>
          </div>
        </div>
      </div>
      <Container className="pt-md-2 pt-sm-2">
        <div className="header-personal mb-4">
          <div className="align-items-center card-header d-flex justify-content-between">
            <h5>
              <b>
                <LiaStarHalfAltSolid
                  style={{ color: "black", marginRight: "10px" }}
                />
                My Favorites
              </b>
            </h5>
          </div>

          <div className="card-body">
            <div>
              {favorites.length === 0 ? (
                <p className="text-center secondary regular mt-4">
                  Click on the star icon of any solution to add it to your
                  favorites
                </p>
              ) : (
                <div className="row w-auto mt-4">
                  {favorites.map((fav, index) => (
                    <div
                      key={index}
                      className="hover-effect col-lg-1 col-md-3 col-sm-4 col-6 p-2 d-flex flex-column align-items-center"
                    >
                      <div
                        className="solution-card p-3 text-center w-100"
                        style={{
                          backgroundColor: fav.color,
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(fav.path)}
                      >
                        <span className="solution-icon">{fav.icon}</span>
                        {/* <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-top">
                              {isFavorite(fav)
                                ? "Remove from Favorites"
                                : "Add to Favorites"}
                            </Tooltip>
                          } 
                        >*/}
                        {isFavorite(fav) ? (
                          <GoStarFill
                            className="favorite-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              addToFavorites(fav);
                            }}
                            style={{
                              position: "absolute",
                              top: "13px",
                              right: "13px",
                              cursor: "pointer",
                              fontSize: "22px",
                              color: "red",
                              backgroundColor: "white",
                              borderRadius: "50%",
                              padding: "2px",
                            }}
                          />
                        ) : (
                          <GoStar
                            className="favorite-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              addToFavorites(fav);
                            }}
                            style={{
                              position: "absolute",
                              top: "13px",
                              right: "13px",
                              cursor: "pointer",
                              fontSize: "22px",
                              color: "black",
                              backgroundColor: "white",
                              borderRadius: "50%",
                              padding: "2px",
                            }}
                          />
                        )}
                        {/* </OverlayTrigger> */}
                      </div>
                      <p
                        className="title-container mt-2 text-center"
                        onClick={() => navigate(fav.path)}
                      >
                        {fav.title}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="header-personal mb-4">
          <div className="align-items-center card-header d-flex justify-content-between">
            <h5>
              <b>
                <Airplay style={{ color: "black", marginRight: "10px" }} />
                What Would You Like to Work on Today ?
              </b>
            </h5>
          </div>
        </div>

        <div className="mt-2 mb-4 lager-screen-manu">
          <>
            <button
              className="chat-option-btn p-2 m-2 flex items-center"
              onClick={() => handleButtonClick("")}
            >
              <FaRobot className="mr-2" /> Work With My AI Business Mentor <IoMdArrowForward />
            </button>
            <button
              className="chat-option-btn p-2 m-2 flex items-center"
              onClick={() => handleButtonClick("/my-task/task")}
            >
              <FaList className="mr-2" /> Review My To-Do List <IoMdArrowForward />
            </button>
            <button
              className="chat-option-btn p-2 m-2 flex items-center"
              onClick={() => handleButtonClick("/sops/sales")}
            >
              <FaChartLine className="mr-2" /> Work on Sales <IoMdArrowForward />
            </button>
            <button
              className="chat-option-btn p-2 m-2 flex items-center"
              onClick={() => handleButtonClick("/sops/marketing")}
            >
              <FaBullhorn className="mr-2" /> Work on Marketing <IoMdArrowForward />
            </button>
            <button
              className="chat-option-btn p-2 m-2 flex items-center"
              onClick={() => handleButtonClick("/sales")}
            >
              <FaClipboardList className="mr-2" /> Work on My Founders OS Plan <IoMdArrowForward />
            </button>
            <button
              className="chat-option-btn p-2 m-2 flex items-center"
              onClick={() => handleButtonClick("/execution/personal-selling")}
            >
              <FaCalendarAlt className="mr-2" /> Review My Monthly KPIs <IoMdArrowForward />
            </button>
          </>
        </div>

        <div className="mt-2 mb-4 small-screen-manu overflow-auto" style={{ whiteSpace: "nowrap" }}>
          <button
            className="chat-option-btn p-2 mx-2 d-inline-flex align-items-center"
            onClick={() => handleButtonClick('')}
          >
            <FaRobot className="me-2" /> Work With My AI Business Mentor <IoMdArrowForward />
          </button>
          <button
            className="chat-option-btn p-2 mx-2 d-inline-flex align-items-center"
            onClick={() => handleButtonClick("/my-task/task")}
            >
            <FaList className="me-2" /> Review My To-Do List <IoMdArrowForward />
          </button>
          <button
            className="chat-option-btn p-2 mx-2 d-inline-flex align-items-center"
            onClick={() => handleButtonClick("/sops/sales")}
          >
            <FaChartLine className="me-2" /> Work on Sales <IoMdArrowForward />
          </button>
          <button
            className="chat-option-btn p-2 mx-2 d-inline-flex align-items-center"
            onClick={() => handleButtonClick('/sops/marketing')}
          >
            <FaBullhorn className="me-2" /> Work on Marketing <IoMdArrowForward />
          </button>
          <button
            className="chat-option-btn p-2 mx-2 d-inline-flex align-items-center"
            onClick={() => handleButtonClick('/sales')}
          >
            <FaClipboardList className="me-2" /> Work on My Founders OS Plan <IoMdArrowForward />
          </button>
          <button
            className="chat-option-btn p-2 mx-2 d-inline-flex align-items-center"
            onClick={() => handleButtonClick('/execution/personal-selling')}
          >
            <FaCalendarAlt className="me-2" /> Review My Monthly KPIs <IoMdArrowForward />
          </button>
        </div>

        <div className="header-personal mb-4">
          <div className="align-items-center card-header d-flex justify-content-between">
            <h5>
              <b>
                <FaCompass style={{ color: "black", marginRight: "10px" }} />
                Workplace
              </b>
            </h5>
          </div>

          <div className="header-personal mt-4 mb-4">
            <div className="align-items-center card-header d-flex justify-content-between">
              <h6>
                <Box style={{ color: "black", margin: "20px" }} />
                Operations
              </h6>
            </div>

            <div className="card-body">
              <div className="row w-auto mt-4">
                {operations.map((sol, index) => (
                  <div
                    key={index}
                    className="hover-effect col-lg-1 col-md-3 col-sm-4 col-6 p-2 d-flex flex-column align-items-center"
                  >
                    <div
                      className="solution-card p-3 text-center w-100"
                      style={{ backgroundColor: sol.color, cursor: "pointer" }}
                      onClick={() =>
                        sol.hasSubMenu
                          ? setShowSubMenu(!showSubMenu)
                          : navigate(sol.path)
                      }
                    >
                      <span className="solution-icon">{iconMap[sol.icon]}</span>
                      {/* <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">
                            {isFavorite(sol)
                              ? "Remove from Favorites"
                              : "Add to Favorites"}
                          </Tooltip>
                        }
                      > */}
                      {isFavorite(sol) ? (
                        <GoStarFill
                          className="favorite-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            addToFavorites(sol);
                          }}
                          style={{
                            position: "absolute",
                            top: "13px",
                            right: "13px",
                            cursor: "pointer",
                            fontSize: "22px",
                            color: "red",
                            backgroundColor: "white",
                            borderRadius: "50%",
                            padding: "2px",
                          }}
                        />
                      ) : (
                        <GoStar
                          className="favorite-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            addToFavorites(sol);
                          }}
                          style={{
                            position: "absolute",
                            top: "13px",
                            right: "13px",
                            cursor: "pointer",
                            fontSize: "22px",
                            color: "black",
                            backgroundColor: "white",
                            borderRadius: "50%",
                            padding: "2px",
                          }}
                        />
                      )}
                      {/* </OverlayTrigger> */}
                    </div>
                    <p
                      className="title-container mt-2 text-center"
                      onClick={() =>
                        sol.hasSubMenu
                          ? setShowSubMenu(!showSubMenu)
                          : navigate(sol.path)
                      }
                    >
                      {sol.title}
                    </p>
                  </div>
                ))}
              </div>

              {showSubMenu && (
                <div className="submenu-container m-auto mt-4">
                  <div className="row w-auto mt-4">
                    <p className="title-container1 mt-2 mb-3">
                      Departmental Notes and Best Practices
                    </p>
                    {departmentLinks.map((dept, index) => (
                      <div
                        key={index}
                        className="hover-effect col-lg-2 col-md-3 col-sm-4 col-6 p-2 d-flex flex-column align-items-center"
                      >
                        <div
                          className="sub-solution-card p-3 text-center w-100"
                          style={{
                            backgroundColor: dept.color,
                            cursor: "pointer",
                          }}
                          onClick={() => navigate(dept.path)}
                        >
                          <span className="solution-icon">
                            {iconMap[dept.icon]}
                          </span>
                          {/* <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-top">
                                {isFavorite(dept)
                                  ? "Remove from Favorites"
                                  : "Add to Favorites"}
                              </Tooltip>
                            }
                          > */}
                          {isFavorite(dept) ? (
                            <GoStarFill
                              className="favorite-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                addToFavorites(dept);
                              }}
                              style={{
                                position: "absolute",
                                top: "13px",
                                right: "13px",
                                cursor: "pointer",
                                fontSize: "22px",
                                color: "red",
                                backgroundColor: "white",
                                borderRadius: "50%",
                                padding: "2px",
                              }}
                            />
                          ) : (
                            <GoStar
                              className="favorite-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                addToFavorites(dept);
                              }}
                              style={{
                                position: "absolute",
                                top: "13px",
                                right: "13px",
                                cursor: "pointer",
                                fontSize: "22px",
                                color: "black",
                                backgroundColor: "white",
                                borderRadius: "50%",
                                padding: "2px",
                              }}
                            />
                          )}
                          {/* </OverlayTrigger> */}
                        </div>
                        <p className="title-container mt-2 text-center">
                          {dept.title}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="header-personal mb-4">
            <div className="align-items-center card-header d-flex justify-content-between">
              <h6>
                <Airplay style={{ color: "black", margin: "20px" }} />
                Strategy
              </h6>
            </div>

            <div className="card-body">
              <div className="row w-auto mt-4">
                {mylifeplan.map((sol, index) => (
                  <div
                    key={index}
                    className="hover-effect col-lg-1 col-md-3 col-sm-4 col-6 p-2 d-flex flex-column align-items-center"
                  >
                    <div
                      className="solution-card p-3 text-center w-100"
                      style={{ backgroundColor: sol.color, cursor: "pointer" }}
                      onClick={() => navigate(sol.path)}
                    >
                      <span className="solution-icon">{iconMap[sol.icon]}</span>
                      {/* <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">
                            {isFavorite(sol)
                              ? "Remove from Favorites"
                              : "Add to Favorites"}
                          </Tooltip>
                        } 
                      >*/}
                      {isFavorite(sol) ? (
                        <GoStarFill
                          className="favorite-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            addToFavorites(sol);
                          }}
                          style={{
                            position: "absolute",
                            top: "13px",
                            right: "13px",
                            cursor: "pointer",
                            fontSize: "22px",
                            color: "red",
                            backgroundColor: "white",
                            borderRadius: "50%",
                            padding: "2px",
                          }}
                        />
                      ) : (
                        <GoStar
                          className="favorite-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            addToFavorites(sol);
                          }}
                          style={{
                            position: "absolute",
                            top: "13px",
                            right: "13px",
                            cursor: "pointer",
                            fontSize: "22px",
                            color: "black",
                            backgroundColor: "white",
                            borderRadius: "50%",
                            padding: "2px",
                          }}
                        />
                      )}
                      {/* </OverlayTrigger> */}
                    </div>
                    <p
                      className="title-container mt-2 text-center"
                      onClick={() => navigate(sol.path)}
                    >
                      {sol.title}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="header-personal mb-4">
            <div className="align-items-center card-header d-flex justify-content-between">
              <h6>
                <Cloud style={{ color: "black", margin: "20px" }} />
                Management
              </h6>
            </div>
            <div className="card-body">
              <div className="row  w-auto mt-4">
                {personalos.map((sol, index) => (
                  <div
                    key={index}
                    className="hover-effect col-lg-1 col-md-3 col-sm-4 col-6 p-2 d-flex flex-column align-items-center"
                  >
                    <div
                      className="solution-card p-3 text-center w-100"
                      style={{ backgroundColor: sol.color, cursor: "pointer" }}
                      onClick={() => navigate(sol.path)}
                    >
                      <span className="solution-icon">{iconMap[sol.icon]}</span>
                      {/* <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">
                            {isFavorite(sol)
                              ? "Remove from Favorites"
                              : "Add to Favorites"}
                          </Tooltip>
                        }
                      > */}
                      {isFavorite(sol) ? (
                        <GoStarFill
                          className="favorite-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            addToFavorites(sol);
                          }}
                          style={{
                            position: "absolute",
                            top: "13px",
                            right: "13px",
                            cursor: "pointer",
                            fontSize: "22px",
                            color: "red",
                            backgroundColor: "white",
                            borderRadius: "50%",
                            padding: "2px",
                          }}
                        />
                      ) : (
                        <GoStar
                          className="favorite-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            addToFavorites(sol);
                          }}
                          style={{
                            position: "absolute",
                            top: "13px",
                            right: "13px",
                            cursor: "pointer",
                            fontSize: "22px",
                            color: "black",
                            backgroundColor: "white",
                            borderRadius: "50%",
                            padding: "2px",
                          }}
                        />
                      )}
                      {/* </OverlayTrigger> */}
                    </div>
                    <p
                      className="title-container mt-2 text-center"
                      onClick={() => navigate(sol.path)}
                    >
                      {sol.title}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Workspace;
