import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Card, CardBody, CardHeader, Container, Modal } from 'react-bootstrap';
import { FaRegEdit } from 'react-icons/fa';
import API from '../../../utils';
import { toast } from 'react-toastify';
import AddAvatarModal from './addAvatarModal';
import EditAvatarModal from './editAvatarModal';
import Edit4events from './newedit';
import EditPainPoints from './editPainPoints';
import EditWantsNeeds from './wantsneeds';
import EditOpportunities from './editopportunities';
import EditResources from './editresources';
import { MdDelete } from 'react-icons/md';
import Noimagepng from '../../../assets/images/Image_not_available.png';
import BackButton from '../../common/header-component/backButton';

const CustomerAvtar = () => {
    const [avatars, setAvatars] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showEditmotivetionModal, setShowmotivetionEditModal] = useState(false);
    const [showEditPainPointsModal, setShowPainPointEditModal] = useState(false);
    const [showEditWantsNeedsModal, setShowWantsNeedsEditModal] = useState(false);
    const [showEditOpportunitiesModal, setShowOpportunitiesEditModal] = useState(false);
    const [showEditResourcesModal, setShowEditResourcesEditModal] = useState(false);
    const [currentAvatarId, setCurrentAvatarId] = useState(null);
    const [formData, setFormData] = useState({
        image: null,
        name: '',
        age: '',
        gender: '',
        location: '',
        profession: '',
        hobbies: '',
        education: '',
        income: '',
        family: '',
        Influencers: '',
        brand_they_love: '',
        skills: '',
        sources: '',
        social_media_use: '',
        Motivations: [],
        Pain_Points: [],
        Wants_Needs: [],
        Opportunities: [],
        Resources: [],
    });
    const uid = localStorage.getItem('user_id') || '';

    useEffect(() => {
        fetchAvatars();
    }, []);

    const fetchAvatars = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_customer_avatar}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                setAvatars(formatAvatars(response.data.data));

            } else {
                console.error("Error fetching avatars:", response.data.message);
                toast.error(`Failed to fetch avatars: ${response.data.message}`);
            }
        } catch (error) {
            console.error("Error fetching avatars:", error);
        }
    };

    const formatAvatars = (data) => {
        return data.map(avatar => {
            let demographics = {};
            try {
                demographics = JSON.parse(avatar.Demographics || '{}');
            } catch (error) {
                console.error("Error parsing Demographics:", error);
            }

            const parseArray = (jsonString) => {
                try {
                    const cleanedString = jsonString.replace(/^"|"$/g, ''); // Remove extra quotes
                    const parsed = JSON.parse(cleanedString);
                    return Array.isArray(parsed) ? parsed : [];
                } catch (error) {
                    console.error("Error parsing JSON string:", error);
                    return [];
                }
            };

            return {
                id: parseInt(avatar.id, 10),
                image: avatar.image,
                name: avatar.name || '',
                age: demographics.age || '',
                gender: demographics.gender || '',
                location: demographics.location || '',
                profession: demographics.profession || '',
                // hobbies: parseArray(demographics.hobbies),
                hobbies: (demographics.hobbies ? demographics.hobbies.split(',').map(item => item.trim()) : []),
                education: demographics.education || '',
                income: demographics.income || '',
                family: demographics.family || '',
                Influencers: (demographics.influencers ? demographics.influencers.split(',').map(item => item.trim()) : []),
                brand_they_love: (demographics.brand_they_love ? demographics.brand_they_love.split(',').map(item => item.trim()) : []),
                skills: demographics.skills || '',
                sources: (demographics.sources ? demographics.sources.split(',').map(item => item.trim()) : []),
                social_media_use: demographics.social_media_use || '',
                Motivations: parseArray(avatar.Motivations),
                Pain_Points: parseArray(avatar.Pain_Points),
                Wants_Needs: parseArray(avatar.Wants_Needs),
                Opportunities: parseArray(avatar.Opportunities),
                Resources: parseArray(avatar.Resources),
            };
        });
    };


    const handleShowAddModal = () => setShowAddModal(true);
    const handleCloseAddModal = () => {
        setShowAddModal(false);
        resetFormData();
    };


    const handleCloseEditModal = () => {
        setShowEditModal(false);
        setShowmotivetionEditModal(false);
        setShowPainPointEditModal(false);
        setShowWantsNeedsEditModal(false);
        setShowOpportunitiesEditModal(false);
        setShowEditResourcesEditModal(false);
        resetFormData();
    };


    const handleShowEditModal = (id) => {
        const avatar = avatars.find(av => av.id === id);

        setFormData({
            ...avatar,
            hobbies: avatar.hobbies ? avatar.hobbies.join(', ') : '',
            Influencers: avatar.Influencers ? avatar.Influencers.join(', ') : '',
            brand_they_love: avatar.brand_they_love ? avatar.brand_they_love.join(', ') : '',
            skills: avatar.skills || '',
            sources: avatar.sources ? avatar.sources.join(', ') : '',
        });

        setCurrentAvatarId(id);
        setShowEditModal(true);
    };


    const handleShowmotivetionEditModal = (id) => {
        const avatar = avatars.find(av => av.id === id);

        setFormData({
            ...avatar,
            Motivations: Array.isArray(avatar.Motivations) ? avatar.Motivations : [], // Ensure it's an array
        });

        setCurrentAvatarId(id);
        setShowmotivetionEditModal(true);
    };

    const handleShowPainPointsEditModal = (id) => {
        const avatar = avatars.find(av => av.id === id);

        setFormData({
            ...avatar,
            Pain_Points: Array.isArray(avatar.Pain_Points) ? avatar.Pain_Points : [], // Ensure it's an array
        });

        setCurrentAvatarId(id);
        setShowPainPointEditModal(true);
    };

    const handleShowWantsNeedsEditModal = (id) => {
        const avatar = avatars.find(av => av.id === id);

        setFormData({
            ...avatar,
            Wants_Needs: Array.isArray(avatar.Wants_Needs) ? avatar.Wants_Needs : [], // Ensure it's an array
        });

        setCurrentAvatarId(id);
        setShowWantsNeedsEditModal(true);
    };

    const handleShowOpportunitiesEditModal = (id) => {
        const avatar = avatars.find(av => av.id === id);

        setFormData({
            ...avatar,
            Opportunities: Array.isArray(avatar.Opportunities) ? avatar.Opportunities : [], // Ensure it's an array
        });

        setCurrentAvatarId(id);
        setShowOpportunitiesEditModal(true);
    };

    const handleShowEditResourcesEditModal = (id) => {
        const avatar = avatars.find(av => av.id === id);

        setFormData({
            ...avatar,
            Resources: Array.isArray(avatar.Resources) ? avatar.Resources : [], // Ensure it's an array
        });

        setCurrentAvatarId(id);
        setShowEditResourcesEditModal(true);
    };

    const resetFormData = () => {
        setFormData({
            image: null,
            name: '',
            age: '',
            gender: '',
            location: '',
            profession: '',
            hobbies: '',
            education: '',
            income: '',
            family: '',
            Influencers: '',
            brand_they_love: '',
            skills: '',
            sources: '',
            social_media_use: '',
            Motivations: [],
            Pain_Points: [],
            Wants_Needs: [],
            Opportunities: [],
            Resources: [],
        });
    };

    const handleSaveNewAvatar = async () => {
        const formDataToSend = new FormData();

        // Iterate over the formData keys and append them to formDataToSend
        Object.keys(formData).forEach(key => {
            if (Array.isArray(formData[key])) {
                // Stringify the array fields
                formDataToSend.append(key, JSON.stringify(formData[key]));
            } else {
                formDataToSend.append(key, formData[key]);
            }
        });

        // Append user_id separately
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_customer_avatar}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                handleCloseAddModal();
                fetchAvatars();
                setAvatars([...avatars, { ...formData, id: avatars.length + 1, ...parseArrays(formData) }]);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Failed to add key Relationship.", error);
            toast.error("An error occurred while saving the avatar.");
        }
    };


    const handleSaveAvatar = async () => {
        const updatedFormData = createFormData({
            ...formData,
            Motivations: Array.isArray(formData.Motivations) ? formData.Motivations : [],
            Pain_Points: Array.isArray(formData.Pain_Points) ? formData.Pain_Points : [],
            Wants_Needs: Array.isArray(formData.Wants_Needs) ? formData.Wants_Needs : [],
            Opportunities: Array.isArray(formData.Opportunities) ? formData.Opportunities : [],
            Resources: Array.isArray(formData.Resources) ? formData.Resources : [],
        });


        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.update_customer_avatar}`,
                updatedFormData,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                const updatedAvatars = avatars.map(av => av.id === currentAvatarId ? { ...formData, ...parseArrays(formData) } : av);
                setAvatars(updatedAvatars);
                fetchAvatars();
                toast.success("Avatar Added successfully.");
                handleCloseEditModal();
            } else {
                toast.error(`Failed to update avatar: ${response.data.message}`);
            }
        } catch (error) {
            console.error("Failed to update avatar:", error);
        }
    };

    const createFormData = (data) => {
        const formData = new FormData();
        Object.keys(data).forEach(key => {
            if (Array.isArray(data[key])) {
                formData.append(key, JSON.stringify(data[key]));
            } else {
                formData.append(key, data[key]);
            }
        });
        return formData;
    };

    const parseArrays = (data) => {
        const fields = ['Motivations', 'Pain_Points', 'Wants_Needs', 'Opportunities', 'Resources'];
        const parsedData = {};
        fields.forEach(field => {
            try {
                parsedData[field] = JSON.parse(data[field] || '[]');
            } catch (error) {
                parsedData[field] = [];
            }
        });
        return {
            ...data,
            ...parsedData
        };
    };


    // const parseArrays = (data) => {
    //     const fields = [
    //         'Motivations',
    //         'Pain_Points',
    //         'Wants_Needs',
    //         'Opportunities',
    //         'Resources'
    //     ];

    //     const parsedData = {};

    //     // Loop through each field and parse the JSON string into an array
    //     fields.forEach(field => {
    //         try {
    //             parsedData[field] = JSON.parse(data[field]);
    //         } catch (error) {
    //             console.error(`Error parsing ${field}:`, error);
    //             parsedData[field] = [];
    //         }
    //     });

    //     return {
    //         hobbies: data.hobbies.split(',').map(item => item.trim()),
    //         Influencers: data.Influencers.split(',').map(item => item.trim()),
    //         brand_they_love: data.brand_they_love.split(',').map(item => item.trim()),
    //         skills: data.skills.split(',').map(item => item.trim()),
    //         sources: data.sources.split(',').map(item => item.trim()),
    //         ...parsedData // Add the parsed arrays to the returned object
    //     };
    // };

    const handleFormDataChange = (event) => {
        const { name, value, files } = event.target;
        if (files) {
            setFormData(prevData => ({ ...prevData, image: files[0] }));
        } else if (name.includes('[')) {
            const [fieldName, idx] = name.split('[');
            const index = parseInt(idx.replace(']', ''), 10);
            setFormData(prevData => {
                const newArr = [...prevData[fieldName]];
                newArr[index] = value;
                return { ...prevData, [fieldName]: newArr };
            });
        } else {
            setFormData(prevData => ({ ...prevData, [name]: value }));
        }
    };

    const handleAddField = (field) => {
        setFormData(prevData => ({
            ...prevData,
            [field]: [...prevData[field], '']
        }));
    };


    const handleRemoveField = (field, index) => {
        setFormData(prevData => ({
            ...prevData,
            [field]: prevData[field].filter((_, i) => i !== index)
        }));
    };

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [avatarToDeleteId, setAvatarToDeleteId] = useState(null);

    const handleShowmotivetiondeleteModal = (id) => {
        setAvatarToDeleteId(id);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setAvatarToDeleteId(null);
    };

    const handleConfirmDelete = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('id', avatarToDeleteId);

            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.delete_customer_avatar}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success("Avatar deleted successfully.");
                handleCloseDeleteModal();
                fetchAvatars();
            } else {
                toast.error(`Failed to delete avatar: ${response.data.message}`);
            }
        } catch (error) {
            console.error("Failed to delete avatar:", error);
            toast.error("An error occurred while deleting the avatar.");
        }
    };

    return (
        <>
            <Container fluid={true}>
                {/* <BackButton /> */}

                <Card>
                    {/* <CardHeader>
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="">
                                <h5>Customer Avatar</h5>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <Button variant="success" onClick={handleShowAddModal}>
                                    {avatars.length === 1 ? 'Add More Avatar' : 'Add Avatar'}
                                </Button>
                            </div>


                        </div>
                    </CardHeader> */}
                    <div className="card-header d-flex flex-wrap justify-content-between align-items-center ">
                        <div className="d-flex align-items-center flex-grow-1">
                            <h5>Customer Avatar</h5>
                        </div>
                        <div className="align-items-center justify-content-between d-flex">
                            <Button variant="success" className="btn-success main-buttons me-lg-2" onClick={handleShowAddModal}>
                                {avatars.length === 1 ? 'Add More Avatar' : 'Add Avatar'}
                            </Button>
                            <BackButton />
                        </div>
                    </div>

                    <CardBody>
                        {avatars.length === 0 ? (
                            <h6 className='d-flex justify-content-center'>No data available.</h6>
                        ) : (
                            avatars.map(avatar => (
                                <div className='my-3' key={avatar.id}>
                                    <div className="section">
                                        <div className='d-flex justify-content-end my-2'>
                                            <button
                                                className="align-items-center d-flex btn-danger"
                                                onClick={() => handleShowmotivetiondeleteModal(avatar.id)}
                                            >
                                                <MdDelete className="me-1" />
                                                Remove
                                            </button>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="left-panel col-lg-4 col-md-5">
                                            <div className="profile-container">
                                                <img
                                                    className="profile-image mt-4 mx-3 mx-lg-0"
                                                    src={avatar.image || Noimagepng}
                                                    alt=""
                                                />
                                                <div className="profile-content mt-3">
                                                    <div className=''>
                                                        <div className='text-end'>
                                                            <button className="costemeravtar" onClick={() => handleShowEditModal(avatar.id)}>
                                                                <FaRegEdit className="costemeravtar-icon" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <p><strong>Name:</strong> {avatar.name}</p>
                                                    <p><strong>Age:</strong> {avatar.age}</p>
                                                    <p><strong>Location:</strong> {avatar.location}</p>
                                                    <p><strong>Profession:</strong> {avatar.profession}</p>
                                                    <p><strong>Hobbies:</strong> {(Array.isArray(avatar.hobbies) ? avatar.hobbies.join(', ') : '')}</p>
                                                    <p><strong>Education:</strong> {avatar.education}</p>
                                                    <p><strong>Income:</strong> {avatar.income}</p>
                                                    <p><strong>Family:</strong> {avatar.family}</p>
                                                    <p><strong>Influencers:</strong> {(Array.isArray(avatar.Influencers) ? avatar.Influencers.join(', ') : '')}</p>
                                                    <p><strong>Brands they Love:</strong> {(Array.isArray(avatar.brand_they_love) ? avatar.brand_they_love.join(', ') : '')}</p>
                                                    <p><strong>Skills:</strong> {avatar.skills}</p>
                                                    <p><strong>Sources:</strong> {(Array.isArray(avatar.sources) ? avatar.sources.join(', ') : '')}</p>
                                                    <p><strong>Social Media Use:</strong> {avatar.social_media_use}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="right-panel col-lg-8 col-md-7">
                                            <div className="sections">

                                                <div className="section">
                                                    <div className='d-flex my-2'>
                                                        <h3>Motivations</h3>
                                                        <div className='text-end'>
                                                            <button className="costemeravtar" onClick={() => handleShowmotivetionEditModal(avatar.id)}>
                                                                <FaRegEdit className="costemeravtar-icon" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <ul>
                                                        <div className='row'>
                                                            {(Array.isArray(avatar.Motivations) ? avatar.Motivations : []).map((item, index) => (
                                                                <div className='col-md-6' key={index}>
                                                                    <li>{item}</li>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </ul>
                                                </div>
                                                <div className="section">
                                                    <div className='d-flex my-2'>
                                                        <h3>Pain Points</h3>
                                                        <div className='text-end'>
                                                            <button className="costemeravtar" onClick={() => handleShowPainPointsEditModal(avatar.id)}>
                                                                <FaRegEdit className="costemeravtar-icon" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <ul>
                                                        <div className='row'>
                                                            {(Array.isArray(avatar.Pain_Points) ? avatar.Pain_Points : []).map((item, index) => (
                                                                <div className='col-md-6' key={index}>
                                                                    <li>{item}</li>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </ul>
                                                </div>
                                                <div className="section">
                                                    <div className='d-flex my-2'>
                                                        <h3>Wants & Needs</h3>
                                                        <div className='text-end'>
                                                            <button className="costemeravtar" onClick={() => handleShowWantsNeedsEditModal(avatar.id)}>
                                                                <FaRegEdit className="costemeravtar-icon" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <ul>
                                                        <div className='row'>
                                                            {(Array.isArray(avatar.Wants_Needs) ? avatar.Wants_Needs : []).map((item, index) => (
                                                                <div className='col-md-6' key={index}>
                                                                    <li>{item}</li>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </ul>
                                                </div>
                                                <div className="section">
                                                    <div className='d-flex my-2'>
                                                        <h3>Opportunities</h3>
                                                        <div className='text-end'>
                                                            <button className="costemeravtar" onClick={() => handleShowOpportunitiesEditModal(avatar.id)}>
                                                                <FaRegEdit className="costemeravtar-icon" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <ul>
                                                        <div className='row'>
                                                            {(Array.isArray(avatar.Opportunities) ? avatar.Opportunities : []).map((item, index) => (
                                                                <div className='col-md-6' key={index}>
                                                                    <li>{item}</li>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </ul>
                                                </div>
                                                <div className="section">
                                                    <div className='d-flex my-2'>
                                                        <h3>Resources</h3>
                                                        <div className='text-end'>
                                                            <button className="costemeravtar" onClick={() => handleShowEditResourcesEditModal(avatar.id)}>
                                                                <FaRegEdit className="costemeravtar-icon" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <ul>
                                                        <div className='row'>
                                                            {(Array.isArray(avatar.Resources) ? avatar.Resources : []).map((item, index) => (
                                                                <div className='col-md-6' key={index}>
                                                                    <li>{item}</li>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}


                    </CardBody>

                    <AddAvatarModal
                        show={showAddModal}
                        onHide={handleCloseAddModal}
                        formData={formData}
                        onChange={handleFormDataChange}
                        onSave={handleSaveNewAvatar}
                        onAddField={handleAddField}
                        onRemoveField={handleRemoveField}
                    />

                    <EditAvatarModal
                        show={showEditModal}
                        onHide={handleCloseEditModal}
                        formData={formData}
                        onChange={handleFormDataChange}
                        onSave={handleSaveAvatar}
                        onAddField={handleAddField}
                        onRemoveField={handleRemoveField}
                    />

                    <Edit4events
                        show={showEditmotivetionModal}
                        onHide={handleCloseEditModal}
                        formData={formData}
                        onChange={handleFormDataChange}
                        onSave={handleSaveAvatar}
                        onAddField={handleAddField}
                        onRemoveField={handleRemoveField}
                    />

                    <EditPainPoints
                        show={showEditPainPointsModal}
                        onHide={handleCloseEditModal}
                        formData={formData}
                        onChange={handleFormDataChange}
                        onSave={handleSaveAvatar}
                        onAddField={handleAddField}
                        onRemoveField={handleRemoveField}
                    />

                    <EditWantsNeeds
                        show={showEditWantsNeedsModal}
                        onHide={handleCloseEditModal}
                        formData={formData}
                        onChange={handleFormDataChange}
                        onSave={handleSaveAvatar}
                        onAddField={handleAddField}
                        onRemoveField={handleRemoveField}
                    />

                    <EditOpportunities
                        show={showEditOpportunitiesModal}
                        onHide={handleCloseEditModal}
                        formData={formData}
                        onChange={handleFormDataChange}
                        onSave={handleSaveAvatar}
                        onAddField={handleAddField}
                        onRemoveField={handleRemoveField}
                    />

                    <EditResources
                        show={showEditResourcesModal}
                        onHide={handleCloseEditModal}
                        formData={formData}
                        onChange={handleFormDataChange}
                        onSave={handleSaveAvatar}
                        onAddField={handleAddField}
                        onRemoveField={handleRemoveField}
                    />
                </Card>
            </Container>


            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this Customer Avatar ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CustomerAvtar;