import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row, CardHeader } from "react-bootstrap";
import axios from 'axios';
import { toast } from "react-toastify";
import API from "../../../utils";
import BackButton from "../../common/header-component/backButton";

const OrganizationalCheckup = () => {
  const [questions, setQuestions] = useState([]);
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(false);
  const uid = localStorage.getItem("user_id");

  const fetchData = async () => {
    setLoading(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.organization_data_get_all}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (response.data.status === "success") {
        const data = response.data.data;

        const fetchedQuestions = data.map(item => item.name);
        const fetchedResponses = data.map(item => ({
          id: item.id,
          answer: item.answer || ''
        }));
        setQuestions(fetchedQuestions);
        setResponses(fetchedResponses);
      } else {
        //toast.error(response.data.message || 'Failed to fetch data');
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error('Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleResponseChange = async (questionIndex, valueIndex) => {
    const newResponses = [...responses];
    newResponses[questionIndex] = { ...newResponses[questionIndex], answer: valueIndex };
    setResponses(newResponses);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);
      formDataToSend.append('organizational_id', newResponses[questionIndex].id);
      formDataToSend.append('checked', valueIndex || '');

      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.organizational_insert}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (response.data.status === "success") {
        toast.success('Response updated successfully!');
        fetchData();
      } else {
        // toast.error(response.data.message || 'Failed to fetch data');
      }
    } catch (error) {
      console.error("Error updating response:", error);
      toast.error('Failed to update response');
    }
  };


  const calculateScore = () => {
    const totalScore = responses.reduce((sum, response) => {
      return sum + parseInt(response.answer || 0);
    }, 0);

    const maxScore = questions.length * 5;

    return (totalScore / maxScore) * 100;
  };

  return (
    <Container fluid={true}>
      {/* <BackButton /> */}

      <Row>
        <Col sm={12}>
          <Card>
            {/* <CardHeader>
              <Row>
                <Col md={6}>
                  <h5>Organizational Checkup</h5>
                </Col>
              </Row>
            </CardHeader> */}
            <div className="card-header d-flex flex-wrap justify-content-between align-items-center ">
              <div className="d-flex align-items-center flex-grow-1">
                <h5>Organizational Checkup</h5>
              </div>
              <div >
                <BackButton />
              </div>
            </div>
            <form className="form theme-form">
              <div className="card-body">
                <Row>
                  <Col sm={12}>
                    <div className="life-wheel-table">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th></th>
                            {[1, 2, 3, 4, 5].map(num => (
                              <th key={num}>{num}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {questions.map((question, questionIndex) => (
                            <tr key={questionIndex}>
                              <td>{question}</td>
                              {[1, 2, 3, 4, 5].map(num => (
                                <td key={num}>
                                  <input
                                    type="radio"
                                    className="radio_animated"
                                    name={`question-${questionIndex}`}
                                    value={num}
                                    checked={responses[questionIndex]?.answer === num.toString()}
                                    onChange={() => handleResponseChange(questionIndex, num.toString())}
                                  />
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="result text-end mt-4">
                        <h2>Total Score: {calculateScore().toFixed(2)}%</h2>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </form>
          </Card>
        </Col>
      </Row>

    </Container>
  );
};

export default OrganizationalCheckup;
