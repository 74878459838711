import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Dropdown, Button } from 'react-bootstrap';
import { FaSortDown, FaTasks, FaCalendarWeek, FaCalendar, FaExclamationCircle, FaHourglassHalf } from 'react-icons/fa';
import { CgToday } from "react-icons/cg";
import { PiHourglassHighFill } from "react-icons/pi";
import { SiVlcmediaplayer } from "react-icons/si";
import { toast } from 'react-toastify';
import AddModal from '../modals/addmodal';
import axios from 'axios';
import API, { isSubscribedAccountant } from '../../../utils';
import CreatedByme from '../modals/taskCreatebyme';
import { useNavigate } from 'react-router-dom';
import BackButton from '../../common/header-component/backButton';

const Task = () => {
  const uid = localStorage.getItem('user_id');
  const [tasks, setTasks] = useState([]);
  const [activeTab, setActiveTab] = useState('My Tasks');
  const [hideCompletedTasks, setHideCompletedTasks] = useState(false);

  const componentRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    fetchTasks();
  }, [activeTab]);

  useEffect(() => {
    if (!isSubscribedAccountant()) {
      navigate("/users/billings");
      window.location.reload();
    }
  }, []);

  const fetchTasks = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('filter', activeTab);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_all_task}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );


      if (response.data.status === "success") {
        const tasksData = response.data.data;

        const tasksArray = Object.values(tasksData);

        const sortedTasks = tasksArray.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
        setTasks(sortedTasks);

        const hideIsCheckValue = tasksArray.length > 0 ? tasksArray[0].hide_is_check : "0";
        setHideCompletedTasks(hideIsCheckValue === "1");

      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Error during task fetching:', error);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleCheckboxChange = () => {
    const newHideCompletedTasks = !hideCompletedTasks;
    setHideCompletedTasks(newHideCompletedTasks);
    CheckboxChangeSubmit(newHideCompletedTasks ? 1 : 0);
  };

  const CheckboxChangeSubmit = async (hide_is_check) => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('hide_is_check', hide_is_check);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.complete_hide_task}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        fetchTasks();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during task submission:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const tabHeadings = {
    'My Tasks': 'All Tasks',
    "Today's Tasks": 'Today Tasks',
    'Delayed Tasks': 'Delayed Tasks',
    'Upcoming Tasks': 'Upcoming Tasks',
    "This Week's Tasks": "This Week's Tasks",
    "This Month's Tasks": "This Month's Tasks",
    'Completed': 'Completed',
    'Professional': 'Professional',
    'Personal': 'Personal',
  };

  const tabIcons = {
    'My Tasks': <FaTasks />,
    "Today's Tasks": <CgToday />,
    'Delayed Tasks': <FaHourglassHalf />,
    'Upcoming Tasks': <FaCalendar />,
    "This Week's Tasks": <FaCalendarWeek />,
    "This Month's Tasks": <FaCalendar />,
    'Completed': <FaExclamationCircle />,
    'Professional': <PiHourglassHighFill />,
    'Personal': <SiVlcmediaplayer />,
  };

  const filteredTasks = hideCompletedTasks
    ? tasks.filter((task) => task.status !== 'completed')
    : tasks;

  return (
    <Fragment>
      <Container fluid>
        <div className="email-wrap bookmark-wrap">
          <Row>
            <Col lg={12} md={12} sm={12} className="box-col-12">
              <div className="email-right-aside bookmark-tabcontent">
                <Card className="email-body radius-left">
                  <div className="ps-0">
                    <div className="card-header d-flex flex-wrap justify-content-between align-items-center ">
                      <div className="d-flex align-items-center flex-grow-1">
                        <h5 className="mb-0">{tabHeadings[activeTab]}</h5>
                      </div>
                      <div >
                        <BackButton />
                      </div>
                    </div>
                    <CardBody>
                      <AddModal fetchTasks={fetchTasks} />
                      <div className='d-flex'>
                        <Dropdown>
                          <Dropdown.Toggle as="div" id="dropdown-basic">
                            <Button variant="secondary" className="align-items-center main-menu">
                              Filter <FaSortDown className='mytask-toggle-icon mb-2' />
                            </Button>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {Object.keys(tabHeadings).map(tab => (
                              <Dropdown.Item
                                key={tab}
                                className={activeTab === tab ? 'active' : ''}
                                onClick={() => handleTabChange(tab)}
                              >
                                {tabIcons[tab]} {tabHeadings[tab]}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className="checkbox-group align-items-center d-flex">
                          <label style={{ color: "white", marginLeft: "10px" }}>
                            <input
                              className="checkbox_animated m-l-10"
                              type="checkbox"
                              checked={hideCompletedTasks}
                              onChange={handleCheckboxChange}
                            />{" "}
                          </label>
                          <h6 style={{ color: "black" }}>Hide Completed Tasks </h6>
                        </div>
                      </div>
                      <CreatedByme mytasks={filteredTasks} fetchData={fetchTasks} ref={componentRef} />
                    </CardBody>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Task;