import React, { Fragment, useEffect, useState } from 'react';
import { Password } from '../constant';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import FacebookLogin from 'react-facebook-login';
import axios from 'axios';
import API from '../utils';
import { jwtDecode } from 'jwt-decode';
import MyLoader from '../components/common/myloader';
import CardDetailsModal from '../auth/cardDetailsmodal/cardDetails';
import Cookies from 'js-cookie';
import Loginbg from "../assets/images/logo/bg.png";


const Signup = () => {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        user_name: '',
        email: '',
        password: '',
        sub: '',
    });
    const [validationErrors, setValidationErrors] = useState({});
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSignupClick = () => {
        setLoading(true);
        setTimeout(() => {
            navigate('/login');
            setLoading(false);
        }, 1000);
    };

    const checkSubscription = async (userId) => {
        // try {
        //   const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.checkSubscription}/${userId}`, {
        //     headers: {
        //       Authorization: `${API.AUTHORIZATION_TOKEN}`
        //     }
        //   });

        //   // Assuming response.data.status will be "success" and message will be a descriptive text
        //   if (response.data.status === "success" && response.data.message.includes("not expired")) {
        //     return true; // Subscription is valid
        //   } else {
        //     return false; // Subscription is not valid
        //   }
        // } catch (error) {
        //   console.error('Error checking subscription:', error);
        //   return false; // Default to invalid subscription on error
        // }


        const formDataToSend = new FormData();
        formDataToSend.append('userid', userId);

        try {
            const response = await fetch(`${API.BASE_URL}${API.ENDPOINTS.check_subscription}`, {
                method: 'POST',
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`
                },
                body: formDataToSend
            });

            const data = await response.json();
            if (data.status === "success") {
                return true;
            } else {
                localStorage.setItem("isSubscriptionValid", false);
                return false;
            }
        } catch (error) {
            console.error("Error during login:", error);
        }
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
        const errors = {};

        // if (!formData.user_name) {
        //     errors.user_name = 'Name is required';
        // }
        if (!formData.user_name) {
            errors.user_name = 'User Name is required';
        } else if (formData.user_name.length > 20) {
            errors.user_name = 'User Name must be at least 20 characters';
        }

        if (!formData.email) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Email is invalid';
        }

        if (!formData.password) {
            errors.password = 'Password is required';
        } else if (formData.password.length < 6) {
            errors.password = 'Password must be at least 6 characters';
        }

        setValidationErrors(errors);

        if (Object.keys(errors).length === 0) {

            const formDataToSend = new FormData();
            formDataToSend.append('email', formData.email);
            try {
                const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.checkUserExists}`, formDataToSend, {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                });

                if (response.data.status === "success") {
                    // toast.error(response.data.message);
                    setShowModal(true);
                } else {
                    toast.error(response.data.message);
                    setShowModal(false);
                }
            } catch (error) {

            }

        }
    };
    // const [googleLoaded, setGoogleLoaded] = useState(false);

    const clearAllCookies = () => {
        Cookies.remove('your_cookie_name');

        const allCookies = Cookies.get();
        Object.keys(allCookies).forEach((cookie) => {
            Cookies.remove(cookie);
        });
    };

    const handleLoginSuccess = (userData) => {
        localStorage.setItem("login", JSON.stringify(userData));
        localStorage.setItem("profile", userData.profile);
        localStorage.setItem("email", userData.email);
        localStorage.setItem("user_name", userData.user_name);
    };


    const handleModalSubmit = async (cardDetails) => {
        setLoading(true);

        const formDataToSend = new FormData();
        formDataToSend.append('user_name', formData.user_name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('password', formData.password);
        formDataToSend.append('price', 100);
        formDataToSend.append('card_number', cardDetails.card_number);
        formDataToSend.append('exp_month', cardDetails.exp_month);
        formDataToSend.append('exp_year', cardDetails.exp_year);
        formDataToSend.append('cvc', cardDetails.cvc);

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.signup}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`
                },
            });

            if (response.data.status === "success") {
                toast.success(response.data.message);
                try {
                    const loginResponse = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.login}`,
                        formDataToSend,
                        {
                            headers: {
                                Authorization: `${API.AUTHORIZATION_TOKEN}`,
                                'Content-Type': 'multipart/form-data'
                            },
                        }
                    );
                    if (loginResponse.data.status === "success") {
                        localStorage.setItem("login", true);
                        localStorage.setItem("user_id", loginResponse.data.user_data.id);
                        localStorage.setItem("user_name", loginResponse.data.user_data.user_name);
                        localStorage.setItem("email", loginResponse.data.user_data.email);

                        const userData = {
                            email: loginResponse.data.user_data.email,
                            name: loginResponse.data.user_data.name,
                            profile: loginResponse.data.user_data.profile,
                            user_name: loginResponse.data.user_data.user_name,
                        };

                        const isValidSubscription = await checkSubscription(loginResponse.data.user_data.id);
                        localStorage.setItem("isSubscriptionValid", isValidSubscription);

                        if (isValidSubscription) {
                            navigate(`/`);
                            handleLoginSuccess(userData);
                            toast.success(loginResponse.data.message);

                        } else {
                            navigate(`/users/userEdit`);
                            handleLoginSuccess(userData);
                            toast.success(loginResponse.data.message);
                        }
                    } else {
                        toast.error(loginResponse.data.message);
                    }
                } catch (error) {
                    console.error("Error during login:", error);
                    toast.error("An error occurred. Please try again.");
                }
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Signup failed.");
        } finally {
            setLoading(false);
            setShowModal(false);
        }
    };

    const checkGoogleUser = async (email, name, sub) => {
        setLoading(true);

        const formDataToSend = new FormData();
        formDataToSend.append('email', email);
        formDataToSend.append('name', name);
        formDataToSend.append('sub', sub);

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.googleLogin}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    }
                }
            );

            if (response.data.status === "success") {
                localStorage.setItem("login", true);
                localStorage.setItem("user_id", response.data.user_data.id);
                localStorage.setItem("user_name", response.data.user_data.user_name);
                localStorage.setItem("email", response.data.user_data.email);
                localStorage.setItem("profileURL", response.data.user_data.profileURL);

                navigate('/');
                toast.success(response.data.message);
                return { isValid: true, userData: response.data.user_data };
            } else {
                toast.error(response.data.message);
                return { isValid: false };
            }
        } catch (error) {
            console.error("Error checking Google user:", error);
            toast.error("An error occurred while checking Google user.");
            return { isValid: false, error };
        }
    };

    const handleGoogleSuccess = async (response) => {
        try {
            const token = response.credential;
            const decoded = jwtDecode(token);

            const userData = {
                profileURL: decoded.picture,
                email: decoded.email,
                name: decoded.name,
                sub: decoded.sub
            };

            const userCheckResponse = await checkGoogleUser(userData.email, userData.name, userData.sub);

            if (userCheckResponse.isValid) {
                toast.success("User validation True");

            } else {
                toast.error("User validation failed. Please try again.");
            }
        } catch (error) {
            toast.error("An error occurred during the Google login process.");
            console.error("Google login error:", error);
        }
    };

    const handleGoogleLogin = () => {
        window.google.accounts.id.prompt((notification) => {

            if (notification.isNotDisplayed()) {
                // toast.error("Google login prompt was not displayed.");
                clearAllCookies();
                return;
            } else if (notification.isSkippedMoment()) {
                // toast.error("Google login was skipped.");
                clearAllCookies();
                return;
            }
        });
    };

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.onload = () => {
            window.google.accounts.id.initialize({
                client_id: "1077855235576-pvkhaga0uqmo4s9rih9as02rg1gvfi9n.apps.googleusercontent.com",
                callback: handleGoogleSuccess,
            });
            // setGoogleLoaded(true);
        };
        document.body.appendChild(script);
    }, []);

    // const checkGoogleUser = async (email, name, sub) => {
    //     setLoading(true);

    //     const formDataToSend = new FormData();
    //     formDataToSend.append('email', email);
    //     formDataToSend.append('name', name);
    //     formDataToSend.append('sub', sub);

    //     try {
    //         const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.googleLogin}`,
    //             formDataToSend,
    //             {
    //                 headers: {
    //                     Authorization: `${API.AUTHORIZATION_TOKEN}`
    //                 }
    //             }
    //         );

    //         if (response.data.status === "success") {
    //             localStorage.setItem("login", true);
    //             localStorage.setItem("user_id", response.data.user_data.id);
    //             localStorage.setItem("user_name", response.data.user_data.user_name);
    //             localStorage.setItem("email", response.data.user_data.email);
    //             localStorage.setItem("profile", response.data.user_data.profile);

    //             const isValidSubscription = await checkSubscription(response.data.user_data.id);
    //             localStorage.setItem("isSubscriptionValid", isValidSubscription);


    //             navigate('/');
    //             toast.success(response.data.message);
    //             return { isValid: true, userData: response.data.user_data };
    //         } else {
    //             toast.error(response.data.message);
    //             return { isValid: false };
    //         }
    //     } catch (error) {
    //         console.error("Error checking Google user:", error);
    //         toast.error("An error occurred while checking Google user.");
    //         return { isValid: false, error };
    //     }
    // };


    // const handleGoogleSuccess = async (response) => {
    //     try {
    //         const token = response.credential;
    //         const decoded = jwtDecode(token);

    //         const userData = {
    //             profile: decoded.picture,
    //             email: decoded.email,
    //             name: decoded.name,
    //             sub: decoded.sub
    //         };


    //         // Call checkGoogleUser with email, name, and sub
    //         const userCheckResponse = await checkGoogleUser(userData.email, userData.name, userData.sub);

    //         // Assuming userCheckResponse contains necessary validation results
    //         if (userCheckResponse.isValid) {
    //             toast.success("User validation True");

    //         } else {
    //             toast.error("User validation failed. Please try again.");
    //         }
    //     } catch (error) {
    //         toast.error("An error occurred during the Google login process.");
    //         console.error("Google login error:", error);
    //     }
    // };


    // const handleGoogleFailure = (error) => {
    //     toast.error("Google login failed.");
    //     if (error.error === "popup_closed_by_user") {
    //         toast.error("Google sign-in was interrupted. Please try again.");
    //     } else {
    //         toast.error("Google login failed. Please try again.");
    //     }
    // };

    const checkFacebookUser = async (email, name, sub) => {
        setLoading(true);

        if (!sub) {
            toast.error("Facebook ID is required.");
            setLoading(false);
            return { isValid: false, message: "Facebook ID is required." };
        }

        const formDataToSend = new FormData();
        formDataToSend.append('email', email);
        formDataToSend.append('name', name);
        formDataToSend.append('sub', sub);

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.facebookLogin}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    }
                }
            );

            if (response.data.status === "success") {
                localStorage.setItem("login", true);
                localStorage.setItem("profile", response.data.user_data.profile);
                localStorage.setItem("user_id", response.data.user_data.id);
                localStorage.setItem("user_id", response.data.user_data.id);
                localStorage.setItem("user_name", response.data.user_data.user_name);
                localStorage.setItem("email", response.data.user_data.email);


                const isValidSubscription = await checkSubscription(response.data.user_data.id);
                localStorage.setItem("isSubscriptionValid", isValidSubscription);

                navigate(`/`);
                toast.success(response.data.message);
                return { isValid: true, userData: response.data.user_data };
            } else {
                toast.error(response.data.message);
                return { isValid: false };
            }
        } catch (error) {
            console.error("Error checking Facebook user:", error);
            toast.error("An error occurred while checking Facebook user.");
            return { isValid: false, error };
        } finally {
            setLoading(false); // Hide loader
        }
    };

    const responseFacebook = async (response) => {
        try {
            const userData = {
                profile: response.picture ? response.picture.data.url : '',
                email: response.email,
                name: response.name,
                sub: response.id  // Assuming 'id' is the equivalent of 'sub' for Facebook
            };

            // Call checkFacebookUser with email, name, and sub
            const userCheckResponse = await checkFacebookUser(userData.email, userData.name, userData.sub);

            // Handle the response from checkFacebookUser
            if (userCheckResponse.isValid) {
                toast.success("User validation True");
            } else {
                toast.error("User validation failed. Please try again.");
            }
        } catch (error) {
            toast.error("An error occurred during the Facebook login process.");
            console.error("Facebook login error:", error);
        }
    };

    const handleFacebookFailure = (error) => {
        console.error("Facebook login failed:", error);
        toast.error("Facebook login failed.");
    };

    return (
        <>
            <MyLoader active={loading} >
                <Fragment>
                    <div className="login-container d-flex">
                        <div className="background-image">
                            <div className="p-3 text-white justify-content-end text-end align-items-center">
                                <div className="">
                                    <div className="d-flex justify-content-end">
                                        <img src={Loginbg} className="Logosvg-main1" alt="background" />
                                    </div>
                                    <div className="d-flex align-items-lg-center">
                                        <h2 className="text-white  mb-0 fw-bold ">Your Personal Operating System to<br /> Live Life More Intentionally</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="login-box">
                            <div className="auth-heding-main-singup">
                                <h2 className="auth-heding">SignUp</h2>
                                <p className="auth-peregraft">Enter your Email and Password For Signup</p>
                            </div>
                            <form className="theme-form" >
                                <div className="form-group">
                                    <label className="col-form-label"><b>Name</b></label>
                                    <input
                                        className={`form-control ${validationErrors.user_name ? 'is-invalid' : ''}`}
                                        name="user_name"
                                        value={formData.user_name}
                                        onChange={handleChange}
                                        type="text"
                                        placeholder="Enter Your Name"
                                    />
                                    {validationErrors.user_name && (
                                        <div className="text-danger">{validationErrors.user_name}</div>
                                    )}
                                </div>

                                <div className="form-group">
                                    <label className="col-form-label"><b>Email</b></label>
                                    <input
                                        className={`form-control ${validationErrors.email ? 'is-invalid' : ''}`}
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        type="text"
                                        placeholder="Enter Your Email"
                                    />
                                    {validationErrors.email && (
                                        <div className="text-danger">{validationErrors.email}</div>
                                    )}
                                </div>

                                <div className="form-group">
                                    <label className="col-form-label"><b>{Password}</b></label>
                                    <input
                                        className={`form-control ${validationErrors.password ? 'is-invalid' : ''}`}
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        type="password"
                                        placeholder="**********"
                                    />
                                    {validationErrors.password && (
                                        <div className="text-danger">{validationErrors.password}</div>
                                    )}
                                </div>


                                <div className="form-group">
                                    <div className=" p-0 suscribe-us">
                                        <b>Subscribe Us @ $15</b>
                                    </div>
                                </div>

                                <div className="form-group  mt-3 mb-0 d-grid">
                                    <button className="login-button" onClick={handleSubmit}>
                                        Sign up
                                    </button>
                                </div>

                                {/* <div className="divider d-flex align-items-center my-3">
                                    <p className="text-center fw-bold mx-3 mb-0">OR</p>
                                </div> */}

                            </form>

                            <div className="row my-4">
                                <div className="col-lg-12  col-sm-12  justify-content-center my-2">
                                    {/* <GoogleOAuthProvider clientId="1077855235576-pvkhaga0uqmo4s9rih9as02rg1gvfi9n.apps.googleusercontent.com">
                                            <GoogleLogin
                                                onSuccess={handleGoogleSuccess}
                                                onFailure={handleGoogleFailure}
                                                render={renderProps => (
                                                    <>
                                                        <div className="col-lg-12">
                                                            <button
                                                                onClick={renderProps.onClick}
                                                                disabled={renderProps.disabled}
                                                                className="social-login-button"
                                                            >
                                                                <img
                                                                    src="https://developers.google.com/identity/images/g-logo.png"
                                                                    alt="Google logo"
                                                                    className="logo"
                                                                />
                                                                Sign in with Google
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                            />
                                        </GoogleOAuthProvider> */}
                                    <button
                                        className="social-login-button"
                                        onClick={handleGoogleLogin}
                                    >
                                        Sign in with Google
                                    </button>
                                </div>
                                <div className="col-lg-12  col-sm-12  justify-content-center my-2">
                                    <FacebookLogin
                                        appId="502068089355876"
                                        fields="name,email,picture"
                                        callback={responseFacebook}
                                        onFailure={handleFacebookFailure}
                                        // icon={<FaFacebook className="ms-1 Facebook-icon" />}
                                        render={renderProps => (
                                            <button
                                                className="social-login-button"
                                                onClick={renderProps.onClick}
                                            >
                                                <img alt="Facebook icon" className="logo" />
                                                <span className="flex-shrink-0">with Facebook</span>
                                            </button>
                                        )}
                                    />
                                </div>

                                <style jsx>{`
        .social-login-button {
          background-color: #4285F4; /* Default to Google button color */
          color: #fff;
          padding: 10px 20px;
          font-size: 16px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%; /* Full width */
          max-width: 300px; /* Consistent width */
        }

        .social-login-button img.logo {
          width: 20px; /* Consistent icon size */
          margin-right: 10px;
        }

        .social-login-button:nth-of-type(2) {
          background-color: #3b5998; /* Facebook button color */
        }
      `}</style>
                            </div>

                            <div className="account-auth text-center my-3">
                                <p className="mb-0">
                                    Don't have an account?
                                    <span
                                        onClick={handleSignupClick}
                                        className='me-1'
                                        style={{ cursor: 'pointer', color: '#00bcd4' }}
                                    >
                                        SignIn
                                    </span>
                                </p>
                                {loading && (
                                    <MyLoader />
                                )}
                            </div>
                        </div>
                    </div >
                    <ToastContainer />
                </Fragment>
                <CardDetailsModal
                    show={showModal}
                    handleClose={() => setShowModal(false)}
                    handleSubmit={handleModalSubmit}
                />
            </MyLoader>
        </>
    );
};

export default Signup;