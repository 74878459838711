import React, { useEffect, useState, useCallback } from "react";
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  useNodesState,
  useEdgesState,
} from "reactflow";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Form,
} from "react-bootstrap";
import "reactflow/dist/style.css";
import { toast } from "react-toastify";
import API from "../../../utils";
import axios from "axios";
import _ from "lodash";
import BackButton from "../../common/header-component/backButton";
const Marketing = () => {
  const user_id = localStorage.getItem("user_id");
  const thisDep = "marketing";
  const department = localStorage.getItem("department");
  const isDisabled = department ? thisDep !== department : false;
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [shape, setShape] = useState("");
  const [workflow_name, setworkflow_name] = useState("");
  const [founderosId, setFoundrosId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [cardBodies, setCardBodies] = useState([
    { id: Date.now().toString(), nodes: [], edges: [], visible: true },
  ]);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  const shapeStyles = {
    rectangle: { borderRadius: "0%" },
    round: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "80px",
      height: "80px",
      borderRadius: "50%",
    },
    diamond: {
      width: "100px",
      height: "100px",
      backgroundColor: "green",
      clipPath: "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      fontSize: "12px",
    },
  };

  useEffect(() => {
    fetchData();
    processfetchData();
  }, []);

  const fetchData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("user_id", user_id);
      formDataToSend.append("category_name", "Marketing");

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_founderos_data_by_cat_name}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        const data = response.data.data[0];
        setFoundrosId(data.id);

        const workflowData = data.workflow_name;
        const updatedCardBodies = Object.keys(workflowData).map((index) => {
          const { workflow_name, shapes } = workflowData[index];

          const nodes = workflow_name.map((name, idx) => ({
            id: (idx + 1).toString(),
            data: { label: name },
            position: { x: 250, y: 100 + idx * 100 },
            draggable: false,
            style: {
              backgroundColor: "green",
              color: "white",
              ...shapeStyles[shapes[idx]],
            },
          }));

          nodes.unshift({
            id: "start",
            data: { label: "Start" },
            position: { x: 50, y: 100 },
            draggable: false,
            style: {
              backgroundColor: "black",
              color: "white",
              ...shapeStyles["rectangle"],
            },
          });

          nodes.push({
            id: "end",
            data: { label: "End" },
            position: { x: 450, y: 100 + nodes.length * 100 },
            draggable: false,
            style: {
              backgroundColor: "black",
              color: "white",
              ...shapeStyles["rectangle"],
            },
          });

          const edges = nodes.slice(0, -1).map((node, idx) => ({
            id: `e-${node.id}-${nodes[idx + 1].id}`,
            source: node.id,
            target: nodes[idx + 1].id,
            animated: true,
          }));

          return {
            id: Date.now().toString(),
            nodes: [...nodes],
            edges: [...edges],
            visible: true,
          };
        });

        setCardBodies(updatedCardBodies);

        if (workflowData["1"]) {
          setCurrentCardIndex(0);
        } else if (workflowData["2"]) {
          setCurrentCardIndex(1);
        } else if (workflowData["3"]) {
          setCurrentCardIndex(2);
        } else if (workflowData["4"]) {
          setCurrentCardIndex(3);
        } else {
          setCurrentCardIndex(0);
        }
      } else {
        console.error("Error: Status not success");
      }
    } catch (error) {
      console.error("Error fetching workflow steps:", error);
    }
  };

  const handleLabelChange = (e) => {
    const value = e.target.value;
    setworkflow_name(value);
    setErrorMessage(value.trim() === "" ? "This Field is required" : "");
  };

  const addStep = async (event, index) => {
    event.preventDefault();

    if (errorMessage || workflow_name.trim() === "") {
      setErrorMessage("This Field is required");
      return;
    }

    const newNodeId = (cardBodies[index].nodes.length + 1).toString();
    const newNode = {
      id: newNodeId,
      data: { label: workflow_name },
      position: { x: 250, y: 100 + (cardBodies[index].nodes.length - 1) * 100 },
      draggable: false,
      style: {
        backgroundColor: "green",
        color: "white",
        ...shapeStyles[shape],
      },
    };

    const newNodes = [
      ...cardBodies[index].nodes.filter(
        (node) => node.id !== "start" && node.id !== "end"
      ),
      newNode,
    ];

    const endPosition = { x: 450, y: 100 + newNodes.length * 100 };
    const newEdges = [];
    const filteredEdges = cardBodies[index].edges.filter(
      (edge) => edge.target !== "end"
    );

    if (newNodes.length === 1) {
      newEdges.push({
        id: `e-start-${newNodeId}`,
        source: "start",
        target: newNodeId,
        animated: true,
      });
      newEdges.push({
        id: `e-${newNodeId}-end`,
        source: newNodeId,
        target: "end",
        animated: true,
      });
    } else {
      newEdges.push({
        id: `e-${newNodes[newNodes.length - 2].id}-${newNodeId}`,
        source: newNodes[newNodes.length - 2].id,
        target: newNodeId,
        animated: true,
      });
      newEdges.push({
        id: `e-${newNodeId}-end`,
        source: newNodeId,
        target: "end",
        animated: true,
      });
    }

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("user_id", user_id);
      formDataToSend.append("index[]", index + 1);
      formDataToSend.append("category_name", "Marketing");
      formDataToSend.append("workflow_name[]", workflow_name);
      formDataToSend.append("shape[]", shape);

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.add_founderos_step}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        window.location.reload();
        setCardBodies((prevCardBodies) => {
          const updatedCardBodies = [...prevCardBodies];
          updatedCardBodies[index] = {
            ...updatedCardBodies[index],
            nodes: [
              ...newNodes,
              {
                id: "start",
                data: { label: "Start" },
                position: { x: 50, y: 100 },
                draggable: false,
                style: {
                  backgroundColor: "black",
                  color: "white",
                  ...shapeStyles["rectangle"],
                },
              },
              ...newNodes,
              {
                id: "end",
                data: { label: "End" },
                position: endPosition,
                draggable: false,
                style: {
                  backgroundColor: "black",
                  color: "white",
                  ...shapeStyles["rectangle"],
                },
              },
            ],
            edges: [...filteredEdges, ...newEdges],
          };

          return updatedCardBodies;
        });

        setworkflow_name("");
        setShape("");
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error adding step:", error);
      setErrorMessage("Failed to add step. Please try again.");
    }
  };

  const addNewFlow = () => {
    setCardBodies([
      ...cardBodies,
      { id: Date.now().toString(), nodes: [], edges: [], visible: true },
    ]);
    setCurrentCardIndex(cardBodies.length);
  };

  const [formData, setFormData] = useState({
    process_name: "",
  });

  const processfetchData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("user_id", user_id);
      formDataToSend.append("category_name", "Marketing");

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_founderos_process_name}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.stautus === "success") {
        const data = response.data.data;
        if (data && data.length > 0) {
          const VisionAnswer = data[0].process_name;
          setFormData({ process_name: VisionAnswer });
        } else {
          console.error("No process name found in the response data.");
        }
      } else {
        console.error("Error:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching process name:", error);
    }
  };

  const debouncedApiCall = useCallback(
    _.debounce(async (name, value) => {
      const formDataToSend = new FormData();
      formDataToSend.append("user_id", user_id);
      formDataToSend.append("category_name", "Marketing");
      formDataToSend.append("process_name", value);

      try {
        const response = await axios.post(
          `${API.BASE_URL}${API.ENDPOINTS.add_update_founderos_process_name}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
            },
          }
        );
        if (response.data.stautus === "success") {
          toast.success(response.data.message);
          processfetchData();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Profile update failed.");
      }
    }, 2000),
    []
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    debouncedApiCall(name, value);
  };

  return (
    <Container fluid>
      {/* <BackButton /> */}

      <Form className="justify-content-between">
        <Row className="justify-content-between mb-3">
          <Col md={4} sm={12}>
            <label>
              <b>Process Name </b>
            </label>
            <div>
              <input
                type="text"
                className="form-control"
                name="process_name"
                placeholder="Enter Process Name"
                value={formData.process_name}
                onChange={handleChange}
                rows="4"
                disabled={isDisabled}
              />
            </div>
          </Col>
          {/* <Col className='align-content-end'>
            <BackButton />
          </Col> */}
        </Row>
      </Form>
      <Row>
        <Col lg={12}>
          <Card>
            {/* <CardHeader>
              <div className="align-items-center d-flex justify-content-between">
                <h5>Marketing</h5>
                <div>
                  <Button
                    onClick={addNewFlow}
                    className="btn btn-success"
                    disabled={isDisabled}
                  >
                    Add a Process
                  </Button>
                </div>
              </div>
            </CardHeader> */}
            <div className="card-header d-flex flex-wrap justify-content-between align-items-center ">
              <div className="d-flex align-items-center flex-grow-1">
                <h5>Marketing</h5>
              </div>
              <div className="align-items-center justify-content-between d-flex">
                <Button onClick={addNewFlow} className="btn btn-success me-lg-2" disabled={isDisabled}>Add a Process</Button>
                <BackButton />
              </div>
            </div>
            <CardBody>
              {cardBodies.map((cardBody, index) => (
                <div key={cardBody.id} style={{ marginBottom: "20px" }}>
                  <Form
                    className="justify-content-between"
                    onSubmit={(event) => addStep(event, index)}
                  >
                    <Row className="justify-content-between">
                      <Col md={4}>
                        <Form.Group controlId="workflowName">
                          <Form.Label>
                            <b>Process Steps</b>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Process Steps"
                            value={workflow_name}
                            onChange={handleLabelChange}
                            disabled={isDisabled}
                          />
                          {errorMessage && (
                            <div className="text-danger">{errorMessage}</div>
                          )}
                        </Form.Group>
                      </Col>

                      <div className="d-flex justify-content-end align-content-center col-md-4">
                        <Button
                          type="submit"
                          style={{ height: "fit-content" }}
                          className="btn btn-success mt-4"
                          disabled={isDisabled}
                        >
                          Add Step
                        </Button>
                      </div>
                    </Row>
                  </Form>
                  {cardBody.visible && (
                    <div style={{ height: "500px", width: "100%" }}>
                      <ReactFlow
                        key={cardBody.id}
                        nodes={cardBody.nodes}
                        edges={cardBody.edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                      //onNodeClick={handleNodeClick}
                      >
                        <Background />
                        <Controls />
                        <MiniMap />
                      </ReactFlow>
                    </div>
                  )}
                </div>
              ))}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Node</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md="12">
                                <label><b>Process Steps</b></label>
                                <input
                                    type="text"
                                    placeholder='Enter Process Steps'
                                    className="form-control"
                                    value={workflow_name}
                                    onChange={handleLabelChange}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                    <Button variant="primary" onClick={handleModalSubmit}>Save changes</Button>
                </Modal.Footer>
            </Modal> */}
    </Container>
  );
};

export default Marketing;
