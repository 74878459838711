import React from "react";

const Footer = () => {
    const currentYear = new Date().getFullYear(); // Get the current year
    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 footer-copyright">
                        <p className="mb-0">{"Copyright "} {currentYear} {" © Founders OS. All rights reserved."}</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
