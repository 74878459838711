import React, { useEffect, useState, useCallback, useRef } from "react";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import API from "../../../utils";
import axios from "axios";
import BackButton from "../../common/header-component/backButton";

// Debounce function
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const PersonalSelling = () => {
  const uid = localStorage.getItem('user_id');
  const [formData, setFormData] = useState({
    Remembered: ["", "", "", "", ""],
    parents: ["", "", "", "", ""],
    Spouse: ["", "", "", "", ""],
  });

  const formDataRef = useRef(formData); // Ref to keep the latest formData

  const handleSubmit = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);

    Object.keys(formDataRef.current).forEach((key) => {
      formDataToSend.append(key, JSON.stringify(formDataRef.current[key]));
    });

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.competence_insert}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        fetchData(); // Optionally refetch data after successful submission
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during request:", error);
      toast.error('Please try again later.');
    } finally {
      // setLoading(false);
    }
  };

  const debouncedHandleSubmit = useCallback(debounce(handleSubmit, 2000), []);

  const handleChange = (e, questionSet, index) => {
    const { value } = e.target;

    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [questionSet]: prevData[questionSet].map((item, i) => (i === index ? value : item)),
      };

      // Update the ref to hold the latest formData
      formDataRef.current = updatedData;

      return updatedData;
    });

    // Call the debounced function to integrate the API after 2 seconds
    debouncedHandleSubmit();
  };

  const fetchData = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.competence_get}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.status === "success") {
        const data = response.data.data[0];
        const parsedRemembered = JSON.parse(data.Remembered || '[]');
        const parsedSpouse = JSON.parse(data.Spouse || '[]');
        const parsedParents = JSON.parse(data.parents || '[]');

        setFormData({
          Remembered: parsedRemembered.length ? parsedRemembered : ["", "", "", "", ""],
          Spouse: parsedSpouse.length ? parsedSpouse : ["", "", "", "", ""],
          parents: parsedParents.length ? parsedParents : ["", "", "", "", ""],
        });

        // Update the ref with fetched data
        formDataRef.current = {
          Remembered: parsedRemembered.length ? parsedRemembered : ["", "", "", "", ""],
          Spouse: parsedSpouse.length ? parsedSpouse : ["", "", "", "", ""],
          parents: parsedParents.length ? parsedParents : ["", "", "", "", ""],
        };
      } else {
        toast.error(response.data.message || 'No data available');
      }
    } catch (error) {
      console.error("Error during request:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container fluid={true}>
      {/* <BackButton /> */}

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            {/* <div className="card-header">
              <h5>Personal Selling Competence: Building Funnel Questions</h5>
            </div> */}
            <div className="card-header d-flex flex-wrap justify-content-between align-items-center ">
              <div className="d-flex align-items-center flex-grow-1">
                <h5>Personal Selling Competence: Building Funnel Questions</h5>
              </div>
              <div >
                <BackButton />
              </div>
            </div>
            <form className="form theme-form">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group mb-2">
                      <label><b>Quality/Disqualify</b>: What are 5 questions to screen them in (Qualify) and/or screen them out (Disqualify)?</label>
                      {formData.Remembered.map((value, index) => (
                        <input
                          key={index}
                          className="form-control mb-2"
                          name={`Remembered-${index}`}
                          value={value}
                          onChange={(e) => handleChange(e, "Remembered", index)}
                          placeholder={`Question ${index + 1}`}
                        />
                      ))}
                    </div>

                    <div className="form-group mb-2">
                      <label><b>Expose & Monetize Gaps (Pain)</b>: What are 5 questions to identify specific pains?</label>
                      {formData.Spouse.map((value, index) => (
                        <input
                          key={index}
                          className="form-control mb-2"
                          name={`Spouse-${index}`}
                          value={value}
                          onChange={(e) => handleChange(e, "Spouse", index)}
                          placeholder={`Question ${index + 1}`}
                        />
                      ))}
                    </div>

                    <div className="form-group mb-2">
                      <label><b>Propose a Win-Win Outcome Covenant</b>: What are 5 questions that propose a relationship of mutual benefit?</label>
                      {formData.parents.map((value, index) => (
                        <input
                          key={index}
                          className="form-control mb-2"
                          name={`parents-${index}`}
                          value={value}
                          onChange={(e) => handleChange(e, "parents", index)}
                          placeholder={`Question ${index + 1}`}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default PersonalSelling;
