import React, { useState, useEffect, useCallback } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import API, { isSubscribedAccountant } from "../../../utils";
import DataTable from "react-data-table-component";
import BackButton from "../../common/header-component/backButton";

const ClientsName = () => {
  const uid = localStorage.getItem("user_id");
  const [formData, setFormData] = useState({
    name: "",
    customer_note: "",
    company: "",
    title: "",
    Testimonial: "",
    request_review_email: "",
    selectedOption: "",
  });
  const [dropdownOptions, setDropdownOptions] = useState([]);

  const fetchData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("user_id", uid);

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_client_template}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        const templates = response.data.data;
        const formattedOptions = templates.map((template) => ({
          value: template.id,
          label: template.name,
        }));
        setDropdownOptions(formattedOptions);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append("user_id", uid);
    formDataToSend.append("title", formData.title);
    formDataToSend.append("company", formData.company);
    formDataToSend.append("request_testimonial", formData.Testimonial);
    formDataToSend.append("request_review_email", formData.request_review_email);
    formDataToSend.append("name", formData.name);
    formDataToSend.append("customer_note", formData.customer_note);
    formDataToSend.append("template_id", formData.selectedOption);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.add_client}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        toast.success("Client Added Successfully");
        fetchData();
        fetchTasks();
        setFormData({
          name: "",
          service: "",
          title: "",
          Testimonial: "",
          request_review_email: "",
          selectedOption: "",
          customer_note: "",
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during request:", error);
    }
  };

  const handleSend = async (e, rowId) => {
    e.preventDefault();
    setEmailLoading((prevState) => ({ ...prevState, [rowId]: true }));

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("id", rowId);

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.send_client_email}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during request:", error);
    } finally {
      setEmailLoading((prevState) => ({ ...prevState, [rowId]: false }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDropdownChange = (e, rowId) => {
    const { value } = e.target;
    const updatedOutcomes = projectData.map((item) =>
      item.id === rowId ? { ...item, selectedTemplateId: value } : item
    );

    setProjectData(updatedOutcomes);

    setUpdatedTask({ field: "template_id", value, goalid: rowId });
  };

  const [updatedTask, setUpdatedTask] = useState(null);

  const debouncedUpdateTask = useCallback(
    debounce(async (task) => {
      const { goalid, field, value } = task;

      const formDataToSend = new FormData();
      formDataToSend.append("client_id", goalid);
      formDataToSend.append("field", field);
      formDataToSend.append("text", value);

      try {
        const response = await axios.post(
          `${API.BASE_URL}${API.ENDPOINTS.update_clients}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
            },
          }
        );

        if (response.data.status === "success") {
          // toast.success(response.data.message);
          toast.success("Client Updating Successfully");
          fetchData();
          fetchTasks();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error during update:", error);
      }
    }, 1000),
    []
  );

  const handleEditInputChange = (e, index, field, goalid) => {
    const { value } = e.target;
    const updatedOutcomes = [...projectData];
    updatedOutcomes[index][field] = value;

    setUpdatedTask({ field, value, goalid });
  };

  useEffect(() => {
    if (updatedTask) {
      debouncedUpdateTask(updatedTask);
    }
  }, [updatedTask, debouncedUpdateTask]);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isSubscribedAccountant()) {
      navigate("/users/billings");
      window.location.reload();
    }
  }, [navigate]);

  const [projectData, setProjectData] = useState([]);

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("user_id", uid);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_all_client}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        const sortedTasks = response.data.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setProjectData(sortedTasks);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during task fetching:", error);
    }
  };
  const [emailLoading, setEmailLoading] = useState({});

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      cell: (row, index) => (
        <div className="clintname-table w-100">
          <input
            type="text"
            name="name"
            value={row.name}
            className="form-control w-100"
            onChange={(e) => handleEditInputChange(e, index, "name", row.id)}
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      cell: (row, index) => (
        <div className="clintname-table w-100">
          <input
            type="text"
            name="title"
            value={row.title}
            className="form-control w-100"
            onChange={(e) => handleEditInputChange(e, index, "title", row.id)}
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: " Email",
      selector: (row) => row.request_review,
      cell: (row, index) => (
        <div className="clintname-table w-100">
          <input
            type="text"
            name="request_review"
            value={row.request_review}
            className="form-control w-100"
            onChange={(e) =>
              handleEditInputChange(e, index, "request_review", row.id)
            }
          />
        </div>
      ),
      sortable: true,
    },

    {
      name: "Template",
      selector: (row) => row.selectedTemplateId || row.template_id,
      cell: (row, index) => (
        <div className="clintname-table w-100">
          <select
            id="dropdown"
            name="selectedOption"
            className="form-control w-100"
            value={row.selectedTemplateId || row.template_id || ""}
            onChange={(e) => handleDropdownChange(e, row.id)}
          >
            <option value="">Select an option</option>
            {dropdownOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Customer Notes",
      selector: (row) => row.customer_note,
      cell: (row, index) => (
        <div className="clintname-table w-100">
          <textarea
            type="text"
            name="customer_note"
            value={row.customer_note}
            className="form-control w-100"
            onChange={(e) =>
              handleEditInputChange(e, index, "customer_note", row.id)
            }
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          {emailLoading[row.id] ? (
            <Button className="btn btn-primary p-2" disabled>
              <span
                className="spinner-border spinner-border-sm me-1"
                role="status"
                aria-hidden="true"
              ></span>
              Sending...
            </Button>
          ) : (
            <Button
              className="btn btn-primary p-2"
              onClick={(e) => handleSend(e, row.id)}
            >
              Send Email
            </Button>
          )}
        </div>
      ),
      sortable: false,
    },
  ];

  // Function to check if the "Save" button should be enabled
  const isSaveButtonEnabled = () => formData.request_review_email.trim() !== "";

  return (
    <>
      <div className="container-fluid">
        {/* <BackButton /> */}
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              {/* <div className="card-header">
                <div className="row">
                  <div className="align-items-center justify-content-between d-flex">
                    <h5>Clients Name</h5>
                  </div>
                </div>
              </div> */}
              <div className="card-header d-flex flex-wrap justify-content-between align-items-center ">
                <div className="d-flex align-items-center flex-grow-1">
                  <h5>Clients Name</h5>
                </div>
                <div >
                  <BackButton />
                </div>
              </div>

              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="form-group mb-3 col-lg-3 col-md-6">
                      <label htmlFor="name">
                        <b>Name:</b>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group mb-3 col-lg-3 col-md-6">
                      <label htmlFor="title">
                        <b>Title:</b>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        placeholder="Title"
                        value={formData.title}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group mb-3 col-lg-3 col-md-6">
                      <label htmlFor="request_review_email">
                        <b> Email:</b>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="request_review_email"
                        name="request_review_email"
                        placeholder="Email"
                        value={formData.request_review_email}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="form-group mb-3 col-lg-3 col-md-6">
                      <label htmlFor="dropdown">
                        <b>Clients Template Option:</b>
                      </label>
                      <select
                        id="dropdown"
                        name="selectedOption"
                        className="form-control"
                        value={formData.selectedOption}
                        onChange={handleInputChange}
                      >
                        <option value="">Select an option</option>
                        {dropdownOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group mb-3 col-lg-12 col-md-12 col-sm-12 ">
                      <label htmlFor="customer_note">
                        <b>Customer Notes:</b>
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="customer_note"
                        name="customer_note"
                        placeholder="Customer Notes "
                        value={formData.customer_note}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="card-footer text-end">
                    <Button
                      type="button"
                      variant="success"
                      onClick={handleSubmit}
                      disabled={!isSaveButtonEnabled()} // Disable if `request_review_email` is empty
                    >
                      Save
                    </Button>
                  </div>

                  <DataTable
                    columns={columns}
                    data={projectData}
                    persistTableHead={true}
                    pagination
                    className="rolmodel-table"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientsName;
