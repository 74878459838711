import React, { useState } from "react";
import { Progress } from 'reactstrap';
import BackButton from "../../common/header-component/backButton";

const Founderdrivers = () => {
  const uid = localStorage.getItem("user_id");

  const storedCompletion = localStorage.getItem('peopleCompletionPercentage');
  const peopleCompletionPercentage = storedCompletion ? Number(storedCompletion) : 0;

  const storedCustomerCompletion = localStorage.getItem('customerCompletionPercentage');
  const customerCompletionPercentage = storedCustomerCompletion ? Number(storedCustomerCompletion) : 0;

  const storedCustomerCompletionfinance = localStorage.getItem('financeCompletionPercentage');
  const financeCompletionPercentage = storedCustomerCompletionfinance ? Number(storedCustomerCompletionfinance) : 0; // default to 5 if not set

  const storedtecnologyCompletionfinance = localStorage.getItem('tecnologyCompletionPercentage');
  const tecnologyCompletionPercentage = storedtecnologyCompletionfinance ? Number(storedtecnologyCompletionfinance) : 0; // default to 5 if not set

  const storedopretionCompletionfinance = localStorage.getItem('opretionCompletionPercentage');
  const opretionCompletionPercentage = storedopretionCompletionfinance ? Number(storedopretionCompletionfinance) : 0; // default to 5 if not set

  const storedtransactionCompletionfinance = localStorage.getItem('transactionCompletionPercentage');
  const transactionCompletionPercentage = storedtransactionCompletionfinance ? Number(storedtransactionCompletionfinance) : 0; // default to 5 if not set

  const storedrisckCompletionfinance = localStorage.getItem('risckCompletionPercentage');
  const risckCompletionPercentage = storedrisckCompletionfinance ? Number(storedrisckCompletionfinance) : 0; // default to 5 if not set

  const sections = [
    { name: "People", completion: peopleCompletionPercentage },
    { name: "Technology", completion: tecnologyCompletionPercentage },
    { name: "Operations", completion: opretionCompletionPercentage },
    { name: "Customer", completion: customerCompletionPercentage },
    { name: "Finance", completion: financeCompletionPercentage },
    { name: "Transactions", completion: transactionCompletionPercentage },
    { name: "Risk", completion: risckCompletionPercentage },
  ];

  const getColorForPercentage = (percentage) => {
    if (percentage < 20) return 'danger';
    if (percentage < 40) return 'warning';
    if (percentage < 60) return 'info';
    if (percentage < 80) return 'primary';
    return 'success';
  };

  const [optOutSections, setOptOutSections] = useState([]);

  const sectionLinks = {
    "People": "/growthframeworks/founderdriver/public-drivers-of-growth",
    "Technology": "/growthframeworks/founderdriver/technology-drivers-of-growth",
    "Operations": "/growthframeworks/founderdriver/operations-drivers-of-growth",
    "Customer": "/growthframeworks/founderdriver/customer-drivers-of-growth",
    "Finance": "/growthframeworks/founderdriver/finance-drivers-of-growth",
    "Transactions": "/growthframeworks/founderdriver/transactions-drivers-of-growth",
    "Risk": "/growthframeworks/founderdriver/risk-drivers-of-growth",
  };

  const handleOptOutChange = (section) => {
    setOptOutSections((prevOptOutSections) =>
      prevOptOutSections.includes(section)
        ? prevOptOutSections.filter((s) => s !== section)
        : [...prevOptOutSections, section]
    );
  };

  return (
    <div className="container-fluid">
      {/* <BackButton /> */}
      <div className="row">
        <div className="col-sm-12 ">
          <div className="card ">
            {/* <div className="card-header ">
              <div className="row">
                <div className="align-items-center d-flex">
                  <h5>Founder’s OS:  7 Drivers of Growth to Help Your Business Excel by Building a Growth Plan</h5>
                </div>
              </div>
            </div> */}
            <div className="card-header d-flex flex-wrap justify-content-between align-items-center ">
              <div className="d-flex align-items-center flex-grow-1">
                <h5>Founder’s OS:  7 Drivers of Growth to Help Your Business Excel by Building a Growth Plan</h5>
              </div>
              <div >
                <BackButton />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="card-body">
                <div className='mb-3 Row'>
                  {sections.map((section) => (
                    <div key={section.name} className="d-md-flex">
                      <div className='col-md-4 tag'>
                        <a
                          href={sectionLinks[section.name]}
                          onClick={() => handleOptOutChange(section.name)}
                        >
                          {section.name}
                        </a>
                      </div>

                      <div className='col-md-6'>
                        <Progress value={section.completion} color={getColorForPercentage(section.completion)}>
                          {` ${Math.floor(section.completion)} `}%
                        </Progress>
                      </div>
                      <div className='col-md-2'>
                        <h6 className='mx-4'>{` ${Math.floor(section.completion)} `}<span>%</span></h6>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Founderdrivers;
