import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import axios from "axios";
import API from "../../../../../utils";
import { toast } from "react-toastify";
import { FaCheck, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const CustomerActions = () => {
  const actionsList = {
    "Customer Experience": [
      {
        id: 1,
        name: "Introduce regular customer satisfaction feedback process and create meaningful management information",
      },
      { id: 2, name: "Re-imagine and map key customer journeys" },
      { id: 3, name: "Align brand promise with customer experience" },
      {
        id: 4,
        name: "Develop plans to promote enhanced and consistent customer experience across all channels and touch points",
      },
      {
        id: 5,
        name: "Shift key customer journeys to digital where possible, in-line with renewed customer needs and expectations",
      },
      {
        id: 6,
        name: "Use customer feedback to drive product/service innovation",
      },
      {
        id: 7,
        name: "Develop and introduce a highly effective customer loyalty program",
      },
      {
        id: 8,
        name: "Introduce/improve (predictive) data analytics to identify changes in customer buying patterns, behavior and satisfaction levels",
      },
    ],
    "Products, Markets, and Channels": [
      {
        id: 9,
        name: "Evaluate and reshape our product and service portfolio in-line with changing customer expectations",
      },
      {
        id: 10,
        name: "Frequently review product, market, channel combinations alignment and performance",
      },
      {
        id: 11,
        name: "Enhance analytical capability to enable improved market segmentation, create more specific sales activity for groups of customers and provide tailored customer experience",
      },
      { id: 12, name: "Explore further channels of distribution" },
      {
        id: 13,
        name: "Customize products and services for multi-channels and distributors",
      },
      {
        id: 14,
        name: "Develop and/or review plans for international expansion with existing and/or new products",
      },
      { id: 15, name: "Consider emerging market strategy" },
      {
        id: 16,
        name: "Develop a blueprint for effective new market entry (e.g. market selection, entrance and tax implications)",
      },
      {
        id: 17,
        name: "Conduct a systematic Customer risk analysis (dependence, PMCs, disruption, competencies, processes, systems, organization, etc.) incl. outside, downside and upside risks (risk-taking required to realize the company’s growth and long-term value objectives)",
      },
    ],
    Marketing: [
      {
        id: 18,
        name: "Improve our external communications to make it more effective, transparent and empathetic to reflect current socio-economic environment",
      },
      { id: 19, name: "Re-define our brand promise" },
      { id: 20, name: "Develop a comprehensive brand management strategy" },
      {
        id: 21,
        name: "Marketing to be focused on retention, cross-selling and up-selling",
      },
      {
        id: 22,
        name: "Marketing to be focused on developing deeper, long-term and continuous relationships, driving high loyalty and customer retention levels",
      },
      {
        id: 23,
        name: "Develop an innovative and responsive digital marketing strategy to align with or replace traditional methods",
      },
      { id: 24, name: "Build effective communities of brand ambassadors" },
    ],
    "Sales and Pricing": [
      {
        id: 25,
        name: "Transition and transform sales and marketing to effective virtual working",
      },
      {
        id: 26,
        name: "In-line with the current economic situation, evaluate and reshape our commercial model (e.g. pricing, cost of sales and service) and understand the impact to our pipeline and margins",
      },
      {
        id: 27,
        name: "Review price and margin for specific customer segments",
      },
      {
        id: 28,
        name: "Improve competitive and market intelligence to enable better pricing decisions and more effective sales programs",
      },
      {
        id: 29,
        name: "Develop intelligent, dynamic pricing approach for specific customer segments or accounts",
      },
      {
        id: 30,
        name: "Develop customer lifetime value model to transform sales, pricing and retention strategies",
      },
      {
        id: 31,
        name: "Introduce robust and consistent account management process",
      },
      {
        id: 32,
        name: "Link staff compensation to long-term customer experience/satisfaction",
      },
    ],
  };
  const [selectedActions, setSelectedActions] = useState([]);
  const [openCategory, setOpenCategory] = useState(null);
  const [checkedValue, setCheckedValue] = useState({});
  const uid = localStorage.getItem("user_id");

  const handleCategoryToggle = (category) => {
    setOpenCategory(openCategory === category ? null : category);
  };

  const fetchData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("user_id", uid);

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_growth_customer}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === "success") {
        const jsonData = response.data.data.technology_action;

        if (jsonData) {
          try {
            const data = JSON.parse(jsonData);

            const parseCategoryData = (categoryData) => {
              return categoryData ? categoryData.split(",") : [];
            };

            const actions = {
              "Customer Experience": parseCategoryData(
                data.customer_experience || ""
              ),
              "Products, Markets, and Channels": parseCategoryData(
                data.product_market_challange || ""
              ),
              Marketing: parseCategoryData(data.marketing || ""),
              "Sales and Pricing": parseCategoryData(data.sales_pricing || ""),
            };
            setCheckedValue(actions);
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
        } else {
          console.error("No data found for technology_action.");
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Fetch technology actions error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formatSelectedActions = (selectedActions) => {
    const categories = {
      "Customer Experience": "customer_experience[]",
      "Products, Markets, and Channels": "product_market_challange[]",
      Marketing: "marketing[]",
      "Sales and Pricing": "sales_pricing[]",
    };

    const formatted = Object.keys(categories).reduce((acc, category) => {
      const selectedData = selectedActions
        .filter((action) => action.category === category)
        .map((action) => {
          const actionDetails = actionsList[category].find(
            (a) => a.id === action.id
          );
          return actionDetails ? actionDetails.name : null; // Directly returning the action name
        })
        .filter((action) => action !== null);

      acc[categories[category]] = selectedData;
      return acc;
    }, {});

    return formatted;
  };

  const handleAddToActions = async (category, action, index) => {
    try {
      const newCheckedValue = { ...checkedValue };
      const isSelected = newCheckedValue[category]?.includes(action.id);
      if (isSelected) {
        newCheckedValue[category] = newCheckedValue[category].filter(
          (id) => id !== action.id
        );
      } else {
        newCheckedValue[category] = [
          ...(newCheckedValue[category] || []),
          action.id,
        ];
      }
      setCheckedValue(newCheckedValue);

      // Update selected actions state
      const updatedActions = Object.keys(newCheckedValue).flatMap((cat) =>
        newCheckedValue[cat].map((id) => ({ category: cat, id }))
      );
      setSelectedActions(updatedActions);

      // Make API call
      const formDataToSend = new FormData();
      formDataToSend.append("user_id", uid);

      // Format the actions correctly
      const formattedActions = formatSelectedActions(updatedActions);

      // Append formatted actions to FormData
      Object.entries(formattedActions).forEach(([key, value]) => {
        if (value.length > 0) {
          value.forEach((item) => formDataToSend.append(key, item));
        } else {
          formDataToSend.append(key, "[]"); // To represent an empty array
        }
      });

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.add_update_customer_action}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Submit technology actions error", error);
      toast.error("An error occurred while updating data.");
    }
  };

  const renderActions = (category) => {
    const categoryActions = actionsList[category] || [];

    return categoryActions.map((action, index) => (
      <Col xs={12} md={6} lg={3} key={action.id} className="mb-3">
        <div className="action-card p-3 border">
          <p>{action.name}</p>
          <Button
            variant={
              checkedValue[category]?.includes(action.id)
                ? "success"
                : "primary"
            }
            onClick={() => handleAddToActions(category, action, index)}
            className="d-flex align-items-center mt-3 p-2"
          >
            {checkedValue[category]?.includes(action.id) ? (
              <FaCheck className="me-2" />
            ) : (
              <FaPlus className="me-2" />
            )}
            {checkedValue[category]?.includes(action.id)
              ? "Selected"
              : "Add to Actions"}
          </Button>
        </div>
      </Col>
    ));
  };

  return (
    <div className="col-sm-12">
      <div className="card mt-4">
        <div className="card-header">
          <div className="justify-content-between d-flex ">
            <div className="">
              <h5>Actions for Customer</h5>
            </div>
            <div>
              <Link
                className="backto-ondriver"
                to="/growthframeworks/founderdriver"
              >
                Back to the Founder OS Drivers of Growth
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card-body">
            <p>
              To help you achieve your goals we have created actions learned
              from our insights of working with market leaders. Add ones that
              you feel are best for your business.
            </p>
            <Accordion defaultActiveKey="0" activeKey={openCategory}>
              {Object.keys(actionsList).map((category) => (
                <Accordion.Item eventKey={category} key={category}>
                  <Accordion.Header
                    onClick={() => handleCategoryToggle(category)}
                  >
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h5 className="mb-0 fw-bold">{category}</h5>
                      <p className="mb-0 text-primary">View Actions</p>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      {openCategory === category && renderActions(category)}
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerActions;
