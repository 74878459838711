import React, { useState } from "react";
import { Link } from "react-router-dom";
import API from "../../../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function CustomerAssessment() {
  const [strategyOption, setStrategyOption] = useState("");
  const [cultureOption, setCultureOption] = useState("");
  const [planningOption, setPlanningOption] = useState("");
  const [performanceOption, setPerformanceOption] = useState("");
  const [feedbackOption, setFeedbackOption] = useState("");
  const [valuesOption, setValuesOption] = useState("");
  const [innovationOption, setInnovationOption] = useState("");
  const [embeddingOption, setEmbeddingOption] = useState("");
  const [leadershipOption, setLeadershipOption] = useState("");
  const [rewardsOption, setRewardsOption] = useState("");
  const [rewardsStrategyOption, setRewardsStrategyOption] = useState("");

  const uid = localStorage.getItem("user_id");

  const handleChange = (setter) => (event) => {
    setter(event.target.value);
  };

  useEffect(() => {
    const formDataToSend = new FormData();
    formDataToSend.append("user_id", uid);
    formDataToSend.append("Capability_Strategy", strategyOption);
    formDataToSend.append(
      "Customer_Experience_Customer_Loyalty",
      cultureOption
    );
    formDataToSend.append(
      "Products_Markets_Channels_Analytics",
      planningOption
    );
    formDataToSend.append(
      "Products_Markets_and_Channels_Markets",
      performanceOption
    );
    formDataToSend.append("Products_Markets_Channels_Channels", feedbackOption);
    formDataToSend.append("Products_Markets_Channels_Products", valuesOption);
    formDataToSend.append("Marketing", innovationOption);
    formDataToSend.append("Marketing_Brand", embeddingOption);
    formDataToSend.append("Sales_Pricing", leadershipOption);
    formDataToSend.append("Sales_Pricing_Competition", rewardsStrategyOption);

    if (
      strategyOption ||
      cultureOption ||
      planningOption ||
      performanceOption ||
      feedbackOption ||
      valuesOption ||
      innovationOption ||
      embeddingOption ||
      leadershipOption ||
      rewardsStrategyOption
    ) {
      updateTask(formDataToSend);
    }
  }, [
    strategyOption,
    cultureOption,
    planningOption,
    performanceOption,
    feedbackOption,
    valuesOption,
    innovationOption,
    embeddingOption,
    leadershipOption,
    rewardsStrategyOption,
  ]);

  const updateTask = async (formDataToSend) => {
    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.add_update_customer_assesment}`,
        formDataToSend,
        {
          headers: {
            Authorization: API.AUTHORIZATION_TOKEN,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occurred while updating data.");
    }
  };

  const fetchData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("user_id", uid);

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_growth_customer}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        const jsonData = response.data.data.customer_assesment;

        let data;
        try {
          data = JSON.parse(jsonData);
        } catch (error) {
          console.error("Error parsing JSON:", error);

          return;
        }

        setStrategyOption(data.Capability_Strategy);
        setCultureOption(data.Customer_Experience_Customer_Loyalty);
        setPlanningOption(data.Products_Markets_Channels_Analytics);
        setPerformanceOption(data.Products_Markets_and_Channels_Markets);
        setFeedbackOption(data.Products_Markets_Channels_Channels);
        setValuesOption(data.Products_Markets_Channels_Products);
        setInnovationOption(data.Marketing);
        setEmbeddingOption(data.Marketing_Brand);
        setLeadershipOption(data.Sales_Pricing);
        setRewardsOption(data.Sales_Pricing);
        setRewardsStrategyOption(data.Sales_Pricing_Competition);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Fetch meeting types error", error);
    }
  };

  const calculateCompletionPercentage = () => {
    const points = {
      Leading: 9.09,
      Established: 6.06,
      Developing: 3.03,
    };

    const totalQuestions = 11;
    const totalScore = [
      strategyOption,
      cultureOption,
      planningOption,
      performanceOption,
      feedbackOption,
      valuesOption,
      innovationOption,
      embeddingOption,
      leadershipOption,
      rewardsOption,
      rewardsStrategyOption,
    ].reduce((acc, option) => acc + (points[option] || 0), 0);

    const percentage = (totalScore / (totalQuestions * points.Leading)) * 100;
    const roundedPercentage = Math.round(percentage);

    localStorage.setItem("customerCompletionPercentage", roundedPercentage);

    return roundedPercentage;
  };

  const techCompletionPercentage = calculateCompletionPercentage();

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="col-sm-12">
      <div className="card mt-4">
        <div className="card-header">
          <div className="justify-content-between align-items-center d-flex">
            <div className=" align-items-center d-flex">
              <h5>Customer Assessment</h5>
            </div>

            <div className="ms-3 align-items-center d-flex ms-3">
              <div className="m-r-15">
                <Link
                  className="backto-ondriver"
                  to="/growthframeworks/founderdriver"
                >
                  Back to the Founder OS Drivers of Growth
                </Link>
              </div>
              <CircularProgressbar
                className="assessmentprogress"
                value={techCompletionPercentage}
                text={`${Math.round(techCompletionPercentage)}%`}
                styles={{
                  path: {
                    stroke: "green",
                  },
                  text: {
                    fill: "black",
                    fontSize: "16px",
                  },
                  trail: {
                    stroke: "#d6d6d6",
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card-body">
            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>Customer - Capability - Strategy</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  Please select the radio button that best describes where you
                  are in your business. (Leading, Established, Developing)
                </p>
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={strategyOption === "Leading"}
                      onChange={handleChange(setStrategyOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • The full customer journey experience drives high customer
                  loyalty.
                </p>
                <p className="textnots-text">
                  • Customer experience fulfils the brand promise.{" "}
                </p>

                <p className="textnots-text">
                  • The quality of the brand experience turns customers into
                  advocates. They contribute ideas and insights that help the
                  development of new products and services.{" "}
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={strategyOption === "Established"}
                      onChange={handleChange(setStrategyOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • The current portfolio of products and services meets
                  existing customer needs and is the main driver for the
                  customer experience and loyalty.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={strategyOption === "Developing"}
                      onChange={handleChange(setStrategyOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Individual product, service and channels drive customer
                  experience and loyalty.
                </p>
              </div>
            </div>

            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>
                      Customer – Customer Experience – Customer Loyalty
                    </strong>
                  </b>
                </label>
                <p className="textnots-text">
                  Please select the radio button that best describes where you
                  are in your business. (Leading, Established, Developing){" "}
                </p>
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={cultureOption === "Leading"}
                      onChange={handleChange(setCultureOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Solving customer issues before they arise is a focus.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={cultureOption === "Established"}
                      onChange={handleChange(setCultureOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • The business regularly measures customer satisfaction and
                  attitude levels.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={cultureOption === "Developing"}
                      onChange={handleChange(setCultureOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • The business periodically measures customer satisfaction.
                </p>
              </div>
            </div>

            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>
                      Customer – Products, Markets and Channels - Analytics
                    </strong>
                  </b>
                </label>
              </p>
              <p className="textnots-text">
                Please select the radio button that best describes where you are
                in your business. (Leading, Established, Developing)
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={planningOption === "Leading"}
                      onChange={handleChange(setPlanningOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • It uses advanced analytics to micro-segment its customer
                  base and provides a tailored customer experience.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={planningOption === "Established"}
                      onChange={handleChange(setPlanningOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Analytical methods are updated regularly and are based on
                  macro-segments.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={planningOption === "Developing"}
                      onChange={handleChange(setPlanningOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Basic segmentation is based on demographics and sales
                  information (e.g. revenue and volume).
                </p>
              </div>
            </div>

            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>
                      Customer – Products, Markets and Channels - Markets
                    </strong>
                  </b>
                </label>
              </p>
              <p className="textnots-text">
                Please select the radio button that best describes where you are
                in your business. (Leading, Established, Developing)
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={performanceOption === "Leading"}
                      onChange={handleChange(setPerformanceOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • The business is expanding globally into emerging or
                  diversified markets.
                </p>
                <p className="textnots-text">
                  • There is a proven track record of being able to quickly and
                  successfully enter new markets.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={performanceOption === "Established"}
                      onChange={handleChange(setPerformanceOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Business is expanding internationally (market selection,
                  entrance and tax implications).
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={performanceOption === "Developing"}
                      onChange={handleChange(setPerformanceOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Markets are often domestic and local.
                </p>
              </div>
            </div>

            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>
                      Customer – Products, Markets and Channels - Channels
                    </strong>
                  </b>
                </label>
              </p>
              <p className="textnots-text">
                Please select the radio button that best describes where you are
                in your business. (Leading, Established, Developing)
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={feedbackOption === "Leading"}
                      onChange={handleChange(setFeedbackOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • It frequently evaluates its multi product-market-channel
                  combinations in a structured fashion, which drives strategic
                  decision-making.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={feedbackOption === "Established"}
                      onChange={handleChange(setFeedbackOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Sales and distribution are multichannel.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={feedbackOption === "Developing"}
                      onChange={handleChange(setFeedbackOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • The business has a few sales and distribution channels and
                  is exploring further expansion.
                </p>
              </div>
            </div>

            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>
                      Customer – Products, Markets and Channels - Products
                    </strong>
                  </b>
                </label>
              </p>
              <p className="textnots-text">
                Please select the radio button that best describes where you are
                in your business. (Leading, Established, Developing)
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={valuesOption === "Leading"}
                      onChange={handleChange(setValuesOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Values are understood by all. Behaviors are aligned to
                  performance and linked to rewards.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={valuesOption === "Established"}
                      onChange={handleChange(setValuesOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • The company is enhancing products for existing customer base
                  and exploring new markets for its existing products.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={valuesOption === "Developing"}
                      onChange={handleChange(setValuesOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • The business is focused on maximizing sales volume with
                  current products and services with existing customers.
                </p>
              </div>
            </div>

            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>Customer – Marketing</strong>
                  </b>
                </label>
              </p>
              <p className="textnots-text">
                Please select the radio button that best describes where you are
                in your business. (Leading, Established, Developing)
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={innovationOption === "Leading"}
                      onChange={handleChange(setInnovationOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • The business is focused on developing deeper, long-term and
                  continuous relationships.
                </p>
                <p className="textnots-text">
                  • Highly innovative and responsive digital marketing is
                  integrated with traditional methods.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={innovationOption === "Established"}
                      onChange={handleChange(setInnovationOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Marketing is focused on retention, cross-selling and
                  up-selling.
                </p>
                <p className="textnots-text">
                  • The company is leveraging digital marketing to attract and
                  retain customers.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={innovationOption === "Developing"}
                      onChange={handleChange(setInnovationOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Marketing is focused on products and services.
                </p>
                <p className="textnots-text">
                  • Marketing is reliant on word of mouth, social media or
                  traditional media.{" "}
                </p>
              </div>
            </div>

            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>Customer – Marketing - Brand</strong>
                  </b>
                </label>
              </p>
              <p className="textnots-text">
                Please select the radio button that best describes where you are
                in your business. (Leading, Established, Developing)
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={embeddingOption === "Leading"}
                      onChange={handleChange(setEmbeddingOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Brand value is a true differentiator and leads to increased
                  margins.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={embeddingOption === "Established"}
                      onChange={handleChange(setEmbeddingOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Leadership understands the value of the brand and is living
                  the brand promise.
                </p>
                <p className="textnots-text">
                  • A comprehensive brand management strategy is in place.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={embeddingOption === "Developing"}
                      onChange={handleChange(setEmbeddingOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • There is increased attention to brand value.
                </p>
                <p className="textnots-text">
                  • A brand management strategy is being considered.
                </p>
              </div>
            </div>

            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>Customer – Sales & Pricing</strong>
                  </b>
                </label>
              </p>
              <p className="textnots-text">
                Please select the radio button that best describes where you are
                in your business. (Leading, Established, Developing)
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={leadershipOption === "Leading"}
                      onChange={handleChange(setLeadershipOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Rewards are based on long-term customer experiences.
                </p>
                <p className="textnots-text">
                  • Brand reputation generates sales.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={leadershipOption === "Established"}
                      onChange={handleChange(setLeadershipOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Staff are rewarded for exceeding sales targets and customer
                  satisfaction.
                </p>
                <p className="textnots-text">
                  • Sales are managed through robust account management
                  processes.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={leadershipOption === "Developing"}
                      onChange={handleChange(setLeadershipOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Staff are rewarded for meeting sales targets.
                </p>
              </div>
            </div>

            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>Customer – Sales & Pricing – Competition</strong>
                  </b>
                </label>
              </p>
              <p className="textnots-text">
                Please select the radio button that best describes where you are
                in your business. (Leading, Established, Developing)
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={rewardsOption === "Leading"}
                      onChange={handleChange(setRewardsOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Competitor's activities and market trends are
                  comprehensively evaluated to proactively evolve products,
                  services and sales programs.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={rewardsOption === "Established"}
                      onChange={handleChange(setRewardsOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Competitor's activities and market trends are
                  comprehensively evaluated to proactively evolve products,
                  services and sales programs.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={rewardsOption === "Developing"}
                      onChange={handleChange(setRewardsOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • The competitive environment is understood
                </p>
              </div>
            </div>

            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>Customer – Sales & Pricing – Price</strong>
                  </b>
                </label>
              </p>
              <p className="textnots-text">
                Please select the radio button that best describes where you are
                in your business. (Leading, Established, Developing)
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={rewardsStrategyOption === "Leading"}
                      onChange={handleChange(setRewardsStrategyOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Pricing is based on customer lifetime value
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={rewardsStrategyOption === "Established"}
                      onChange={handleChange(setRewardsStrategyOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Intelligent, dynamic pricing is applied to specific customer
                  segments or individual accounts/customers.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={rewardsStrategyOption === "Developing"}
                      onChange={handleChange(setRewardsStrategyOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Price and margin are based on single-sale transactions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerAssessment;
