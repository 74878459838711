import React, { useCallback, useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Chart } from "react-google-charts";
import DataTable from 'react-data-table-component';
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import debounce from 'lodash/debounce';
import MyLoader from "../../common/myloader";
import { MdDeleteForever } from "react-icons/md";
import BackButton from "../../common/header-component/backButton";

const TalentAssessment = () => {
  const uid = localStorage.getItem("user_id");
  const [loading, setLoading] = useState(false);

  const [chartData, setChartData] = useState([["Productivity", "Core Values", { role: "tooltip", type: "string" }]]);

  const scatterPlotOptions = {
    hAxis: { title: "Productivity", minValue: 0, maxValue: 10, gridlines: { count: 5 } },
    vAxis: { title: "Core Values", minValue: 0, maxValue: 10, gridlines: { count: 5 } },
    legend: "none",
    chartArea: { width: '80%', height: '70%' },
    pointSize: 10,
    tooltip: { isHtml: true },
    colors: ['#1f77b4'],
  };

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetchTasks();
    fetchChartData();
  }, []);

  const fetchTasks = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_talentassessment}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === 'success') {
        const sortedTasks = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setTableData(sortedTasks);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Error during task fetching:', error);
      toast.error("An error occurred while fetching tasks.");
    }
  };

  const fetchChartData = async () => {
    setLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_char_talentassessment}`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === 'success') {
        const { data } = response.data;

        const formattedData = [
          ["Productivity", "Core Values", { role: "tooltip", type: "string" }],
          ...data
            .filter(item => !isNaN(item.productivity_score) && !isNaN(item.core_values_score))
            .map(item => [
              Number(item.productivity_score),
              Number(item.core_values_score),
              `Team Member Initials: ${item.team_member}\nProductivity Score: ${item.productivity_score}\nCore Value Score: ${item.core_values_score}`
            ])
        ];

        setChartData(formattedData);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Error during chart data fetching:', error);
      toast.error("An error occurred while fetching chart data.");
    } finally {
      setLoading(false);
    }
  };
  const [formData, setFormData] = useState({
    team_member: '',
    Productivity: '',
    CoreValues: '',
    productivity_score: '',
    core_values_score: '',
    rating: '',
    next_planning_period: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('team_member', formData.team_member);
    formDataToSend.append('productivity_score', formData.productivity_score);
    formDataToSend.append('core_values_score', formData.core_values_score);
    formDataToSend.append('rating', formData.rating);
    formDataToSend.append('next_planning_period', formData.next_planning_period); // Convert to string
    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_talentassessment}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success("Talent Assessment Added Successfully");
        fetchTasks();
        fetchChartData();
        setFormData({
          team_member: '',
          Productivity: '',
          CoreValues: '',
          productivity_score: '',
          core_values_score: '',
          rating: '',
          Needsattention: '',
          next_planning_period: '',
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during task submission:", error);
      toast.error("An error occurred during submission.");
    }
  };


  const [updatedTask, setUpdatedTask] = useState(null);

  const debouncedUpdateTask = useCallback(
    debounce(async (task) => {
      const formDataToSend = new FormData();
      formDataToSend.append('id', task.id);
      formDataToSend.append('field', task.field);
      formDataToSend.append('text', task.value);

      try {
        const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.update_talentassessment}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
            },
          }
        );

        if (response.data.status === "success") {
          fetchTasks();
          fetchChartData();
          toast.success("Talent Assessment Updating Successfully");
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("An error occurred while updating data.");
      }
    }, 2000),
    []
  );

  const handleEditInputChange = (e, index, field, id) => {
    const { value } = e.target;
    const updatedOutcomes = [...tableData];
    updatedOutcomes[index][field] = value;


    setUpdatedTask({ field, value, id });
  };

  useEffect(() => {
    if (updatedTask) {
      debouncedUpdateTask(updatedTask);
    }
  }, [updatedTask, debouncedUpdateTask]);


  const handleDelete = async (id) => {
    setLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append('id', id);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.delete_talentassessment}`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === 'success') {
        toast.success("Talent Assessment Delete Successfully");
        fetchTasks();
        fetchChartData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occurred while fetching chart data.");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      name: 'Team Member Initials',
      selector: row => row.team_member,
      cell: (row, index) => (
        <div className="repoting-table">
          <input
            type="text"
            value={row.team_member}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "team_member", row.id)}
          />
        </div>
      ),
      sortable: true,
      center: true
    },
    {
      name: 'Productivity Score',
      selector: row => row.team_member,
      cell: (row, index) => (
        <div className="repoting-table">
          <input
            type="text"
            value={row.productivity_score}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "productivity_score", row.id)}
          />
        </div>
      ),
      sortable: true,
      center: true
    },
    {
      name: 'Core Value Score',
      selector: row => row.core_values_score,
      cell: (row, index) => (
        <div className="repoting-table">
          <input
            type="text"
            value={row.core_values_score}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "core_values_score", row.id)}
          />
        </div>
      ),
      sortable: true,
      center: true
    },
    {
      name: 'Rating',
      selector: row => row.rating,
      cell: (row, index) => (
        <div className="repoting-table">

          <input
            type="number"
            value={row.rating}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "rating", row.id)}
          />
        </div>
      ),
      sortable: true,
      center: true
    },
    {
      name: 'Action Plan For Next Planning Period',
      selector: row => row.next_planning_period,
      cell: (row, index) => (
        <div className="repoting-table">
          <input
            type="text"
            value={row.next_planning_period}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "next_planning_period", row.id)}
          />
        </div>
      ),
      sortable: true,
      center: true
    },
    {
      name: 'Actions',
      cell: (row, index) => (
        <div className="action-buttons">
          <button
            className="btn-danger"
            onClick={() => handleDelete(row.id)}
          >
            <MdDeleteForever className="f-20" />
          </button>
        </div>
      ),
      center: true
    }
  ];

  const isSaveButtonEnabled = () => formData.team_member.trim() !== '';

  return (
    <Container fluid={true}>

      <MyLoader active={loading} >
        {/* <BackButton /> */}
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              {/* <div className="card-header">
                <h5>Talent Assessment Chart</h5>
              </div> */}
              <div className="card-header d-flex flex-wrap justify-content-between align-items-center ">
                <div className="d-flex align-items-center flex-grow-1">
                  <h5>Talent Assessment Chart</h5>
                </div>
                <div >
                  <BackButton />
                </div>
              </div>
              <div className="card-body chart-block">
                <div>
                  {chartData && chartData.length > 1 ? (
                    <Chart
                      width={'100%'}
                      height={'400px'}
                      chartType="ScatterChart"
                      data={chartData}
                      options={scatterPlotOptions}
                    />
                  ) : (
                    <h6 className="d-flex justify-content-center">No data available to display.</h6>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card-body">
            <div class="alert alert-success" role="alert">
              <h4 class="alert-heading m-0 jobscorecaed">Note: Productivity Score , Core Value Score , Rating on a scale of 1-10.</h4>
            </div>
            <form className="theme-form">
              <div className="row">
                <div className="mb-3 col-lg-3 col-md-6">
                  <label className="col-form-label pt-0"><b>Team Member Initials</b></label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Team Member Initials"
                    name="team_member"
                    value={formData.team_member}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="mb-3 col-lg-3 col-md-6">
                  <label className="col-form-label pt-0"><b>Productivity Score</b></label>
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Productivity Score"
                    name="productivity_score"
                    min="1"
                    max="10"
                    value={formData.productivity_score}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3 col-lg-3 col-md-6">
                  <label className="col-form-label pt-0"><b>Core Value Score</b></label>
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Core Value Score"
                    name="core_values_score"
                    value={formData.core_values_score}
                    min="1"
                    max="10"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3 col-lg-3 col-md-6">
                  <label className="col-form-label pt-0"><b>Rating</b></label>
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Rating"
                    name="rating"
                    value={formData.rating}
                    min="1"
                    max="10"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3 col-lg-12 col-md-6">
                  <label className="col-form-label pt-0"><b>Action Plan For Next Planning Period</b></label>
                  <textarea
                    className="form-control"
                    type="text"
                    rows={5}
                    placeholder="Action Plan For Next Planning Period"
                    name="next_planning_period"
                    value={formData.next_planning_period}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end align-content-center mb-2">
                <Button variant="success" disabled={!isSaveButtonEnabled()} onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            </form>

            <DataTable
              persistTableHead={true}
              columns={columns}
              data={tableData}
              pagination
              className="rolmodel-table"
            />
          </div>
        </div>
      </MyLoader>
    </Container>
  );
};

export default TalentAssessment;
