import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FaRegEdit, FaTrash } from "react-icons/fa";
import axios from 'axios';
import { toast } from 'react-toastify';
import API from '../../../utils';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import BackButton from '../../common/header-component/backButton';

const ClientsTempleat = () => {
    const [expandedtemplateId, setExpandedtemplateId] = useState(null);
    const uid = localStorage.getItem("user_id");
    const [formData, setFormData] = useState({
        template: '',
        name: '',
    });
    const [errors, setErrors] = useState({
        template: '',
        name: '',
    });
    const [Templates, setTemplates] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [currentTemplateId, setCurrentTemplateId] = useState(null);

    const handleChange = (e, editor) => {
        const data = editor.getData();
        setErrors((prevErrors) => ({
            ...prevErrors,
            template: '',
        }));
        setFormData((prevData) => ({
            ...prevData,
            template: data
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        if (isEditing) {
            handleEditSubmit();
        } else {
            handleAddSubmit();
        }
    };

    const handleAddSubmit = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('name', formData.name);
            formDataToSend.append('template', formData.template);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_client_template}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                // toast.success(response.data.message);
                toast.success("Template Added Successfully");
                setTemplates((prevTemplates) => [...prevTemplates, { name: formData.name, template: formData.template, id: response.data.id }]);
                setFormData({
                    template: '',
                    name: '',
                });
                fetchData();

            } else {
                toast.error(response.data.template);
            }
        } catch (error) {
            console.error("Error during task submission:", error);
        }
    };

    const handleEdit = (template) => {
        setIsEditing(true);
        setCurrentTemplateId(template.id);
        setFormData({
            name: template.name,
            template: template.template,
        });
    };

    const handleEditSubmit = async () => {
        try {
            if (!currentTemplateId) {
                console.error("No template ID found for editing.");
                toast.error("No template ID found.");
                return;
            }

            const formDataToSend = new FormData();
            formDataToSend.append('template_id', currentTemplateId);
            formDataToSend.append('name', formData.name);
            formDataToSend.append('template', formData.template);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.update_client_template}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                // toast.success(response.data.message);
                toast.success("Template Updating Successfully");
                setTemplates((prevTemplates) =>
                    prevTemplates.map((template) =>
                        template.id === currentTemplateId
                            ? { ...template, name: formData.name, template: formData.template }
                            : template
                    )
                );
                setIsEditing(false);
                fetchData();
                setFormData({
                    template: '',
                    name: '',
                });
                setCurrentTemplateId(null);
            } else {
                toast.error(response.data.message || "An error occurred while updating the template.");
            }
        } catch (error) {
            console.error("Error during template edit submission:", error);
            toast.error("An error occurred while updating the template.");
        }
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {
            template: '',
            name: '',
        };

        if (!formData.template.trim()) {
            newErrors.template = 'This field is required';
            isValid = false;
        }
        if (!formData.name.trim()) {
            newErrors.name = 'This field is required';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const toggleExpand = (id) => {
        setExpandedtemplateId(expandedtemplateId === id ? null : id);
    };

    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_client_template}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                setTemplates(response.data.data); // Set the fetched Templates
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="container-fluid">
            {/* <BackButton /> */}
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        {/* <div className="card-header">
                            <div className="row">
                                <div className="col-md-6 align-items-center d-flex">
                                    <h5>Email Template</h5>
                                </div>
                            </div>
                        </div> */}
                        <div className="card-header d-flex flex-wrap justify-content-between align-items-center ">
                            <div className="d-flex align-items-center flex-grow-1">
                                <h5>Email Template</h5>
                            </div>
                            <div >
                                <BackButton />
                            </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group mb-3">
                                            <label className="form-label"><b>{isEditing ? "Edit Template name" : "Add Template name"}</b></label>
                                            <input
                                                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                            />
                                            {errors.name && <div className="text-danger">{errors.name}</div>}
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="form-label"><b>{isEditing ? "Edit Template" : "Add Template"}</b></label>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={formData.template}
                                                onChange={handleChange}
                                            />
                                            {errors.template && <div className="text-danger">{errors.template}</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-end">
                                <Button variant="success" type="submit" className="mx-1">
                                    {isEditing ? "Edit Email Template" : "Save Email Template"}
                                </Button>
                            </div>
                        </form>
                    </div>
                    <div className="card mt-3">
                        <div className="card-body">
                            <h5>Added Email Templates</h5>
                            {Templates.length === 0 ? (
                                <p>No Email Templates added yet.</p>
                            ) : (
                                <div>
                                    {Templates.map((template) => (
                                        <div key={template.id} className="template-section mb-3">
                                            <div
                                                className="template-name d-flex align-items-center"
                                                onClick={() => toggleExpand(template.id)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <strong>{template.name}</strong>
                                                <button className='ms-2' onClick={() => handleEdit(template)}>
                                                    <FaRegEdit />
                                                </button>
                                                <button className='ms-2 d-none' onClick={() => (template.id)}>
                                                    <FaTrash />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientsTempleat;
