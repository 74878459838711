import React, { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import API from "../../../utils";
import BackButton from "../../common/header-component/backButton";

const GrowYourTeam = () => {
    const [formData, setFormData] = useState({
        Onboarding_c: "",
        coaching_c: "",
        learning_c: "",
        Average_c: "",
        Onboarding_p: "",
        coaching_p: "",
        learning_p: "",
        Average_p: "",
        Remembered: "",
    });

    const uid = localStorage.getItem("user_id");

    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.grow_your_team_get}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                const fetchedData = response.data.data[0];

                setFormData({
                    Onboarding_c: fetchedData.Onboarding_c || "",
                    coaching_c: fetchedData.coaching_c || "",
                    learning_c: fetchedData.learning_c || "",
                    Average_c: fetchedData.Average_c || "",
                    Onboarding_p: fetchedData.Onboarding_p || "",
                    coaching_p: fetchedData.coaching_p || "",
                    learning_p: fetchedData.learning_p || "",
                    Average_p: fetchedData.Average_p || "",
                    Remembered: fetchedData.Remembered || "",
                });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch data error', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        Object.keys(formData).forEach(key => formDataToSend.append(key, formData[key]));

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.grow_your_team_insert}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();

                setFormData({
                    Onboarding_c: "",
                    coaching_c: "",
                    learning_c: "",
                    Average_c: "",
                    Onboarding_p: "",
                    coaching_p: "",
                    learning_p: "",
                    Average_p: "",
                    Remembered: "",
                });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Profile update failed.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    return (
        <Container fluid={true}>
            {/* <BackButton /> */}
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        {/* <div className="card-header">
                            <div className="row">
                                <div className="col-md-6 align-items-center">
                                    <h5>Grow Your Team</h5>
                                </div>
                            </div>
                        </div> */}
                        <div className="card-header d-flex flex-wrap justify-content-between align-items-center ">
                            <div className="d-flex align-items-center flex-grow-1">
                                <h5>Grow Your Team</h5>
                            </div>
                            <div >
                                <BackButton />
                            </div>
                        </div>
                        <form className="form theme-form" onSubmit={handleSubmit}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="life-wheel-tabel">
                                            <table className="table table-bordernone">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Company</th>
                                                        <th>Personal</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {[
                                                        { label: 'Onboarding new team members', c: 'Onboarding_c', p: 'Onboarding_p' },
                                                        { label: 'Regular one-on-one coaching', c: 'coaching_c', p: 'coaching_p' },
                                                        { label: 'Ongoing learning and development', c: 'learning_c', p: 'learning_p' },
                                                        { label: 'Average', c: 'Average_c', p: 'Average_p' }
                                                    ].map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.label}</td>
                                                            <td className="justify-content-around">
                                                                {[1, 2, 3, 4, 5].map(value => (
                                                                    <React.Fragment key={value}>
                                                                        <input
                                                                            type="radio"
                                                                            className="radio_animated"
                                                                            name={item.c}
                                                                            value={value}
                                                                            checked={formData[item.c] === value.toString()}
                                                                            onChange={handleChange}
                                                                        />
                                                                        <span className="family-ratings">{value}</span>
                                                                    </React.Fragment>
                                                                ))}
                                                            </td>
                                                            <td className="justify-content-around">
                                                                {[1, 2, 3, 4, 5].map(value => (
                                                                    <React.Fragment key={value}>
                                                                        <input
                                                                            type="radio"
                                                                            className="radio_animated"
                                                                            name={item.p}
                                                                            value={value}
                                                                            checked={formData[item.p] === value.toString()}
                                                                            onChange={handleChange}
                                                                        />
                                                                        <span className="family-ratings">{value}</span>
                                                                    </React.Fragment>
                                                                ))}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-2">
                                    <label className="col-sm-12 col-form-label"><b>Develop a quarterly company and/or personal priority to take action on in the next quarter to improve onboarding, coaching, or team member development</b></label>
                                    <textarea
                                        className="form-control"
                                        name="Remembered"
                                        value={formData.Remembered}
                                        onChange={handleChange}
                                        rows="4"
                                    ></textarea>
                                </div>
                            </div>
                            <div className="card-footer ">
                                <Button type="submit" variant="success" className="my-2 float-end">Save</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default GrowYourTeam;
